import { _ } from 'lodash';
import Vue, { ref, defineAsyncComponent } from 'vue';
import { useBlock } from '@/pages/Workflows/composables/wf-use-block';
import { useWorkflowsStore } from '@/stores/workflows-store';

// Import the available block versions for our versioning system
import BlockVersions from '@/components/WorkflowBlocks/block-versions.js';


export function useWorkflow(workflow = null, wfName = null) {
  const wf = workflow;
  const workflowsStore = useWorkflowsStore();

  const id = ref(workflow ? workflow?.id : null)

  const todaysDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const name = ref(workflow?.name || wfName || `New workflow ${todaysDate}`);
  const description = ref(workflow?.description || null);

  const group = ref(workflow?.workflow_group || null);
  const publishState = ref(workflow?.publish_state || false);

  const creator = ref(workflow?.creator_email || null);

  const created = ref(workflow?.created_time || null);
  const lastUpdater = ref(workflow?.last_updater || null);

  const updatedTime = ref(workflow?.updated_time  || null);

  const sampleTemplate = ref(null);

  const defaultGlobals = { 'general-contacts': { value: [] }, 'pending-approval-ids': [], };
  const globals = ref(workflow?.globals || defaultGlobals);
  const links = ref([]);
  
  // Required vars
  const webhook = ref(workflow?.webhook || null);
  const workflowLink = ref(workflow?.link || "Available after publish");
  const hasChangedSincePublish = ref(false);

  /* Error handling */
  const errors = ref([]);
  const addError = (error) => {
    const { id, message } = error;
    errors.value = errors.value.filter((e) => e.id !== error.id);
    errors.value.push({ id, message });
  }
  const removeError = (id) => {
    errors.value = errors.value.filter((e) => e.id !== id);
  }

  const getBlockDefinition = async (key, version = null) => {
    let blockDefinition = null;
    const blockVersionEntry = BlockVersions[key];

    // Get all version for this particular type (key) of block
    const allowedBlockVersions = blockVersionEntry?.versions;
    if (!allowedBlockVersions) throw new Error(`[use-workflow] Block versions not found for ${key}`);

    // Attempt to pull the block definition for the specified version
    // Pulls the latest version if no version is specified
    if (!version) blockDefinition = [...allowedBlockVersions].pop();
    else blockDefinition = allowedBlockVersions.find((block) => block.name === version);
    if (!blockDefinition) throw new Error(`[use-workflow] Block version ${version} not found for ${key}`);

    const definition = await import(`./workflow-blocks/${key}/${key}-${blockDefinition.name}.js`);
    if (!definition || !definition.block) throw new Error(`[use-workflow] Block definition not found for ${key}`);
  
    // Returns the block definition for this block's version, or the latest version if no version is specified
    return _.cloneDeep(definition.block);
  };

  const createBlock = async ({ key, parentBlock = null, version = null }) => {
    const blockDef = await getBlockDefinition(key, version);
    const newBlock = useBlock(blockDef, parentBlock);
    const numBlocksThisType = blocks.value.filter((block) => block.key === key).length + 1;
    newBlock.friendlyId = blockDef.key + numBlocksThisType;
    newBlock.indexOfType = numBlocksThisType;
    return newBlock;
  }

  const initNewWorkflow = async (roots, groupParam) => {
    group.value = groupParam;
    blocks.value = [];
    for (const item of roots) {
      const newBlock = await createBlock({ key: item });
      newBlock.setAsRoot(true);
      newBlock.init(newBlock);
      blocks.value.push(newBlock);
    };
    return await create();
  };

  const insertBlock = async (key, outcomeBlock, source, saveUpdate = true, waitForSave = false) => {
    const ownerBlock = blocks.value.find((block) => block.id === outcomeBlock.owner);

    const outcomeId = outcomeBlock.outcomeIndex;
    const previousChildId = outcomeBlock.child?.id;

    // Create the new block
    const newBlock = await createBlock({ key, parentBlock: ownerBlock });

    // Set the new block as the child of the outcome
    ownerBlock.setOutcome(outcomeId, newBlock);
    newBlock.init(newBlock);

    // Update the level of the previous child which is now down one
    const previousChildBlock = blocks.value.find((block) => block.id === previousChildId);
    if (previousChildBlock) {
      // Set the previous child as the child of the new block
      newBlock.setOutcome(0, previousChildBlock);

      // Update the previous child's parent to the new block
      previousChildBlock.setParent(ref(newBlock));
    }

    // Push the new block to our master list
    blocks.value.push(newBlock);
    if (saveUpdate) {
      const updates = {
        source: source || 'insert block - no source entered',
        change_note: `Added ${newBlock.title} block`,
        block_id: newBlock.id,
        current_change: newBlock.save(),
      }

      if (waitForSave) await save(updates);
      else save(updates);
    }
    return newBlock;
  };

  const reorderBlock = (blockId, outcomeBlock, source, saveUpdate = true) => {
    const draggedBlock = blocks.value.find((block) => block.id === blockId);
    if (!draggedBlock) return;

    const newOwnerBlock = blocks.value.find((block) => block.id === outcomeBlock.owner);
    const outcomeIdInNewOwner = outcomeBlock.outcomeIndex;
    const draggedBlockOldOutcomeId = draggedBlock.parent.outcomes.findIndex((outcome) => outcome.child?.id === blockId);
    const blockInOutcomeBeforeDrop = outcomeBlock.child;
    const draggedBlocksChild = draggedBlock.outcomes[0]?.child;

    // Set the destination to null to avoid circular references. The previous ref is in blockInOutcomeBeforeDrop
    newOwnerBlock.setOutcome(outcomeIdInNewOwner, null);

    // When a block moves, we need to:
    // 1. Check special case when the move is within the same parent, new outcome
    // 2. Update the dragged block's outcome to the block that we overrode & update the overriden block's parent
    // 3. If the dragged block had a child, update that child's parent to the dragged block's previous parent (before drag)
    // 4. Update the dragged block's parent to the new owner
    // 5. Update the new owner's outcome to the dragged block
    // 6. Update the dragged block's outcome to the block that we overrode

    // 1. Check special case when the move is within the same parent, new outcome
    const outcomeIndexInOwner = newOwnerBlock.outcomes.findIndex((outcome) => outcome.child?.id === blockId);
    if (outcomeIndexInOwner > -1) newOwnerBlock.setOutcome(outcomeIndexInOwner, null);

    // 2. Update the dragged block's outcome to the block that we overrode
    draggedBlock.parent.setOutcome(draggedBlockOldOutcomeId, draggedBlocksChild);
    blockInOutcomeBeforeDrop?.setParent(ref(draggedBlock));

    // 3. If the dragged block had a child, update that child's parent to the dragged block's previous parent (before drag)
    draggedBlocksChild?.setParent(ref(draggedBlock.parent));

    // 4. Update the dragged block's parent to the new owner
    draggedBlock.setParent(ref(newOwnerBlock));

    // 5. Update the new owner's outcome to the dragged block
    newOwnerBlock.setOutcome(outcomeIdInNewOwner, draggedBlock);

    // 6. Update the dragged block's outcome to the block that we overrode
    draggedBlock.setOutcome(0, blockInOutcomeBeforeDrop);

    if (saveUpdate) {
      const updates = {
        source: source || 'insert block - no source entered',
        change_note: `Re-ordered ${draggedBlock.title} block`,
        block_id: draggedBlock.id,
        current_change: draggedBlock.save(),
      }
      save(updates);
    }
  };

  const workflowHasValidBlockTypes = (blockTypes) => {
    for (const blockType of blockTypes) {
      if (!(blockType in BlockVersions)) return false;
    };

    return true;
  };

  const blocks = ref([]);
  const loadBlocks = async (isCopying) => {

    let currentBlocks = wf.blocks;
    const blockTypes = new Set(currentBlocks.map((block) => block.key));

    if (!workflowHasValidBlockTypes(blockTypes)) return;
    if (currentBlocks) {
      const initialBlock = currentBlocks[0];
      const version = initialBlock.general_settings.version;
      const newBlock = await createBlock({ key: initialBlock.key, version });
      const originalId = currentBlocks[0].id;
      newBlock.load(initialBlock, isCopying, originalId);
      newBlock.init(newBlock);
      blocks.value.push(newBlock);
      await insertLoadedBlock(newBlock, isCopying, originalId);
    }
    return true;
  }

  const insertLoadedBlock = async (currentBlock, isCopying, originalId) => {
    // Restore a block that was loaded from the backend
    let id = originalId || currentBlock.id;
    const loadedBlock = wf.blocks.find((block) => block.id === id);
    const blocksToProcess = [];

    for (const outcome of currentBlock.outcomes) {
      const loadedOutcome = loadedBlock.outcomes[outcome.outcomeId];
      const childBlock = wf.blocks.find((block) => block.id === loadedOutcome.childId);

      if (!childBlock || !loadedOutcome) return;
      const originalId = childBlock.id.value;

      const newChild = await createBlock({ key: childBlock.key });
      newChild.load(childBlock, isCopying, originalId);
      newChild.init(newChild);

      newChild.setParent(currentBlock);
      currentBlock.setOutcome(outcome.outcomeId, newChild);
      blocks.value.push(newChild);
      blocksToProcess.push(newChild);
    };

    // Build the tree recursively
    await Promise.all(blocksToProcess.map(async (block) => await insertLoadedBlock(block, isCopying, block.id.value)));
  }

  const generateSaveObject = () => {
    return {
      id: id.value,
      name: name.value,
      description: description.value,
      workflow_group: group.value,
      webhook: webhook.value,
      publish_state: publishState.value,
      globals: globals.value,
      blocks: blocks.value.map((block) => block.save()),
    }
  }

  const create = async () => {
    const saveObject = generateSaveObject();
    const result = await Vue.prototype.$harbourData.post('/api/workflows', saveObject);
    id.value = result.data.id;
    created.value = result.data.created_time;
  }

  const save = async (saveParams) => {
    const saveObject = generateSaveObject();
    
    // Track that this workflow has changed since the last publish
    hasChangedSincePublish.value = true;

    const options = { workflow: saveObject, changes: saveParams };
    const result = await Vue.prototype.$harbourData.put(`/api/workflows/${id.value}`, options);

    const { last_updater, updated_time } = result.data;
    lastUpdater.value = last_updater;
    updatedTime.value = updated_time;
  };

  const publish = async (workflowData) => {
    workflowData.publishState = true;

    // Since we're publishing, we can reset the hasChangedSincePublish flag
    hasChangedSincePublish.value = false;

    const saveObject = generateSaveObject();
    const changes = {
      current_change: { "publish_state": true },
      change_note: "Published workflow",
      source: "Publish button",
    }
    const options = { changes,  workflow: saveObject };
    const result = await Vue.prototype.$harbourData.post(`/api/workflows/publish/${id.value}`, options);
  }

  const linkWorkflowToObject = async (linkType, object_id, mapping) => {
    const options = {
      workflow_id: id.value,
      object_type: linkType,
      object_id: object_id,
      mapping,
    }

    const result = await Vue.prototype.$harbourData.post('/api/workflow-connections', options);
  }

  const unlinkWorkflowFromObject = async (linkType, object_id) => {    
    const options = {
      workflow_id: id.value,
      link_type: linkType,
      object_id: object_id,
      direction: 'remove'
    }

    const result = await Vue.prototype.$harbourData.post('/api/workflows/create-link', options);
  }

  const getChanges = (note, change) => {
    return {
        change_note: note,
        block_id: 'global',
        current_change: change,
        source: 'WfSettingsGlobal',
      }
  };

  const set = (key, value) => {
    value = value?.trim();
    if (!expose[key]) return;

    // Update the value and save
    expose[key] = value;
    save(getChanges(`Updated ${key} to ${value}`, { [key]: value }));

    if (key === 'name') {
      workflowsStore.sidebarGridApi?.refreshCells();
    }
  };

  const checkForApprovals = () => {
    const rootBlock = blocks.value[0];
    const rootId = rootBlock.id;
    const approvalBlocks = blocks.value.filter((block) => block.key === 'requestApproval');
    for (const block of approvalBlocks) {
      //check if rootId is in this block
      const variables = block.variables['blocks-to-approve'].value;
      if (variables.includes(rootId)) return true;
    }
    return false;
  };

  const expose = {
    id,
    name,
    description,
    creator,
    created,
    lastUpdater,
    updatedTime,

    group,
    publishState,

    sampleTemplate,
    globals,
    links,
    webhook,
    workflowLink,
    hasChangedSincePublish,

    // Blocks
    blocks,
    
    save,
    create,
    publish,
    loadBlocks,
    getBlockDefinition,
    generateSaveObject,

    // Errors
    errors,
    addError,
    removeError,

    // Init
    initNewWorkflow,
    createBlock,
    insertBlock,
    reorderBlock,
    checkForApprovals,

    // Linking
    linkWorkflowToObject,
    unlinkWorkflowFromObject,

    // Seteters
    set,
  }
  
  return expose;
};