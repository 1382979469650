import Vue from 'vue';
export default {
  async getDocumentSuggestions(file) {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await Vue.prototype.$harbourData.post(
      `/api/magic-annotations/suggestions`,
      formData,
    );
    return data;
  },
  async submitDocument(recordId, userData) {
    const { data } = await Vue.prototype.$harbourData.put(
      `api/magic-annotations/suggestions/${recordId}/finalize`,
      {
        user_accepted_data: userData,
      },
    );
    return data;
  },

  async sendEmail(recordId, emails) {
    const { data } = await Vue.prototype.$harbourData.post(
      `api/magic-annotations/suggestions/${recordId}/send-email`,
      {
        emails,
      },
    );
    return data;
  },

  async downloadFile(gcsId) {
    const { data } = await Vue.prototype.$harbourData.get(`magic-sign/${gcsId}`);
    return data;
  },
};
