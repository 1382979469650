<script>
import debounce from '@/utils/debounce';

import { useHarbourStore } from '@/stores/harbour-store';

export default {
  name: 'HrbrTemplateGroupSettings',

  props: {
    templateGroup: {
      type: Object,
      default() {
        return {
          groupId: undefined,
          groupName: undefined,
          groupUsersList: [],
        };
      },
    },
    initUserGroupsList: {
      type: Array,
      default: () => [],
    },
    userEmail: {
      type: String
    }
  },
  data() {
    return {
      isStoringInProgress: false,
      filteredGroup: '',
      userGroupsList: JSON.parse(JSON.stringify(this.initUserGroupsList)),
      isLoading: false,
      harbourStore: useHarbourStore(),
      currentUserEmail: null,
      closeButton: {
        text: 'Close',
        styleType: 'secondary',
      },
    };
  },
  computed: {
    // check if template group is in edit mode by checking if template_group_id is defined
    isEditMode() {
      return this.templateGroup.groupId !== undefined;
    },

    // check if all groups are selected
    allGroupsSelected() {
      return (
        this.userGroupsList.length > 0 &&
        this.initUserGroupsList.length > 0 &&
        this.initUserGroupsList.length === this.templateGroup.groupUsersList.length
      );
    },

    // sort user groups list by isChecked and userGroupName accordingly
    sortedUserGroupsList() {
      return this.userGroupsList.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1;
        }
        if (!a.isChecked && b.isChecked) {
          return 1;
        }
        if (a.userGroupName < b.userGroupName) {
          return -1;
        }
        if (a.userGroupName > b.userGroupName) {
          return 1;
        }
        return 0;
      });
    },

    saveButton() {
      return {
        text: 'Save',
        styleType: 'primary',
        style: {
          marginLeft: '6px'
        },
        disabled: !this.templateGroup.groupName
      }
    }
  },

  methods: {
    setCurrentUserEmail() {
      const { contextDict } = this.harbourStore;
      this.currentUserEmail = this.currentUserEmail || contextDict.systememail;
    },

    closeModal() {
      this.$parent.close();
    },

    // update template group request
    async updateTemplateGroup() {
      this.isLoading = true;
      await this.$harbourData.post('data?update_template_group', {
        requesttype: 'update_template_group',
        template_group_name: this.templateGroup.groupName,
        template_group_id: this.templateGroup.groupId,
        template_group_user_groups: this.templateGroup.groupUsersList,
      });
      this.isLoading = false;
    },

    // create template group request
    async createTemplateGroup() {
      this.isLoading = true;
      const response = await this.$harbourData.post('data?create_template_group', {
        requesttype: 'create_template_group',
        template_group_name: this.templateGroup.groupName,
        template_group_user_groups: this.templateGroup.groupUsersList,
      });
      this.isLoading = false;
      const newTemplateGroup = {
        groupId: response.data.template_group_id,
        groupName: this.templateGroup.groupName,
        groupCreatorEmail: this.currentUserEmail
      };
      this.$emit('add-template-group', newTemplateGroup);
    },

    // save template group controller method
    async saveSettings() {
      if (this.isEditMode) {
        await this.updateTemplateGroup();
      } else {
        await this.createTemplateGroup();
      }
      this.closeModal();
    },

    // on checkbox click handler
    onCheckboxClick(userGroup) {
      if (this.checkGroupIsSelected(userGroup)) {
        this.$buefy.toast.open({
          duration: 2500,
          message: `Successfully shared with ${userGroup.userGroupName} `,
          position: 'is-top',
          type: 'is-success',
        });
      }
    },

    // on select all checkbox(in the header) click handler
    onSelectAllCheckboxClick() {
      if (this.allGroupsSelected) {
        this.templateGroup.groupUsersList = [];
        return;
      }
      this.templateGroup.groupUsersList = this.userGroupsList.map(
        (row) => row.userGroupId,
      );
      this.$buefy.toast.open({
        duration: 2500,
        message: 'Successfully shared with whole organization',
        position: 'is-top',
        type: 'is-success',
      });
      console.log('select all checkbox clicked');
    },

    // check if group is selected
    checkGroupIsSelected(userGroup) {
      return this.templateGroup.groupUsersList.indexOf(userGroup.userGroupId) !== -1;
    },

    // debounced filter user groups list
    debouncedFilterGroups: debounce(function () {
      console.log('debounceFilterGroups');
      this.filterGroups();
    }, 350),

    // filter user groups list
    filterGroups() {
      if (this.filteredGroup === '') {
        this.userGroupsList = this.initUserGroupsList;
        return;
      }
      this.userGroupsList = this.userGroupsList.filter((userGroup) =>
        userGroup.userGroupName.toLowerCase().includes(this.filteredGroup.toLowerCase()),
      );
    },

    // add isChecked value to userGroupsList array to be able to use it in template
    addIsCheckedValueToUserGroupsList() {
      this.userGroupsList.forEach((userGroup) => {
        // eslint-disable-next-line no-param-reassign
        userGroup.isChecked = this.checkGroupIsSelected(userGroup);
      });
    },
  },
  created() {
    // adding isChecked value by default
    this.addIsCheckedValueToUserGroupsList();
    this.setCurrentUserEmail();
  },
};
</script>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Template group settings</p>
    </header>
    <section class="modal-card-body">
      <b-loading :is-full-page="false" :active="isLoading" :can-cancel="true"></b-loading>
      <div class="groupInfo" style="padding-left: 10px">
        <b-field label="Template group name">
          <b-field style="display: flex">
            <b-input
              v-model.trim="templateGroup.groupName"
              placeholder="Template group name"
              icon="pen"
              icon-pack="fal"
              style="width: 100%"
              clearable
              expanded></b-input>
          </b-field>
        </b-field>
        <b-field label="User groups access"></b-field>
        <b-field style="display: flex">
          <b-input
            v-model.trim="filteredGroup"
            @input="debouncedFilterGroups"
            placeholder="Filter by user group name"
            icon="filter"
            icon-pack="fal"
            style="width: 100%"
            clearable
            expanded></b-input>
        </b-field>
        <b-table
          :data="sortedUserGroupsList"
          :paginated="true"
          pagination-simple
          icon-pack="fal"
          icon-prev="chevron-left"
          icon-next="chevron-right"
          per-page="3">
          <b-table-column label="Items" :visible="true" centered>
            <template #header="{ column }">
              <b-switch
                :disabled="!harbourStore.isAdmin"
                :value="allGroupsSelected"
                type="is-success"
                @change.native="onSelectAllCheckboxClick"></b-switch>
            </template>
            <template v-slot="props">
              <b-switch
                :disabled="!harbourStore.isAdmin && !props.row.isGroupAdmin"
                :native-value="props.row.userGroupId"
                v-model="templateGroup.groupUsersList"
                @change.native="onCheckboxClick(props.row)"
                type="is-success"></b-switch>
            </template>
          </b-table-column>

          <b-table-column v-slot="props" field="userGroupName" label="User group">
            {{ props.row.userGroupName }}
          </b-table-column>

          <b-table-column v-slot="props" field="usersTotal" label="Users total">
            {{ props.row.usersTotal }}
          </b-table-column>
        </b-table>
      </div>
    </section>
    <footer class="modal-card-foot">
      <HrbrButton :button="closeButton" @click="closeModal" />
      <HrbrButton :button="saveButton" @click="saveSettings" />
    </footer>
  </div>
</template>

<style lang="postcss" scoped></style>
