import ModalMoveCopyAssets from '@/components/Modals/HrbrMoveCopyAssets.vue';
import { CHECK_TYPE_INPUTS } from '@/config/agreement-editor.js';
import { VERIFICATION_NAMES_MAP, VERIFICATION_RESULTS } from '@/config/verification-inputs.js';
import verificationsApiService from '@/services/verifications-api-service.js';
import {
  DialogProgrammatic as Dialog,
  ModalProgrammatic as Modal,
  ToastProgrammatic as Toast,
} from 'buefy';
import { defineStore } from 'pinia';
import Vue from 'vue';
import { useHarbourStore } from './harbour-store';
import { publishEvent } from '@/utils/bus';

export const useLibraryStore = defineStore('library', {
  state: () => ({
    harbourStore: useHarbourStore(),
    gridAssets: null,
    gridApi: null,
    gridColumnApi: null,
    offset: 0,
    limit: 15,
    selectedRowsCount: 0,
    isCurrentlyLoadingAssets: false,
    specialFolders: ['home', 'shared'],
    textFilterValue: null,
    quickFilterTimeout: null,
    gridReference: null,
    selectedAssets: [],
    loadedFolders: [],
    loadingFolderIds: [],
    assetPreviews: [],
    pendingAssetPreviews: [],
    previewRequestsTimer: null,
    loadedContexts: [],
    assetPreview: null,

    columnDefs: [],
    initialColumnState: [],

    currentAsset: null,
    currentPDF: null,

    isCurrentlyUploading: false,

    // Previews
    alreadyRequested: new Set(),
    currentExtraction: null,

    verificationResultLoaded: false,
  }),
  getters: {
    getFolderIndex: (state) => (folderId) =>
      state.loadedFolders.findIndex((folder) => folder.id === folderId),
    getLoadingFolders: (state) => state.loadingFolderIds,
    isFolderLoaded: (state) => {
      const check = state.harbourStore?.currentFolder?.status === 'loading';
      return state.loadedFolders.some((folder) => folder.id === check);
    },
    hasEditPermissions: (state) => {
      const folder = state.harbourStore.getCurrentFolder;
      const collaborators = folder?.collaborators;
      const creatorEmail = folder?.creatorEmail;
      const user = state.harbourStore.contextDict?.systememail;
      const org = state.harbourStore.contextDict?.organizationid;

      return (
        (collaborators && collaborators.includes(user)) ||
        creatorEmail === user ||
        state.harbourStore.isAdmin ||
        (collaborators && collaborators.includes(org))
      );
    },
    getHomeFolder: (state) => {
      const context = state.harbourStore.contextDict;
      if (!context) return null;
      return `folder-home-${context?.organizationid}-${context?.systemuserid}`;
    },
    getCurrentAssets: (state) => {
      const folder = state.harbourStore.currentFolder;

      if (!folder) return [];
      const homeFolders = ['home', '#home'];
      const selectedFolder = homeFolders.includes(folder) ? state.getHomeFolder : folder;

      return state.harbourStore.myAssets.filter((asset) => asset.folder_id === selectedFolder);
    },
    getCurrentAssetsInFolder: (state) => (folderId) => {
      const homeFolders = ['home', '#home'];
      const targetFolderId = homeFolders.includes(folderId) ? state.getHomeFolder : folderId;
      return state.harbourStore.myAssets.filter((asset) => asset.folder_id === targetFolderId);
    },
  },
  actions: {
    normalizeHashedFolder(folderId) {
      return folderId.substring(1);
    },

    markFolderLoaded(folderId) {
      this.loadingFolderIds = this.loadingFolderIds?.filter((folder) => folder !== folderId);
    },

    updateFoldersList(folderId, status, data = null) {
      const normalizedFolderId = this.specialFolders.includes(folderId) ? `#${folderId}` : folderId;

      const folderObj = {
        id: normalizedFolderId,
        lastLoaded: Date.now(),
        data: data || null,
      };

      const currentFolderIndex = this.getFolderIndex(normalizedFolderId);
      if (currentFolderIndex === -1) {
        this.loadedFolders.push(folderObj);
      } else {
        this.loadedFolders[currentFolderIndex] = {
          ...this.loadedFolders[currentFolderIndex],
          ...folderObj,
        };
      }

      if (status === 'ready') {
        this.markFolderLoaded(normalizedFolderId);
        this.updateGridAssets();
      }
    },

    async getFolderAssets(folders = []) {
      const foldersToGet = [];
      folders.forEach((folder) => {
        if (folder.startsWith('#')) folder = this.normalizeHashedFolder(folder);
        foldersToGet.push(folder);
      });

      const promises = [];
      foldersToGet.forEach((folderId) => {
        // getAssets with forceUpdate = true
        promises.push(this.harbourStore.getAssets(folderId, true));
      });
      await Promise.all(promises);
    },

    prettyBytes(bytes, decimals, kib) {
      kib = kib || false;
      if (bytes === 0) return '0 Bytes';
      if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number';
      const k = kib ? 1024 : 1000;
      const dm = decimals || 2;
      const sizes = kib
        ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
        : [' bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + sizes[i];
    },

    assetIsDraft(asset) {
      return asset.assetsource_contextsourcejson?.contextsourcetype === 'AGREEMENT-DRAFT';
    },

    expandDetails(id) {
      const node = this.gridApi.getRowNode(id);
      !!node && node.setExpanded(!node.expanded);
    },

    promptEditDisplayName(tgtAssetDisplayId, tgtAssetDisplayPreferredName, assetIsUploaded) {
      let self = this;
      const currentFolderCanEdit = true;
      if (!currentFolderCanEdit) return false;

      if (!assetIsUploaded) {
        alert(
          'This item is currently being uploaded - you can edit the name here after this is completed. Thanks!',
        );
        return false;
      }

      Dialog.prompt({
        message: `Edit name`,
        inputAttrs: {
          value: tgtAssetDisplayPreferredName,
          maxlength: 300,
          minlength: 1,
        },
        onConfirm: (tgtAssetNewDisplayPreferredName) => {
          //Validate user-provided value
          tgtAssetNewDisplayPreferredName = tgtAssetNewDisplayPreferredName.trim();
          if (tgtAssetNewDisplayPreferredName.length === 0) {
            alert('The name must have at least one character. Please try again.');
            return false;
          }
          //Update display
          const asset = this.harbourStore.myAssets.find((asset) => asset.id === tgtAssetDisplayId);
          if (!asset) return;

          asset.name = tgtAssetNewDisplayPreferredName;
          const node = this.gridApi.getRowNode(asset.id);
          this.gridApi.redrawRows({ rowNodes: [node] });

          //Send to server
          self.filesystemUpdateAssetDisplayName(tgtAssetDisplayId, tgtAssetNewDisplayPreferredName);

          //Show success
          Toast.open({
            duration: 2500,
            message: 'Success - name updated',
            position: 'is-top',
            type: 'is-success',
          });
        },
      });
    },

    async getContextSource(id) {
      const endpoint = `/folders/myAssets/context/${id}`;
      const result = await Vue.prototype.$harbourData.get(endpoint);

      if (result.status !== 200) return null;

      const contextObject = {
        id,
        context: result.data,
      };
      this.loadedContexts.push(contextObject);
      return result.data;
    },

    filesystemUpdateAssetDisplayName(tgtAssetDisplayId, tgtAssetNewDisplayPreferredName) {
      //Send data request
      Vue.prototype.$harbourData
        .post('data?filesystem-updateassetpreferreddisplayname', {
          requesttype: 'filesystem-updateassetpreferreddisplayname',
          assetdisplayid: tgtAssetDisplayId,
          assetdisplaypreferredname: tgtAssetNewDisplayPreferredName,
          folderId: this.harbourStore.currentFolder,
        })
        .then(() => {})
        .catch(() => {
          alert(
            'Error: while working on this, an error occurred in updating the asset name. Please refresh and try again later or contact support@harbourshare.com',
          );
        });
    },

    getAssetPreview(id) {
      if (this.pendingAssetPreviews.includes(id)) return;
      this.pendingAssetPreviews.push(id);
      this.loadPreviewsFromList();
    },

    async loadPreviewsFromList() {
      // Load previews every few seconds, or when we have 30 pending requests
      if (this.pendingAssetPreviews.length >= 30) {
        this.loadPreviews();
        return;
      }

      if (this.previewRequestsTimer) return;
      this.previewRequestsTimer = setTimeout(async () => {
        this.loadPreviews();
      }, 1000);
    },

    async loadPreviews() {
      if (!this.pendingAssetPreviews.length) return;
      const items = [...this.pendingAssetPreviews];
      this.pendingAssetPreviews = [];

      let folder = this.harbourStore.currentFolder;
      if (folder === '#home') folder = this.getHomeFolder;

      const missingItems = [];
      items.forEach((item) => {
        if (this.alreadyRequested.has(item)) return;
        missingItems.push(item);
      });

      if (!missingItems.length) return;
      const options = {
        previews_request: {
          assets: missingItems,
          folder_id: folder,
        },
      };

      const result = await Vue.prototype.$harbourData.post('/core/previews', options);
      this.assetPreviews = [...this.assetPreviews, ...result.data];
      this.alreadyRequested.add(...items);
      this.previewRequestsTimer = null;
    },

    setupAssetsToCopyOrMove(assets = null) {
      // Set up the array of assets to move
      // If no assets are passed, then try checked rows
      // Failing that, try all folder assets
      const selectedRows = this.gridApi?.getSelectedRows()?.map((asset) => asset.id);
      if (!assets) {
        // If no assets passed, check if we have checked rows
        if (selectedRows?.length) assets = selectedRows;
        else
          assets = this.getCurrentAssetsInFolder(this.harbourStore.currentFolder)?.map(
            (asset) => asset.id,
          );

        // If still no assets, then nothing to do here
        if (!assets) return null;
      }
      return assets;
    },

    copyAssets(assets = null) {
      assets = this.setupAssetsToCopyOrMove(assets);
      this.openFolderChanger(this.$root, 'COPY', assets, this.moveCopyAssetsCallback);
    },

    moveAssets(assets = null) {
      assets = this.setupAssetsToCopyOrMove(assets);
      this.openFolderChanger(this.$root, 'MOVE', assets, this.moveCopyAssetsCallback);
    },

    openFolderChanger(parent, mode, assetIdList, callback) {
      const legacyFoldersForModal = this.harbourStore.myFolders.map((folder) => {
        return {
          Id: folder.id,
          Name: folder.name,
          Parent: folder.parent,
        };
      });

      Modal.open({
        parent: parent,
        component: ModalMoveCopyAssets,
        hasModalCard: true,
        props: {
          assets: assetIdList,
          allfolders: legacyFoldersForModal,
          updatetablemove: () => null,
          mode: mode,
          currentfolderid: this.harbourStore.currentFolder,
          currentfoldername: this.harbourStore.getCurrentFolderName,
          refreshassets: this.getFolderAssets,
          clearcheckedrows: () => null,
          customcallback: callback,
        },
      });
    },

    moveCopyAssetsCallback(assets, mode, source, destination) {
      // Move action in the grid
      const sourceItems = this.getCurrentAssetsInFolder(source);

      const isMove = !!(mode === 'MOVE');
      assets.forEach((id) => {
        const item = sourceItems.find((item) => item.id === id);
        if (!item) return;
        if (isMove) {
          item.folder_id = destination;
        } else {
          const newItem = { ...item };
          newItem.id =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
          newItem.folder_id = destination;
          this.harbourStore.myAssets.unshift(newItem);
        }
      });

      // Apply grid filters and reload source and destination folders
      this.gridApi.onFilterChanged();
    },

    async removeAsset({ id, name }) {
      //Confirm?
      const confirmation = confirm(
        'Are you sure you want to fully delete the file ("' + name + '")?',
      );
      if (!confirmation) return false;

      //Remove
      const sourceItems = this.harbourStore.myAssets;
      const itemIndex = sourceItems.findIndex((item) => item.id === id);
      sourceItems.splice(itemIndex, 1);

      Toast.open({
        duration: 2500,
        message: 'Success - file removed',
        position: 'is-top',
        type: 'is-success',
      });

      //Flag for server removal
      await this.filesystemStartAssetDeletion(id, name);
    },

    filesystemStartAssetDeletion(tgtAssetDisplayId, tgtAssetDisplayPreferredName, successCallback) {
      //Send data request
      return new Promise((resolve, reject) => {
        Vue.prototype.$harbourData
          .post('data?filesystem-startassetremoval', {
            requesttype: 'filesystem-startassetremoval',
            assetdisplayid: tgtAssetDisplayId,
            assetdisplaypreferredname: tgtAssetDisplayPreferredName,
            folderid: this.harbourStore.currentFolder,
          })
          .then(() => {
            resolve(tgtAssetDisplayId);
          })
          .catch(() => {
            alert(
              'Error: while working on this, an error occurred. Please refresh and try again later or contact support@harbourshare.com',
            );
            reject();
          });
      });
    },

    resetGridApi() {
      this.gridApi = null;
      this.gridColumnApi = null;
      this.gridAssets = null;
    },

    handleFoldersFilter(currentText) {
      // Handle folders grid quick search
      this.lastSearch = null;
      if (!currentText) {
        this.textFilterValue = null;
        this.gridApi.setQuickFilter(null);
        publishEvent('folders:filtered', null)
        return;
      }

      this.textFilterValue = currentText;

      clearTimeout(this.quickFilterTimeout);
      this.quickFilterTimeout = setTimeout(() => {
        this.gridApi.setQuickFilter(this.textFilterValue);
        this.updateSelectedAssets();
        publishEvent('folders:filtered', this.textFilterValue)
      }, 200);
    },

    foldersGridAutoSize() {
      const gridReference = this.gridReference;
      const gridApi = this.gridApi;
      const gridColumnApi = this.gridColumnApi;
      this.harbourStore.autosizeGridByReference(gridReference, gridApi, gridColumnApi);
    },

    async getAssetPDF(id) {
      // Load the PDF base 64 data for the right sidebar viewer

      this.currentPDF = null;
      this.currentAsset = null;

      const asset = this.harbourStore.myAssets.find((asset) => asset.id === id);
      if (!asset || asset.file_extension !== 'pdf') return;

      this.currentAsset = asset;
      if (!asset.base64data) {
        this.currentPDF = 'loading';
        const result = await Vue.prototype.$harbourData.get(`/folders/asset-data/${asset.id}`);
        if (result.status === 200 && result.data?.data) asset.base64data = result.data?.data;
        else asset.base64data = null;

        // If the asset is still the current asset, then update the PDF
        // Otherwise, the user has changed the asset, so show the current asset
        if (this.currentAsset?.id === asset.id) this.currentPDF = asset.base64data;
        else this.currentPDF = this.currentAsset?.base64data;
      } else {
        this.curentAsset = asset;
        this.currentPDF = asset.base64data;
      }
    },

    setCurrentExtraction(extraction) {
      this.currentExtraction = extraction;
    },

    clearSelectedAssets() {
      this.selectedAssets = [];
    },

    updateSelectedAssets() {
      const currentlySelected = [];
      if (!this.gridApi) return;
      this.gridApi.forEachNodeAfterFilterAndSort(function (node) {
        if (node.isSelected()) {
          currentlySelected.push(node.data);
        }
      });
      this.selectedAssets = currentlySelected;
    },

    removeMultipleFiles(folderId) {
      this.updateSelectedAssets();

      let filesToDelete = this.selectedAssets;
      if (filesToDelete.length === 0) {
        filesToDelete = this.harbourStore.myAssets.filter((asset) => asset.folder_id === folderId);
      }

      const fileList = filesToDelete
        .slice(0, 5)
        .map((asset) => asset.file_name)
        .join('\n');
      let confirmationStr = `Are you sure you want to delete ${filesToDelete.length} files?`;
      confirmationStr += '\n\n';
      confirmationStr += fileList;
      if (filesToDelete.length > 5) {
        confirmationStr += '...';
        confirmationStr += `\n+ ${filesToDelete.length - 5} more`;
      }

      const confirmed = window.confirm(confirmationStr);
      if (!confirmed) return;
      filesToDelete.forEach((asset) => {
        this.filesystemStartAssetDeletion(asset.id, asset.name);
      });
    },

    async loadVerificationsResults() {
      if (this.verificationResultLoaded) return;
      const verificationResultPromises = [];
      const assetItems = [];
      this.harbourStore.myAssets.forEach((asset) => {
        asset?.context_source?.agreementitemvaluesjsonarray.forEach((value) => {
          if (CHECK_TYPE_INPUTS.includes(value.itemtype)) {
            const verificationId = localStorage.getItem(
              `${VERIFICATION_NAMES_MAP[value.itemtype]}_${value.itemid}_${
                asset.context_source.agreedisplayid
              }`,
            );
            if (verificationId) {
              verificationResultPromises.push(
                verificationsApiService.getVerification(verificationId),
              );
              assetItems.push({
                assetId: asset.asset_id,
                inputId: value.itemid,
              });
            }
          }
        });
      });
      const results = await Promise.allSettled(verificationResultPromises);
      for (const [index, result] of results.entries()) {
        if (result.status === 'fulfilled') {
          const { value } = result;
          if (!value) {
            return;
          }
          const assetData = assetItems[index];
          const mainStoreAsset = this.harbourStore.myAssets.find(
            (asset) => asset.asset_id === assetData.assetId,
          );
          const mainStoreInput = mainStoreAsset.context_source.agreementitemvaluesjsonarray.find(
            (input) => input.itemid === assetData.inputId,
          );
          const contextItem = this.loadedContexts.find(
            (context) => context.id === assetData.assetId,
          );
          const contextItemInput =
            contextItem.context.context_source.agreementitemvaluesjsonarray.find(
              (input) => input.itemid === assetData.inputId,
            );
          // Drug and ID checks have different statuses
          if (value?.status === 'started') {
            mainStoreInput.itemvalue = 'Started';
            contextItemInput.itemvalue = 'Started';
          } else {
            mainStoreInput.itemvalue = VERIFICATION_RESULTS[data?.result];
            contextItemInput.itemvalue = VERIFICATION_RESULTS[data?.result];
          }
        }
      }

      this.verificationResultLoaded = true;
    },

    updateNotesByAssetId(assetId, data) {
      this.harbourStore.updateNotesByAssetId(assetId, data);
    },

    lockNotesByAssetId(data) {
      this.harbourStore.lockNotesByAssetId(data);
    },
  },
});
