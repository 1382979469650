// Templates
export const getTemplateGalleryData = (state) => state.templateGalleryData;
export const getIsLoadingOrgTemplates = (state) => state.isLoadingOrgTemplates;

// Core Data
export const getLinkById = (state) => (id) => {
  const gridApi = state.dashboardStore?.gridApi;
  if (!gridApi) return null;
  const rowNode = gridApi.getRowNode(id);
  return rowNode?.data || null;
};

export const getAgreementById = (state) => (id) =>
  state.myAgreements?.find((asset) => asset.id === id);
export const getAssetsByLink = (state) => (id) =>
  state.myAssets?.filter((asset) => asset.link_id === id);
export const getProfilePictures = (state) => state.profilePictures;
export const isAdmin = (state) => Boolean(state.contextDict?.auth_roles?.includes('orgAdmin'));
export const isGroupAdmin = (state) => Boolean(state.contextDict?.auth_roles?.includes('orgGroupAdmin'));
export const getRootFolders = (state) => state.myFolders?.filter((folder) => !folder.parent);

export const getFolderName = (state) => (id) => {
  if (!state.myFolders) return null;
  const homeFolder = `folder-home-${state.contextDict?.organizationid}-${state.contextDict?.systemuserid}`;
  if (id === 'home' || id === '#home' || id === homeFolder) return 'My folders';
  const item = state.myFolders.find((folder) => folder.id === id);
  return item?.name || null;
};

export const getCurrentFolder = (state) => {
  const folder = state.myFolders?.find((folder) => folder.id === state.currentFolder);
  return folder || null;
};

export const getCurrentFolderName = (state) => {
  const folder = getCurrentFolder(state);
  return folder ? folder.name : '';
};

export const getMyTemplates = (state) => {
  return state.myTemplates;
};

export const showEditDocument = (state) => (linkId) => {
  const check = !state.isAgreementEditorFlow && linkId;
  const gridApi = state.dashboardStore?.gridApi;
  if (!gridApi) return;

  const link = gridApi.getRowNode(linkId)?.data;
  if (!link) return false;

  return !check || !link?.email_recipients_completed?.length;
}; 

export const userColumns = (state) => {
  const folder = getCurrentFolder(state);
  return folder?.columnState?.userColumns || [];
};
