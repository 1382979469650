import { defineStore } from 'pinia';
import Vue from 'vue';
import { useHarbourStore } from '@/stores/harbour-store.js';
import organizationApiService from '@/services/organization-api-service.js';
import HrbrPlusWelcomeModal from '@components/Modals/HrbrPlusWelcomeModal.vue';
import HrbrPricingTableModal from '@components/Modals/HrbrPricingTableModal.vue';
import { ModalProgrammatic as Modal } from 'buefy';

export const useBillingStore = defineStore('billing', {
  state: () => ({
    harbourStore: useHarbourStore(),
    isPricingModalActive: false,
    isHarbourPlusWelcomeModalActive: false,
    canCancel: true,
    harplus: {},
  }),

  getters: {
    isEligibleToFremium: (state) => {
      const subdomain = state.harbourStore.contextDict?.www_urlsubdomain;
      return !(subdomain.startsWith('livetest') 
        || subdomain === "browsertestingrobot"
        || subdomain === "browsertestingrobot-staging"
        || subdomain === "browsertestingrobot-canary"
        || subdomain === "localhost"); 
    },
    isAgreementLimitReached(state) {
      if (!this.isEligibleToFremium) {
        return false
      }
      return (
        state.harbourStore.workspaceCustomProperties &&
        state.harbourStore.workspaceCustomProperties.is_paid_webapp_account_level != undefined &&
        (state.harbourStore.workspaceCustomProperties.is_paid_webapp_account_level == 'FREEMIUM' ||
          state.harbourStore.workspaceCustomProperties.is_paid_webapp_account_level == 'STARTER') &&
        state.harbourStore.workspaceCustomProperties
          .webapp_usage_current_total_current_month_agreements_signed != undefined &&
        isNaN(
          state.harbourStore.workspaceCustomProperties
            .webapp_usage_current_total_current_month_agreements_signed,
        ) == false &&
        state.harbourStore.workspaceCustomProperties
          .webapp_usage_current_total_current_month_agreements_signed > 4
      );
    },
    isAgreementLimitWillReachInOne(state) {
      if (!this.isEligibleToFremium) {
        return false
      }
      return (
        state.harbourStore.workspaceCustomProperties &&
        state.harbourStore.workspaceCustomProperties.is_paid_webapp_account_level != undefined &&
        (state.harbourStore.workspaceCustomProperties.is_paid_webapp_account_level == 'FREEMIUM' ||
          state.harbourStore.workspaceCustomProperties.is_paid_webapp_account_level == 'STARTER') &&
        state.harbourStore.workspaceCustomProperties
          .webapp_usage_current_total_current_month_agreements_signed != undefined &&
        isNaN(
          state.harbourStore.workspaceCustomProperties
            .webapp_usage_current_total_current_month_agreements_signed,
        ) == false &&
        state.harbourStore.workspaceCustomProperties
          .webapp_usage_current_total_current_month_agreements_signed == 4
      );
    },
  },

  actions: {
    preventClosing() {
      this.canCancel = false;
    },

    allowClosing() {
      this.canCancel = true;
    },

    async createBillingPortalSession() {
      if (!this.harbourStore.workspaceCustomProperties) {
        await this.harbourStore.getPersonalizationContent();
      }
      const response = await Vue.prototype.$harbourData.post('data?settings-createbillingportal', {
        requesttype: 'settings-createbillingportal',
        customer_id: this.harbourStore.workspaceCustomProperties?.stripe_customer_id,
      });
      window.open(response.data.billingPortalUrl, '_blank').focus();
    },

    async handleBillingModals() {
      // Handle harbour plus redirection or modal depending on url params
      // getting url params from the route
      const urlParams = Object.fromEntries(new URLSearchParams(location.search));
      const reference = urlParams.ref?.toLowerCase() || '';
      const redirect = urlParams.redirect?.toLowerCase() || '';
      const interval = urlParams.int?.toLowerCase() || '';

      if (
        (reference !== 'plus' || redirect === 'plus') &&
        this.harbourStore.workspaceCustomProperties?.stripe_customer_id == undefined
      ) {
        await this.harbourStore.getPersonalizationContent();
      }

      if (
        reference === 'upgrade' &&
        this.harbourStore.workspaceCustomProperties?.is_paid_webapp_account_level !== 'PLUS' &&
        this.harbourStore.workspaceCustomProperties?.is_paid_webapp_account_level !== 'ENTERPRISE'
      ) {
        // Show the upgrade modal automatically if ref=upgrade and we are not in PLUS or ENTERPRISE
        this.harplus = { redirect: false, interval: '' };
        this.openPricingTableModal();
      } else if (redirect === 'plus' && (interval === 'monthly' || interval === 'annually')) {
        if (
          !this.harbourStore.workspaceCustomProperties?.is_paid_webapp_account_level ||
          this.harbourStore.workspaceCustomProperties?.is_paid_webapp_account_level == 'STARTER'
        ) {
          // If this is a redirection for stripe, and the user is on STARTER (or null)
          this.harplus = { redirect: true, interval };
          this.openPricingTableModal();
        }
      } else if (reference === 'plus') {
        // Otherwise, if this is a new plus account, show the welcome
        this.harplus = { redirect: false, interval: '' };
        this.openHarbourPlusWelcomeModal();
      }
    },

    openHarbourPlusWelcomeModal() {
      Modal.open({
        component: HrbrPlusWelcomeModal,
        canCancel: this.canCancel,
        hasModalCard: false,
        scroll: 'keep',
        width: 850,
      });
    },

    openPricingTableModal() {
      console.log('gae_service', this.harbourStore.contextDict?.gae_service);
      Modal.open({
        component: HrbrPricingTableModal,
        canCancel: this.preventClose,
        hasModalCard: false,
        scroll: 'keep',
        width: 850,
        props: {
          system_email: this.harbourStore.contextDict?.systememail,
          gae_service: this.harbourStore.contextDict?.gae_service,
          stripeCustomerId: this.harbourStore.workspaceCustomProperties?.stripe_customer_id,
          harplus: this.harplus,
        },
      });
    },
  },
});
