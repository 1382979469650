const SUPPORTED_FONTS = [
  'Arial',
  'Courier New',
  'Courier',
  'Georgia',
  'Geneva',
  'Helvetica',
  'Lucida Sans Unicode',
  'Lucida Grande',
  'Times New Roman',
  'Times',
  'Tahoma',
  'Trebuchet MS',
  'Verdana',
  'serif',
  'sans-serif',
  'monospace',
];

export default {
  removeCommentsAndSuggestions(html) {
    const tags = ['comment-start', 'comment-end', 'suggestion-start', 'suggestion-end'];
    tags.forEach((tag) => {
      html = html.replace(new RegExp(`<${tag}[^>]*>.*?<\/${tag}>`, 'g'), '');
    });
    const suggestionAttributes = [
      'data-suggestion-end-after',
      'data-suggestion-end-before',
      'data-suggestion-start-after',
      'data-suggestion-start-before',
    ];
    const commentAttributes = [
      'data-comment-end-after',
      'data-comment-end-before',
      'data-comment-start-after',
      'data-comment-start-before',
    ];
    [...suggestionAttributes, ...commentAttributes].forEach((attr) => {
      html = html.replace(new RegExp(` ${attr}=".*?"`, 'g'), '');
    });
    return html;
  },

  async toUploadHtml(htmlString) {
    if (!htmlString) return htmlString;

    let processedHtml = htmlString;
    [
      this.getHtmlWithUnnestedLists, // unnest lists (weird nesting from docx to html conversion)
      this.getHtmlWithoutFancyQuotes, // replace fancy quotes
      this.getHtmlWithInlineStyles, // apply inline styles
      this.getHtmlWithoutUnsupportedFonts, // only supported fonts allowed
      this.emptyParElementsToUpload,
      this.processListItemMarkers,
      this.normalizeFontSize,
    ].forEach((process) => {
      processedHtml = process(processedHtml);
    });
    processedHtml = await this.getHtmlWithResizedImages(processedHtml); // resize images
    return processedHtml;
  },

  toUploadHtmlSync(htmlString) {
    if (!htmlString) return htmlString;

    let processedHtml = htmlString;
    [
      this.getHtmlWithUnnestedLists, // unnest lists (weird nesting from docx to html conversion)
      this.getHtmlWithoutFancyQuotes, // replace fancy quotes
      this.getHtmlWithInlineStyles, // apply inline styles
      this.getHtmlWithoutUnsupportedFonts, // only supported fonts allowed
      this.emptyParElementsToUpload,
      this.processListItemMarkers,
      this.normalizeFontSize,
    ].forEach((process) => {
      processedHtml = process(processedHtml);
    });
    return processedHtml;
  },

  prepareCkeditorHtmlData(
    htmlData,
    hiddenInputs,
    annotationProcessing = null,
    deleteHiddenAnnotations = false,
  ) {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = htmlData;
    const annotations = wrapper.querySelectorAll('.annotation');

    let hiddenAnnotationHandler = null;
    if (deleteHiddenAnnotations) {
      hiddenAnnotationHandler = (annotation) => annotation.remove();
    } else {
      hiddenAnnotationHandler = (annotation) => (annotation.style.display = 'none');
    }

    const hiddenAnnotations = Array.from(annotations).filter((annotation) => {
      const { itemid } = annotation.dataset;
      const hidden = hiddenInputs[itemid];
      if (hidden) {
        return annotation;
      } else if (!annotationProcessing) {
        annotation.style.removeProperty('display');
      }
    });
    hiddenAnnotations.forEach(hiddenAnnotationHandler);

    // additional in-place processing of annotations
    if (annotationProcessing) {
      annotationProcessing(annotations);
    }

    const resultHtml = wrapper.innerHTML;
    wrapper.remove();
    return resultHtml;
  },

  /**
   * Remove extra ordered lists from html
   * These lists can be added during conversion from docx to html and cause nesting
   */
  getHtmlWithUnnestedLists(htmlString) {
    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    // get all ordered lists except nested ones (top level only)
    const lists = dom.documentElement.querySelectorAll('ol:not(ol ol)');

    // unnest
    const unnest = (list) => {
      const tags = Array.from(list.children).map((l) => l.tagName);
      if (tags.length === 1 && tags[0] === 'LI') {
        const listItem = list.firstElementChild;
        const nestedList = listItem.firstElementChild;
        if (nestedList.tagName !== 'OL') {
          return;
        }
        list.replaceWith(...list.childNodes);
        listItem.replaceWith(...listItem.childNodes);
        unnest(nestedList);
      }
    };
    lists.forEach((list) => {
      unnest(list);
    });

    return dom.documentElement.innerHTML;
  },

  /**
   * remove fancy quotes and other bad characters from document html
   */
  getHtmlWithoutFancyQuotes(htmlString) {
    let processedHtml = htmlString;
    const singleQuotes = ['\u2018', '\u2019'];
    const doubleQuotes = ['\u201C', '\u201D'];
    const badChars = ['\x99', '\x9d', '\x9c', '\x9e', '\x9f']; // trademark, right double quote, left double quote, right single quote, left single quote

    const fancy_token_map = { '“': '"', '”': '"', '’': "'", '‘': "'" };

    // dash
    // replace character if surrounded by spaces
    processedHtml = processedHtml.replaceAll(' â ', ' - ');

    singleQuotes.forEach((token) => {
      processedHtml = processedHtml.replaceAll(token, "'");
    });
    doubleQuotes.forEach((token) => {
      processedHtml = processedHtml.replaceAll(token, '"');
    });
    badChars.forEach((token) => {
      processedHtml = processedHtml.replaceAll(token, '');
    });

    // replace em dash with regular dash
    processedHtml = processedHtml.replaceAll('–', '-');

    Object.keys(fancy_token_map).forEach((token) => {
      processedHtml = processedHtml.replaceAll(token, fancy_token_map[token]);
    });

    return processedHtml;
  },

  getHtmlWithInlineStyles(htmlString) {
    // note: While this html comes from ckeditor's converter, it will not have any ckeditor classes like "ck-widget", etc.
    const document = new DOMParser().parseFromString(htmlString, 'text/html');

    const tables = document.querySelectorAll('.table');
    const setFullWidth = (el) => (el.style.width = '100%');
    tables.forEach(setFullWidth);

    const cssProps = ['margin-left', 'margin-right', 'text-indent'];
    cssProps.forEach((prop) => {
      const elems = [...document.querySelectorAll(`[style*="${prop}: -"]`)];
      const resetProperty = (el) => el.style.setProperty(prop, '0px');
      elems.forEach(resetProperty);
    });
    return document.documentElement.innerHTML;
  },

  getHtmlWithoutUnsupportedFonts(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const elements = [...document.querySelectorAll('[style*="font-family"]')];
    const iterate = (elem) => {
      const fontFamily = elem.style.fontFamily;
      const defaultFont = 'Arial, Helvetica, sans-serif';
      const setDefaultFont = (elem) => (elem.style.fontFamily = defaultFont);
      const contains = (fontFamily) => SUPPORTED_FONTS.some((el) => fontFamily.includes(el));
      if (!contains(fontFamily)) setDefaultFont(elem);
    };
    elements.forEach(iterate);
    return document.documentElement.innerHTML;
  },

  async getHtmlWithResizedImages(htmlString) {
    // create div element to hold html
    const documentDiv = document.createElement('div');
    // add class to div
    documentDiv.classList.add('document-div');
    documentDiv.innerHTML = htmlString;

    documentDiv.style.position = 'absolute';
    documentDiv.style.left = '-9999px';
    documentDiv.style.top = '-9999px';
    documentDiv.style.visibility = 'hidden';

    document.body.append(documentDiv);

    const images = [...documentDiv.querySelectorAll('img')];
    const widthLimit = 1000;
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        image.onload = () => resolve(image);
        image.onerror = reject;
      });
    };

    const loadedImages = await Promise.all(images.map(async (image) => loadImage(image)));
    loadedImages.forEach((image) => {
      const width = image.naturalWidth;
      const height = image.naturalHeight;
      if (width <= widthLimit) return;

      // Initialize the canvas and it's size
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set width and height
      canvas.width = widthLimit;
      canvas.height = height - (width - widthLimit);

      // Draw image and export to data-uri
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const dataURI = canvas.toDataURL();

      // overwrite
      image.src = dataURI;
    });

    const html = documentDiv.innerHTML;
    documentDiv.remove();
    return html;
  },

  moveResizedImageOutsideSpan(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const imagesResized = [...document.querySelectorAll('img.image_resized')];
    const moveOutside = (elem) => {
      const parent = elem.parentElement;
      const hasParentSpan = parent.tagName === 'SPAN';
      const containsOneNode = parent.childNodes.length === 1; // only image without text
      const shouldMove = hasParentSpan && containsOneNode;
      if (shouldMove) parent.after(elem);
    };
    imagesResized.forEach(moveOutside);
    return document.body.innerHTML;
  },

  emptyParElementsToUpload(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const parElems = [...document.querySelectorAll('p')];

    const checkNbspElem = (el) => el.textContent === String.fromCharCode(160);
    const emptyParElems = parElems.filter(checkNbspElem);

    for (const par of emptyParElems) {
      par.style.marginTop = '0px';
      par.style.marginBottom = '0px';
    }
    return document.body.innerHTML;
  },

  processEmptyParElements(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const parElems = [...document.querySelectorAll('p')];

    const checkNbspElem = (el) => el.textContent === String.fromCharCode(160);
    const checkNextElemPar = (el) => el.nextElementSibling?.tagName === 'P';
    const checkPrevElemPar = (el) => el.previousElementSibling?.tagName === 'P';
    const checkPrevElemHasJustify = (el) => {
      return el.previousElementSibling?.style.textAlign === 'justify';
    };
    const checkPrevElemPageBreak = (el) => {
      return el.previousElementSibling?.classList.contains('page-break');
    };

    const emptyParElems = parElems.filter(checkNbspElem);
    for (const par of emptyParElems) {
      const isNextOrPrevPar = checkPrevElemPar(par) || checkNextElemPar(par);
      // remove empty par if next or prev elem is par
      // since these pars will have space in word too (before and after)
      if (isNextOrPrevPar) {
        par.remove();
        continue;
      }

      // special cases that raises issue when replace with br
      const isPrevElemHasJustify = checkPrevElemHasJustify(par);
      const isPrevElemPageBreak = checkPrevElemPageBreak(par);
      const canReplaceWithBr = !isPrevElemHasJustify && !isPrevElemPageBreak;
      // replace empty par with br
      if (canReplaceWithBr) par.replaceWith(document.createElement('br'));   
    }
    return document.body.innerHTML;
  },

  processListItemMarkers(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const listItems = [...document.querySelectorAll('body li')];
    const getListItemMarkerSize = (listItem) => {
      const innerElems = [...listItem.querySelectorAll('*')];
      let markerSize = null;
      for (const elem of innerElems) {
        const fontSize = parseFloat(elem.style.fontSize);
        const hasFontSize = !Number.isNaN(fontSize);
        if (!hasFontSize) continue;
        const hasMarkerSize = markerSize !== null;
        if (!hasMarkerSize) markerSize = Math.round(fontSize);
        else if (fontSize < markerSize) markerSize = Math.round(fontSize);
      }
      return markerSize;
    };
    for (const listItem of listItems) {
      const markerSize = getListItemMarkerSize(listItem);
      if (markerSize === null) continue;
      listItem.dataset.markerSize = markerSize;
    }
    return document.body.innerHTML;
  },

  toExportDocxHtml(htmlString) {
    if (!htmlString) return htmlString;
    let processedHtml = htmlString;

    const processFuncs = [
      this.replaceHeadingWithPar,
      this.processEmptyParElements,
      this.moveResizedImageOutsideSpan,
      this.deleteHiddenAnnotations,
    ];
    processFuncs.forEach((process) => {
      processedHtml = process(processedHtml);
    });
    return processedHtml;
  },

  replaceHeadingWithPar(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const headingElems = [...document.querySelectorAll('h1, h2, h3, h4, h5, h6')];

    const createElementToReplace = (heading) => {
      const headingHtml = heading.innerHTML;
      const headingStyle = heading.getAttribute('style');
      const parElem = document.createElement('p');
      parElem.innerHTML = headingHtml;
      if (headingStyle) parElem.setAttribute('style', headingStyle);
      return parElem;
    };

    for (const headingElem of headingElems) {
      const elementToReplace = createElementToReplace(headingElem);
      headingElem.replaceWith(elementToReplace);
    }
    return document.body.innerHTML;
  },

  normalizeFontSize(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const elements = [...document.querySelectorAll('[style*="font-size"]')];
    for (const elem of elements) {
      const fontSize = parseFloat(elem.style.fontSize);
      const hasFontSize = !Number.isNaN(fontSize);
      const equalZero = fontSize === 0;
      if (!hasFontSize || equalZero) continue;
      elem.style.fontSize = Math.round(fontSize);
    }
    return document.body.innerHTML;
  },

  deleteHiddenAnnotations(htmlString) {
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const elements = [...document.querySelectorAll('.annotation')];
    for (const elem of elements) {
      const isHidden = elem.style.display === 'none';
      if (isHidden) elem.remove();
    }
    return document.body.innerHTML;
  },
};
