import { ref, computed, unref } from 'vue';

export function useOutcome(outcome, parentRef) {

  // Self DOM element
  const domElement = ref(null);
  const parent = ref(parentRef);
  const setDomElement = (element) => (domElement.value = element);
  const getDomElement = () => domElement.value;
  const getParentElement = computed(() => parent.value?.getDomElement)

  const outcomeId = ref(outcome.outcomeId);
  const outcomeIndex = outcome.outcomeIndex;
  const owner = parent.value.id;

  const outcomeWidth = 32;
  const outcomeVerticalSeparation = 32;
  const xAdjust = 5;

  const isOutcome = ref(true);
  const title = ref(outcome.title);
  const child = ref(outcome.child);
  const childId = ref(outcome.child?.id);

  const darkColor = ref(outcome.darkColor);

  const setChild = (newChild) => {
    child.value = newChild;
    childId.value = newChild.id;
  }

  const location = computed(() => {
    const outcomeCount = parent.value?.outcomes.length;
    const splits = outcomeCount - 1;
    const blockAreaPlusOverflow = parent.value?.blockWidth + parent.value?.blockSpacing;

    // If no parent yet, return
    if (!getParentElement.value) return;

    const width = parent.value?.blockWidth - parent.value?.blockSpacing;
    let x = 0;
    let y = 0;
    if (splits === 0) x = (width / 2) - 16 - xAdjust;
    else {
      const leftSide = outcomeIndex * (blockAreaPlusOverflow / splits) - parent.value?.blockSpacing;
      const rightSide = width;

      if (outcomeIndex === 0) x = leftSide;
      else x = rightSide + 5 - parent.value?.blockSpacing / 4;
    }

    y = 0;
    return { x, y }
  })

  // Displaying closest outcomes as active dropzones when dragging blocks
  const isDropzoneActive = ref(false);
  const setDropzoneState = (state = false) => {
    isDropzoneActive.value = state;
  }

  const save = () => {
    return {
      outcomeId: outcomeId.value,
      outcomeIndex: outcomeIndex.value,
      title: title.value,
      childId: child.value?.id
    }
  }

  return {
    owner,
    outcomeId,
    outcomeIndex,
    isOutcome,
    title,
    child,

    outcomeVerticalSeparation,
    outcomeWidth,

    location,
    darkColor,

    isDropzoneActive,
    setDropzoneState,
    
    setChild,
    setDomElement,
    getDomElement,
    save
  }
};