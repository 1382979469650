import Vue from 'vue';

export default {
  async getSearchQueryMatches({ queryText, filters, numActiveFilters, pageName, signal = null }) {
    const url = '/data?search-gettopsearchquerymatches';
    const payload = {
      requesttype: 'search-gettopsearchquerymatches',
      querytext: queryText,
      filters,
      numactivefilters: numActiveFilters,
      pagename: pageName,
    };
    const res = await Vue.prototype.$harbourData.post(url, payload, null, { signal });
    return res.data;
  },

  async getAgreementTypes() {
    const res = await Vue.prototype.$harbourData.post('/data?advancedsearch-getagreementtypes', {
      requesttype: 'advancedsearch-getagreementtypes',
    });
    return res.data;
  },

  async getAccountFolders(filters) {
    const filtersData = {
      text_filter: {},
      input_filters: [],
      attachment_filters: [],
      tags_filter: {},
      creation_date_filter: {},
      is_admin_access: filters.isAdminAccess ?? false,
      page_num: 1,
    };

    const res = await Vue.prototype.$harbourData.post('/data?advancedsearch-getaccountfolders', {
      requesttype: 'advancedsearch-getaccountfolders',
      filters: filtersData,
    });
    return res.data;
  },

  async getAgreementCreators(filters) {
    const filtersData = {
      text_filter: {},
      input_filters: [],
      attachment_filters: [],
      tags_filter: {},
      creation_date_filter: {},
      is_admin_access: filters.isAdminAccess ?? false,
      page_num: 1,
    };

    const res = await Vue.prototype.$harbourData.post('data?advancedsearch-getagreementscreators', {
      requesttype: 'advancedsearch-getagreementscreators',
      filters: filtersData,
    });
    return res.data;
  },

  async runQuickSearch(filters, signal = null) {
    const url = '/data?advancedsearch-runquicksearch';
    const payload = {
      requesttype: 'advancedsearch-runquicksearch',
      filters: {
        text_filter: filters.textFilter ?? {},
        input_filters: filters.inputFilters ?? [],
        attachment_filters: filters.attachmentFilters ?? [],
        tags_filter: filters.tagsFilter ?? {},
        creation_date_filter: filters.creationDateFilter ?? {},
        is_admin_access: filters.isAdminAccess ?? false,
        page_num: 1,
      },
    };
    const res = await Vue.prototype.$harbourData.post(url, payload, null, { signal });
    return res.data;
  },

  async runAdvancedSearch(filters) {
    const res = await Vue.prototype.$harbourData.post(
      '/data?advancedsearch-applyelasticsearchfilters',
      {
        requesttype: 'advancedsearch-applyelasticsearchfilters',
        filters: filters,
      },
    );
    return res.data;
  },
};
