<script setup>
import { ref, computed, watch } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';

const harbourStore = useHarbourStore();
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  hrbrfilter: {
    type: Object,
    default: () => {
      return {
        placeholder: 'Filter...',
      };
    },
  },
});

const filterText = ref(null);
const hasText = computed(() => filterText.value);

watch(() => props.hrbrfilter.initialValue, (val) => {
  filterText.value = val;
});

// Reset the filter
const resetFilter = () => {
  filterText.value = null;
  if (props.hrbrfilter?.action) props.hrbrfilter?.action(null);
};

// Reset the filter if the 'ESC' key is pressed
const handleKeyPress = (e) => {
  if (e.key === 'Escape') {
    resetFilter();
  } else if (e.key === 'Enter' && props.hrbrfilter?.onEnterKey) {
    props.hrbrfilter?.onEnterKey();
  }
};

// Handle filter input
const handleInput = () => {
  if (!props.hrbrfilter?.action) {
    console.debug(
      '[HrbrFilter error]',
      'No action defined for dropdown. Did you forget to define the action key?',
    );
  };
  props.hrbrfilter?.action(filterText.value);
};

const isFilterDisabled = computed(() => {
  return props.hrbrfilter?.disabled;
});

const setFilter = (val) => {
  filterText.value = val;
};

defineExpose({ setFilter });
</script>

<template>
  <div class="filter-container" :class="{ active: hasText, 'filter-container-disabled': isFilterDisabled }">

    <div
      class="filter-icon filter-elements filter-icon-left"
      :class="{ 'filter-elements-disabled': isFilterDisabled, 'no-border': hasText }">
      <i v-if="props.hrbrfilter.loading" class="fal fa-spinner-third fa-spin" :class="{ 'filter-icon-left-darker': hasText }"></i>
      <i v-else class="far fa-filter" :class="{ 'filter-icon-left-darker': hasText }"></i>
    </div>

    <div class="filter-input-container">
      <input
        v-model="filterText"
        @input="handleInput"
        @keydown="handleKeyPress"
        type="text"
        class="hrbr-filter filter-elements"
        :class="{ 'filter-elements-disabled': isFilterDisabled, 'no-border': hasText }"
        :placeholder="props.hrbrfilter.placeholder" />
    </div>

    <div
      class="filter-icon filter-elements filter-icon-right"
      :class="{ 'filter-elements-disabled': isFilterDisabled, 'no-border': hasText }">
      <div class="filter-icon-right-container" @click="resetFilter" v-if="!(props.hrbrfilter.loading || isFilterDisabled)">
        <i class="fal fa-times" v-if="filterText"></i>
      </div>
    </div>

  </div>
</template>

<style scoped>
.filter-container {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid var(--section-header-border-light);
  transition: all 250ms ease;
  overflow: hidden;
  position: relative;
}
.filter-container-disabled {
  opacity: .4;
  pointer-events: none;
}
.filter-elements {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: var(--section-header-text-dark);
  text-overflow: ellipsis;
}
.hrbr-filter {
  display: flex;
  align-items: center;
  border-radius: 0;
  outline: none;
  border-left: none;
  border-right: none;
  height: 100%;
  width: 100%;
  min-width: 250px;
  text-overflow: ellipsis;
  font-size: 14px;
}
.hrbr-filter::placeholder {
  color: var(--section-header-text-light);
}
.filter-container .filter-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 7px;
  color: #616161;
  color: #555;
  height: 100%;
  font-size: 13px;
}
.filter-input-container {
  height: 100%;
  width: 100%;
  min-height: 100%;
}
.filter-icon-left {
  border-radius: 5px 0 0 5px;
  transition: color 250ms ease;
  border-right: none;
}
.filter-icon-left-darker {
  color: #333;
  transition: color 250ms ease;
}
.no-border {
  border: none;
}
.filter-icon-right {
  border-left: none;
  border-radius: 0 5px 5px 0;
}
.filter-icon-right-container {
  cursor: pointer;
  width: 10px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  transition: color 250ms ease;
}
.filter-icon-right-container:hover {
  color: black;
}

.active {
  box-shadow: none;
  border: 1px solid #1355FF;
}
.filter-container:not(.filter-container-disabled):hover  {
  border: 1px solid #1355FF;
}

@media (max-width: 768px) {
  .filter-container {
    min-width: 100%;
  }
  .hrbr-filter {
    width: 100%;
    min-width: 100%;
  }
}
</style>
