<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';

const harbourStore = useHarbourStore();
const props = defineProps({
  tgturl: {
    type: String,
    required: true,
  },
  isEditable: {
    type: Boolean,
    required: false,
  },
  linkDisplayIdToEdit: {
    type: String,
    required: true,
  },
  parent: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['close']);

const mediaSrc = ref(null);
const isMobileView = ref(false);

const onWindowResize = () => {
  if (document.body.clientWidth < 600) {
    isMobileView.value = true;
  } else {
    isMobileView.value = false;
  }
};

onMounted(() => {
  mediaSrc.value = props.tgturl;

  //Determine if this is a web view based on size
  window.addEventListener('resize', onWindowResize);
  if (document.body.clientWidth < 600) {
    isMobileView.value = true;
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize);
});

const goToEditDocument = () => {
  // Go to edit document from PDF preview
  harbourStore.openCkDocPreview();
  emit('close');
};
</script>

<template>
  <div class="container">
    <div class="top-bar">
      <button class="edit-btn" v-if="props.isEditable" @click="goToEditDocument">
        <i class="fal fa-file-pen mr-2"></i>
        Edit document
      </button>
    </div>
    <div class="iframe-container">
      <iframe
        v-if="!isMobileView"
        :src="mediaSrc"
        style="width: 100%; height: 100%; background-color: #666"></iframe>
      <iframe
        v-else
        :src="'/pdfviewer?file=' + encodeURIComponent(mediaSrc)"
        style="width: 100%; height: 100%; background-color: #666"></iframe>
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 100% !important;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top-bar {
  display: flex;
  justify-content: flex-end;
  padding-right: 75px;
  width: 100% !important;
}
.edit-btn {
  padding: 10px 15px;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  top: 5px;
  margin-top: 15px;
  color: #333;
  right: 100px;
  z-index: 999;
  cursor: pointer;
  transition: all 250ms ease;
}
.edit-btn:hover {
  background-color: #bbbbbb;
  color: rgb(0, 0, 0);
}
.iframe-container {
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
