<script setup>
import _ from 'lodash';
import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useWorkflowsStore } from '@/stores/workflows-store';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';

import HrbrFilter from '@/components/ui/HrbrFilter.vue';
import WorkflowLinkRow from '@/pages/Workflows/components/WorkflowLinkRow.vue';
import WfBlockIconCircle from './WfBlockIconCircle.vue';

const props = defineProps({
  template: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const templatesStore = useTemplatesStore();
const workflowsStore = useWorkflowsStore();
const { workflows, workflowGroups, viewFilterText, mappings } = storeToRefs(workflowsStore);
const fieldMaps = ref({});

const getGroupName = (group) => workflowGroups.value?.find((g) => g.id === group)?.name;
const filterValue = ref(null);
const getPublishedWorkflows = computed(() => {
  let flows = workflows.value
    .filter((wf) => wf.publishState)
    .map((wf) => {
      return {
        ...wf,
        groupName: getGroupName(wf.group),
      };
    });

  if (filterValue.value) {
    flows = flows.filter((wf) => {
      return (
        wf.name?.toLowerCase().includes(filterValue.value.toLowerCase()) ||
        wf.description?.toLowerCase().includes(filterValue.value.toLowerCase()) ||
        wf.groupName?.toLowerCase().includes(filterValue.value.toLowerCase())
      );
    });
  }
  return flows;
});

const handleSave = () => {
  // Perform validation here

  const id = props.template.agreement_id;
  const template = templatesStore.myTemplates.find((t) => t.agreement_id === id);
  if (!template) return;

  // Remove any workflows that were removed
  const previousMappings = initializeConnections();
  const removedFlows = previousMappings.filter((id) => !selectedWorkflows.value.includes(id)) || [];

  // Unlink any removed workflows
  removedFlows.forEach((id) => {
    const wf = workflows.value.find((wf) => wf.id === id);
    if (!wf) return;

    wf.unlinkWorkflowFromObject('template', props.template.agreement_id);
    mappings.value = mappings.value.filter((map) => {
      const sameWorkflow = map.workflow_id === id;
      const sameTemplate = map.object_id === props.template.agreement_id;
      if (sameWorkflow && sameTemplate) return false;
      return true;
    });
  });

  // Add any workflows that were added
  selectedWorkflows.value.forEach((id) => {
    const wf = workflows.value.find((wf) => wf.id === id);
    if (!wf) return;

    const newMap = { mapping: fieldMaps.value[wf.id] || [] };

    // Check if there was a previous mapping in the store
    const previousMap = mappings.value.find((map) => {
      return map.workflow_id === id && map.object_id === props.template.agreement_id
    });

    // Replace the stored mapping with the update, otherwise create a new one
    if (previousMap) previousMap.mapping = newMap;
    else {
      mappings.value.push({
        workflow_id: wf.id,
        object_id: props.template.agreement_id,
        mapping: newMap,
        workflow_name: wf.name,
      });
    }

    wf.linkWorkflowToObject('template', props.template.agreement_id, newMap);
  });
  templatesStore.agGridApi?.refreshCells();
  emit('close');
};

const handleFilterChange = (text) => {
  if (!text) return (filterValue.value = '');
  filterValue.value = text;
};

const filterObject = ref({
  placeholder: 'Filter by workflow name, description, or group...',
  action: handleFilterChange,
});

const hasPublishedFlows = computed(() => {
  return workflows.value.filter((wf) => wf.publishState);
});


const initializeConnections = () => {
  return mappings.value.filter((map) => map.object_id === props.template.agreement_id).map((map) => map.workflow_id);
};

const currentApproval = ref(null);
const selectedWorkflows = ref(initializeConnections());
const handleWorkflowToggle = (isSelected, wf, isApproval) => {
  if (isSelected) {
    if (isApproval) currentApproval.value = wf.id;
    selectedWorkflows.value.push(wf.id);
  } else {
    if (currentApproval.value === wf.id) {
      currentApproval.value = null;
    }
    selectedWorkflows.value = selectedWorkflows.value.filter((id) => id !== wf.id);
    delete fieldMaps.value[wf.id];
  }
};

const isWorkflowInTemplate = (id) => {
  return mappings.value.some((map) => map.workflow_id === id && map.object_id === props.template.agreement_id);
};

const handleFieldMapUpdate = (mapping, workflowId) => {
  fieldMaps.value[workflowId] = mapping;
};

const checkForApprovals = () => {
  const flowsToCheck = getPublishedWorkflows.value.filter((wf) =>
    selectedWorkflows.value.includes(wf.id),
  );
  for (const wf of flowsToCheck) {
    const result = wf.checkForApprovals();
    if (result) {
      return true;
    }
  }
  return false;
};

const initializeFieldMaps = () => {
  const previousMappings = initializeConnections();
  previousMappings.forEach((id) => {
    const map = mappings.value.find((map) => map.workflow_id === id && map.object_id === props.template.agreement_id);
    if (map) fieldMaps.value[id] = map.mapping?.mapping || [];
  });
};

onMounted(() => {
  checkForApprovals();
  initializeFieldMaps();
});
</script>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-head-left">
        <WfBlockIconCircle
          class="modal-card-head-icon"
          light-color="#C5DEDE"
          dark-color="#078383"
          icon="fal fa-map" />
        Add workflow to template
      </div>
    </header>

    <section class="modal-card-body" v-if="hasPublishedFlows">
      <div class="workflow-row filter-row">
        <HrbrFilter :hrbrfilter="filterObject" class="filter-input" />
      </div>
      <div class="workflow-row title-row">
        <div class="row-left">
            Available workflows
            <b-tooltip
                    label="There may be workflows that are applied to the template but due to your permissions, are not visible in this list"
                    :append-to-body="true"
                    type="is-dark"
                    size="is-large"
                    multilined>
                <i class="fa-regular fa-circle-info"></i>
            </b-tooltip>
        </div>
        <div class="row-right">Connect</div>
      </div>

      <div v-if="getPublishedWorkflows.length">
        <WorkflowLinkRow
          v-for="wf in getPublishedWorkflows"
          :key="wf.id"
          :workflow="wf"
          :is-selected="isWorkflowInTemplate(wf.id)"
          :template="template"
          :has-approval="currentApproval"
          :current-workflows="getPublishedWorkflows"
          :check-for-approvals="checkForApprovals"
          @toggle="handleWorkflowToggle"
          @field-map-update="handleFieldMapUpdate" />
      </div>
      <div v-else-if="viewFilterText">No workflows match the current filter.</div>
      <div v-else>No published workflows available</div>
    </section>

    <section class="modal-card-body" v-else>No published workflows available</section>
    <footer class="modal-card-foot">
      <b-button label="Cancel" @click="emit('close')" />
      <b-button
        v-if="hasPublishedFlows"
        label="Update"
        type="is-primary"
        @click="handleSave"
        :disabled="!getPublishedWorkflows.length" />
    </footer>
  </div>
</template>

<style scoped>
.workflow-row .row-left {
  flex-grow: 1;
}
.title-row {
  font-weight: 600;
}
.workflow-row {
  display: flex;
  width: 100%;
  min-width: 100%;
  margin: 10px 0;
}
.filter-row {
  height: 50px;
  margin-bottom: 20px;
}
.filter-input {
  width: 100%;
}
.section-title {
  font-weight: 600;
}
.fields-container {
  display: flex;
  justify-content: space-between;
}
.source-side {
  min-width: 300px;
  display: flex;
}
.block-container {
  display: flex;
  margin: 20px 0;
}
.pill {
  margin: 0 10px;
  border-radius: 8px;
  border: 1px solid #dadada;
  padding: 1px 10px;
}
.pill i {
  margin-right: 5px;
}
.main-text {
  display: flex;
  align-items: center;
}
.modal-card-head-left {
  display: flex;
  font-weight: 600;
  align-items: center;
}
.modal-card-head-icon {
  margin-right: 10px;
}
.frc-header {
  font-size: 12px;
  color: 999;
}
.field-row-cell {
  flex-grow: 1;
  padding-right: 5px;
}
.frc-icon {
  width: auto;
  padding-right: 5px;
}
.frc-icon-header {
  color: transparent;
  padding-right: 5px;
}

.field-row {
  display: flex;
  width: 100%;
}
.field-row-cell {
}
.main-text {
  font-size: 13px;
  margin: 15px 0;
}
.card-body-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.modal-card-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}
.modal-card {
  max-width: 900px;
  width: 900px;
  background-color: white;
  border-radius: 5px;
}
.modal-card-head {
  display: flex;
  justify-content: space-between;
}
.modal-card-head,
.modal-card-foot {
  padding: 10px 15px;
}
.modal-card-head-icon {
  margin-right: 20px;
}
</style>
