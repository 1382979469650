<script>
// TODO: Create new component based on ag-grid. 
// Merge "HrbrFolderSelector" and "HrbrMoveCopyAssets", if possible.

export default {
    props:["assets","allfolders","updatetablemove","mode","currentfolderid","currentfoldername","refreshassets","clearcheckedrows", "customcallback"],
    name: 'ModalMoveCopyAssets',
    data: function(){
        return{
            folderFilterText:"",
        };
    },
    methods:{

        //Close button
        closeModal:function(){
            this.$parent.close();
        },

        //Set folder destination
        setFolderDestination:function(folder){

            //Item display text
            let self = this;
            let itemTxt = "1 item";
            if(self.assets.length > 1){
                itemTxt = self.assets.length + " items";
            }

            //Validate: not the "shared" folder
            if(folder.Id == "#shared"){
                alert("You can't add items into the Shared folder itself, but you can add items into the other folders where you have edit access (including any subfolders of Shared). Please try again.");
                return false;
            }

            //Validate: attempt to move to self? (*ok, do no action in particular, but we will roll with it)
            if((self.mode == "MOVE") && (folder.Id == self.currentfolderid)){
                self.$parent.close();
                self.$buefy.toast.open({duration: 2500, message: 'Success - ' + itemTxt + ' "moved" to "' +  folder.Name + '"', position: 'is-top', type: 'is-success'});
                return false;
            }

            //Target items
            let itemIdsArray = self.assets.map(function(d){ return d });

            //Validate and move
            if(self.mode == "MOVE"){
                self.$parent.close();
                let moveCopyItemsToast = self.$buefy.toast.open({
                    duration: 8000000,
                    message: `&#9654;&nbsp;&nbsp;Starting move of ${itemTxt} now...`,
                    position: 'is-bottom',
                    type: 'is-black'
                });

                this.customcallback && this.customcallback(self.assets, self.mode, self.currentfolderid, folder.Id);
                self.validateAndMoveAssetsToNewFolderDestination(itemIdsArray,folder.Id,function(successResponse){
                    self.clearcheckedrows();
                    moveCopyItemsToast.close();
                    let isPermitted = successResponse.data.ispermitted;
                    let isPermittedItem = successResponse.data.missingacessitem;
                    if(isPermitted == false){
                        alert("I am afraid you do not have editor access to one or more of these items or the destination folder (*'" + isPermittedItem + "'). Please update and try again, or reach out to: support@harbourshare.com");
                        return false;
                    }
                    self.updatetablemove(itemIdsArray);
                    self.refreshassets([folder.Id, self.currentfolderid])
                    self.$buefy.toast.open({duration: 2500, message: 'Success - ' + itemTxt + ' moved to "' +  folder.Name + '"', position: 'is-top', type: 'is-success'});
                });
            }

            //Validate and copy
            if(self.mode == "COPY"){
                self.$parent.close();
                self.customcallback && self.customcallback(self.assets, self.mode, self.currentfolderid, folder.Id);
                let moveCopyItemsToast = self.$buefy.toast.open({
                    duration: 8000000,
                    message: `&#9654;&nbsp;&nbsp;Starting copy of ${itemTxt} now...`,
                    position: 'is-bottom',
                    type: 'is-black'
                });
                self.validateAndCopyAssetsToNewFolderDestination(itemIdsArray,folder.Id,function(successResponse){
                    self.clearcheckedrows();
                    moveCopyItemsToast.close();
                    let isPermitted = successResponse.data.ispermitted;
                    let isPermittedItem = successResponse.data.missingacessitem;
                    if(isPermitted == false){
                        alert("I am afraid you do not have editor access to one or more of these items or the destination folder (*'" + isPermittedItem + "'). Please update and try again, or reach out to: support@harbourshare.com");
                        return false;
                    }
                    self.refreshassets([folder.Id, self.currentfolderid])
                    self.$buefy.toast.open({duration: 2500, message: 'Success - ' + itemTxt + ' copied to "' +  folder.Name + '"', position: 'is-top', type: 'is-success'});
                });
            }

        },

        //Validate access and move assets to new folder
        validateAndMoveAssetsToNewFolderDestination:function(itemIdsArray,targetFolderId,successCallback){
            let self = this;

            self.$harbourData.post('data?filesystem-moveassetstonewdestinationfolder', {
                'requesttype': 'filesystem-moveassetstonewdestinationfolder',
                'assetidstomove': itemIdsArray,
                'targetfolderid': targetFolderId,
                'previousfolderid': self.currentfolderid,
            })
            .then(successResponse => {
                successCallback(successResponse);
            })
            .catch(errorResponse => {
                alert("Error: unable to move items at this time: please try again later or contact support@harbourshare.com");
            });
        },

        //Validate access and copy assets to new folder
        validateAndCopyAssetsToNewFolderDestination:function(itemIdsArray,targetFolderId,successCallback){
            let self = this;
            let createFromLocation = window.location.href;

            self.$harbourData.post('data?filesystem-copyassetstonewdestinationfolder', {
                'requesttype': 'filesystem-copyassetstonewdestinationfolder',
                'assetidstocopy': itemIdsArray,
                'targetfolderid': targetFolderId,
                'inputurl':createFromLocation,
                'include_attachments': true
            })
            .then(successResponse => {
                successCallback(successResponse);
            })
            .catch(errorResponse => {
                                alert("Error: unable to copy items at this time: please try again later or contact support@harbourshare.com");
            });
        },

        getFilteredFolders(parentFolderId) {
            return this.allfolders.filter((f) => f.Parent === parentFolderId);
        },

        checkFolderVisible(...folders) {
            return folders.some((folder) => {
                const folderName = folder.Name.toLowerCase();
                const filterText = this.folderFilterText.toLowerCase();
                return folderName.includes(filterText);
            });
        },
    },
}
</script>

<template>
  <div id="modal-card-moveassets" class="modal-card">
      <header class="modal-card-head">
          <p v-if="mode == 'MOVE' && assets.length == 1" class="modal-card-title">Move 1 item to...</p>
          <p v-if="mode == 'MOVE' && assets.length > 1" class="modal-card-title">Move {{assets.length}} items to...</p>
          <p v-if="mode == 'COPY' && assets.length == 1" class="modal-card-title">Copy 1 item to...</p>
          <p v-if="mode == 'COPY' && assets.length > 1" class="modal-card-title">Copy {{assets.length}} items to...</p>
      </header>
      <section class="modal-card-body">

          <!-- filter -->
          <b-input style="margin-bottom:15px;" v-model.trim="folderFilterText" placeholder="Filter..." type="search" icon-pack="fal" icon=""></b-input>

          <!-- all folders -->
          <ul class="menu-list">

              <!-- Level0 (*Home, Shared) -->
              <li v-for="(folder0) in allfolders.filter((f) => f.Id == '#shared' || f.Id == '#home')" :key="folder0.Id">
                  <a :title="folder0.Name" v-show="checkFolderVisible(folder0)" v-on:click="setFolderDestination(folder0)">
                      <b-icon pack="fal" v-if="folder0.Id == '#home'" icon="folder"></b-icon>
                      <b-icon pack="fal" v-if="folder0.Id == '#shared'" icon="users"></b-icon>
                      {{ folder0.Name }}
                  </a>

                  <!-- Level1 -->
                  <ul>
                      <li v-for="(folder1) in getFilteredFolders(folder0.Id)" :key="folder1.Id">
                          <a :title="folder1.Name" v-show="checkFolderVisible(folder1, folder0)" v-on:click="setFolderDestination(folder1)">
                              <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder1.Ancestors">
                                  {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                              </span>
                              <b-icon pack="fal" icon="folder"></b-icon>
                              {{folder1.Name }}
                          </a>

                          <!-- Level2 -->
                          <ul>
                              <li v-for="(folder2) in getFilteredFolders(folder1.Id)" :key="folder2.Id">
                                  <a :title="folder2.Name" v-show="checkFolderVisible(folder2, folder1, folder0)" v-on:click="setFolderDestination(folder2)">
                                      <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder2.Ancestors">
                                          {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                                      </span>
                                      <b-icon pack="fal" icon="folder"></b-icon>
                                      {{folder2.Name }}
                                  </a>

                                      <!-- Level3 -->
                                      <ul>
                                          <li v-for="(folder3) in getFilteredFolders(folder2.Id)" :key="folder3.Id">
                                              <a :title="folder3.Name" v-show="checkFolderVisible(folder3, folder2, folder1, folder0)" v-on:click="setFolderDestination(folder3)">
                                                  <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder3.Ancestors">
                                                      {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                                                  </span>
                                                  <b-icon pack="fal" icon="folder"></b-icon>
                                                  {{folder3.Name }}
                                              </a>

                                                  <!-- Level4 -->
                                                  <ul>
                                                      <li v-for="(folder4) in getFilteredFolders(folder3.Id)" :key="folder4.Id">
                                                          <a :title="folder4.Name" v-show="checkFolderVisible(folder4, folder3, folder2, folder1, folder0)" v-on:click="setFolderDestination(folder4)">
                                                              <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder4.Ancestors">
                                                                  {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                                                              </span>
                                                              <b-icon pack="fal" icon="folder"></b-icon>
                                                              {{folder4.Name }}
                                                          </a>

                                                          <!-- Level5 -->
                                                          <ul>
                                                              <li v-for="(folder5) in getFilteredFolders(folder4.Id)" :key="folder5.Id">
                                                                  <a :title="folder5.Name" v-show="checkFolderVisible(folder5, folder4, folder3, folder2, folder1, folder0)" v-on:click="setFolderDestination(folder5)">
                                                                      <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder5.Ancestors">
                                                                          {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                                                                      </span>
                                                                      <b-icon pack="fal" icon="folder"></b-icon>
                                                                      {{folder5.Name }}
                                                                  </a>

                                                                      <!-- Level6 -->
                                                                      <ul>
                                                                          <li v-for="(folder6) in getFilteredFolders(folder5.Id)" :key="folder6.Id">
                                                                              <a :title="folder6.Name" v-show="checkFolderVisible(folder6, folder5, folder4, folder3, folder2, folder1, folder0)" v-on:click="setFolderDestination(folder6)">
                                                                                  <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder6.Ancestors">
                                                                                      {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                                                                                  </span>
                                                                                  <b-icon pack="fal" icon="folder"></b-icon>
                                                                                  {{folder6.Name }}
                                                                              </a>

                                                                                  <!-- Level7 -->
                                                                                  <ul>
                                                                                      <li v-for="(folder7) in getFilteredFolders(folder6.Id)" :key="folder7.Id">
                                                                                          <a :title="folder7.Name" v-show="checkFolderVisible(folder7, folder6, folder5, folder4, folder3, folder2, folder1, folder0)" v-on:click="setFolderDestination(folder7)">
                                                                                              <span class="movePills" v-show="folderFilterText != ''" v-for="(ancestorFolder) in folder7.Ancestors">
                                                                                                  {{ ancestorFolder.Name | truncate(15) }} >&nbsp;
                                                                                              </span>
                                                                                              <b-icon pack="fal" icon="folder"></b-icon>
                                                                                              {{folder7.Name }}
                                                                                          </a>

                                                                                      </li>
                                                                                  </ul>

                                                                          </li>
                                                                      </ul>

                                                              </li>
                                                          </ul>

                                                      </li>
                                                  </ul>

                                          </li>
                                      </ul>

                              </li>
                          </ul>

                      </li>
                  </ul>

              </li>
          </ul>
      </section>
      <footer class="modal-card-foot">
        <HrbrButton :button="{ text: 'Cancel', styleType: 'secondary'}" @click="closeModal()"/>
      </footer>
  </div>
</template>
