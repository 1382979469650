import { CANADA_STATES, US_STATES } from '@/config/states.js';

export const COMMON_DEFAULT_INPUTS = [
  {
    id: 'inputtile-name',
    internalname: 'first_name',
    label: 'Enter first name',
    settingsname: 'First name',
  },
  {
    id: 'inputtile-text',
    internalname: 'middle_name',
    label: 'Enter middle name',
    settingsname: 'Middle name',
    optional: true,
    itemdescriptiontext: 'Only complete if middle name appears on ID',
  },
  {
    id: 'inputtile-name',
    internalname: 'last_name',
    label: 'Enter last name',
    settingsname: 'Last name',
  },
  {
    id: 'inputtile-eventdate',
    label: 'Add date of birth',
    internalname: 'birth_date',
    settingsname: 'Birth date',
    itemvalidations: [
      {
        itemvalidationtype: 'AGEVALIDATION',
        itemvalidationvalue: 18, // restrict to 18 years old
      },
    ],
  },
  {
    id: 'inputtile-email',
    label: 'Enter your email',
    internalname: 'email',
    settingsname: 'Email',
  },
  {
    id: 'inputtile-fulladdress',
    internalname: 'full_address',
    settingsname: 'Full address',
  },
  {
    id: 'inputtile-city',
    internalname: 'city',
    settingsname: 'City',
  },
  {
    id: 'inputtile-state',
    internalname: 'state',
    settingsname: 'State',
  },
  {
    id: 'inputtile-text',
    label: 'Enter county',
    internalname: 'county',
    settingsname: 'County',
    optional: true,
  },
  {
    id: 'inputtile-text',
    label: 'Enter country',
    internalname: 'country',
    settingsname: 'Country',
  },
];

const usCriminalCheckFields = [
  {
    id: 'inputtile-ssn',
    internalname: 'ssn',
    settingsname: 'SSN',
  },
  {
    id: 'inputtile-phonenumber',
    label: 'Enter your phone number',
    internalname: 'phone_number',
    settingsname: 'Phone number',
    inputmaskvalue: '(###)###-####',
    itemvalidations: [{ itemvalidationtype: 'PHONEMASKVALIDATION' }],
    provider: 'turn_ai',
  },
  {
    id: 'inputtile-postalcode',
    internalname: 'zipcode',
    settingsname: 'Zip code',
    provider: 'turn_ai',
  },
];

const panelDrugCheckFields = [
  {
    id: 'inputtile-ssn',
    internalname: 'ssn',
    settingsname: 'SSN',
    itemfieldtype: 'SSNTEXTINPUT',
  },
  {
    id: 'inputtile-gender',
    internalname: 'gender',
    settingsname: 'Gender',
  },
  {
    id: 'inputtile-phonenumber',
    label: 'Enter your phone number',
    internalname: 'phone_number',
    settingsname: 'Phone number',
    inputmaskvalue: '(###)###-####',
    itemvalidations: [{ itemvalidationtype: 'PHONEMASKVALIDATION' }],
  },
  {
    id: 'inputtile-postalcode',
    internalname: 'zipcode',
    settingsname: 'Zip code',
  },
];

const mvrCheckFields = [
  {
    id: 'inputtile-dropdown',
    label: 'Select license country',
    options: ['United States', 'Canada', 'Other'],
    internalname: 'license_country',
    settingsname: 'License country',
    dependency: 'license_prov_state',
  },
  {
    id: 'inputtile-dropdown',
    label: 'Select license state',
    options: Object.keys(US_STATES),
    internalname: 'license_prov_state_us',
    settingsname: 'License state',
    dependencyValue: 'United States',
  },
  {
    id: 'inputtile-dropdown',
    label: 'Select license province',
    options: Object.keys(CANADA_STATES),
    internalname: 'license_prov_state_ca',
    settingsname: 'License province',
    dependencyValue: 'Canada',
  },
  {
    id: 'inputtile-text',
    label: 'Enter license number',
    internalname: 'license_number',
    settingsname: 'License number',
  },
];

export const DEFAULT_INPUTS_MAP = {
  USBACKGROUNDCHECK: usCriminalCheckFields,
  PANELDRUGCHECK: panelDrugCheckFields,
  VEHICLERECORDCHECK: mvrCheckFields,
};

export const USBACKGROUNDCHECK_OPTIONS = [
  {
    value: 'request_us_criminal_record_check_tier_1',
    label: 'Tier 1',
  },
  {
    value: 'request_us_criminal_record_check_tier_2',
    label: 'Tier 2',
  },
  {
    value: 'request_us_criminal_record_check_tier_3',
    label: 'Tier 3',
  },
];

export const PANELDRUGCHECK_OPTIONS = [
  {
    value: '5 panel',
    label: '5 Panel Drug Check',
  },
  {
    value: '7 panel',
    label: '7 Panel Drug Check',
  },
  {
    value: '9 panel',
    label: '9 Panel Drug Check',
  },
  {
    value: '10 panel',
    label: '10 Panel Drug Check',
  },
];

export const VERIFICATION_NAMES_MAP = {
  IDENTITYCHECK: 'enhanced_identity_verification',
  USBACKGROUNDCHECK: 'us_criminal_record_check',
  VEHICLERECORDCHECK: 'motor_vehicle_record_check',
  PANELDRUGCHECK: 'panel_drug_check',
};

export const VERIFICATION_RESULTS = {
  pending: 'In progress',
  verified: 'Verified',
  rejected: 'In review',
};

export const VERIFICATION_PROVIDER_OPTIONS = [
  {
    value: 'certn',
    label: 'Certn',
  },
  {
    value: 'turn_ai',
    label: 'Turn Ai',
  },
];

export const DEFAULT_PROVIDER_PER_CHECK = {
  IDENTITYCHECK: 'certn',
  USBACKGROUNDCHECK: 'certn',
  VEHICLERECORDCHECK: 'certn',
  PANELDRUGCHECK: 'clear_star',
};
