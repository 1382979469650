import Vue, { nextTick } from 'vue';
import { defineStore } from 'pinia';
import { useHarbourStore } from '@/stores/harbour-store';
import { useCkeditorStore } from '@/components/Ckeditor/stores/ckeditor-store';
import { useDraft } from '@/composables/use-draft';
import draftsApiService from '@/services/drafts-api-service';
import generalApiService from '@/services/general-api-service';
import templateApiService from '@/services/templates-api-service';
import HrbrDraftsTemplateModal from '../components/HrbrDraftsTemplateModal.vue';
import { publishEvent } from '@/utils/bus';
import { 
  createDraft, 
  DraftMainGroups,
  DraftRoles, 
  DraftTypes, 
  DraftStatuses, 
  defineDraftRole,
} from '@/domain/drafts/draft';

import { 
  ToastProgrammatic as Toast, 
  DialogProgrammatic as Dialog, 
  ModalProgrammatic as Modal,
} from 'buefy';

export const useDraftsStore = defineStore('drafts', {
  state: () => ({
    harbourStore: useHarbourStore(),
    ckeditorStore: useCkeditorStore(),

    myDrafts: [],
    draftGroups: [
      DraftMainGroups.MyDrafts,
      DraftMainGroups.SharedDrafts,
    ],
    activeGroup: DraftMainGroups.MyDrafts,
    isDraftsInitialized: false,

    agGridApi: null,
    agGridColumnApi: null,
    agGridPaneApi: null,
    gridReference: null,

    textFilterValue: '',
    quickFilterTimeout: null,

    emailsToNames: {},

    isRenamingInSidebarPane: false,
  }),

  getters: {
    activeGroupTitle: (state) => {
      const titles = {
        [DraftMainGroups.MyDrafts]: 'My drafts',
        [DraftMainGroups.SharedDrafts]: 'Shared with me',
      };
      return titles[state.activeGroup] || '';
    },

    isUserOrgAdmin() {
      const roles = this.harbourStore.contextDict?.auth_roles ?? [];
      return roles.includes('orgAdmin');
    },
  },

  actions: {
    setDrafts(reactiveDrafts) {
      this.myDrafts = reactiveDrafts;
    },
    
    setActiveGroup(group) {
      this.activeGroup = group;
    },

    findDraftById(id) {
      const draft = this.myDrafts.find((i) => i.id === id);
      return draft || null;
    },

    findDraftByFileId(ckFileId) {
      const draft = this.myDrafts.find((i) => i.ckFileId === ckFileId);
      return draft || null;
    },

    setDraftName(id, name) {
      const draft = this.findDraftById(id);
      if (draft) draft.name = name;
    },

    setDraftNameByFileId(ckFileId, name) {
      const draft = this.findDraftByFileId(ckFileId);
      if (draft) draft.name = name;
    },

    setDraftStatus(id, status) {
      const draft = this.findDraftById(id);
      if (draft) draft.status = status;
    },

    async storeDraft({ 
      name, 
      agreementId,
      ckFileId = null, 
      role = DraftRoles.Agreement,
      status = DraftStatuses.Started,
      type = DraftTypes.Docx,
      copiedFrom = null,
    }) {
      try {
        const draftData = await draftsApiService.createDraft({
          name,
          ckFileId,
          agreementId,
          role,
          status,
          type,
          copiedFrom
        });
        const draft = createDraft(draftData);
        publishEvent('drafts:created', draft);
        return draft;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async initDrafts() {
      try {
        await this.loadDrafts();
      } finally {
        this.isDraftsInitialized = true;
      }
    },

    async loadDrafts() {
      try {
        let draftsList = await this.loadAllDrafts();

        const reactiveDrafts = [];
        for (const draft of draftsList) {
          const newDraft = this.createReactiveDraft(draft);
          reactiveDrafts.push(newDraft);
        }

        this.setDrafts(reactiveDrafts);
        this.getDraftsProfilePictures();
        this.getProfileNames();

        publishEvent('drafts:loaded', draftsList);
      } catch (err) {
        this.myDrafts = [];
        console.error(err);
      }
    },

    createReactiveDraft(draft) {
      const newDraft = useDraft(draft);
      newDraft.setPath();
      return newDraft;
    },

    async loadAllDrafts() {
      try {
        const respData = await draftsApiService.getDrafts();
        const draftsList = respData
          .map((item) => createDraft(item))
          .sort((a, b) => b.createdAt - a.createdAt)
        return draftsList;
      } catch (err) {
        console.error(err);
        return [];
      }
    },

    async loadDraftById(id) {
      if (!id) return null;
      try {
        const draftData = await draftsApiService.getDrafts({ draftId: id });
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async loadDraftByFileId(ckFileId) {
      if (!ckFileId) return null;
      try {
        const draftData = await draftsApiService.getDrafts({ ckFileId });
        if (!draftData) return null;
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async loadDraftNameByFileId(ckFileId) {
      if (!ckFileId) return null;
      try {
        const draftName = await draftsApiService.getDraftNameByCkFileId(ckFileId);
        return draftName;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async updateDraftNameByFileId(ckFileId, name) {
      if (!ckFileId) return null;

      const newDraftName = name.trim();
      try {
        this.setDraftNameByFileId(ckFileId, newDraftName);
        const draftName = await draftsApiService.setDraftNameByCkFileId(ckFileId, newDraftName);
        return draftName;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async updateDraftUpdatedTimeByFileId(ckFileId) {
      if (!ckFileId) return null;
      try {
        const time = await draftsApiService.updateDraftUpdatedTimeByCkFileId(ckFileId);
        return time;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async getDraftsProfilePictures() {
      if (!this.myDrafts.length) return;    
      const emails = [];
      this.myDrafts.forEach((draft) => {
        emails.push(
          ...draft.owner, 
          ...draft.writer, 
          ...draft.reader, 
          ...draft.commentator,
        );
      });
      this.harbourStore.loadProfilePictures(emails)
    },

    getProfileNames() {
      const localDataString = localStorage.getItem('hrbr_emailInputData');
      if (!localDataString) return;
      try {
        const localData = JSON.parse(localDataString);
        const { emailsToNames } = localData;
        this.emailsToNames = emailsToNames;
      } catch (err) {
        console.error(err);
        this.emailsToNames = {};
      }
    },

    async deleteDraft(id) {
      if (!id) return;

      try {
        const draftData = await draftsApiService.deleteDraft(id);
        if (draftData) {
          const draftIdx = this.myDrafts.findIndex((i) => i.id === id);
          if (draftIdx !== -1) this.myDrafts.splice(draftIdx, 1);
        }
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    deleteDraftWithConfirm(id) {
      const draft = this.findDraftById(id);
      if (!draft) return;

      const onConfirm = async () => {
        const draftData = await this.deleteDraft(id);
        if (draftData) {
          Toast.open({
            duration: 2500,
            message: 'Success - draft removed',
            position: 'is-top',
            type: 'is-success',
          });
        }
      };
      
      Dialog.confirm({
        title: 'Delete draft',
        message: `Are you sure you want to fully delete the draft ("${draft.name}")?`,
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm,
      });
    },

    async renameDraft(id, newName) {
      if (!id) return;

      const newDraftName = newName.trim();
      try {
        this.setDraftName(id, newDraftName);
        const draftData = await draftsApiService.renameDraft(id, newDraftName);
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async renameDraftWithNotify(id, newName) {
      const nameLen = newName.trim().length;
      if (!nameLen) {
        Toast.open({
          duration: 2500,
          message: 'The name must have at least one character. Please try again.',
          position: 'is-top',
          type: 'is-warning',
        });
        return;
      }
      const draftData = await this.renameDraft(id, newName);
      return draftData;
    },

    async changeDraftStatus(id, status) {
      if (!id) return;

      try {
        this.setDraftStatus(id, status);
        const draftData = await draftsApiService.updateStatus(id, status);
        return createDraft(draftData);
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async copyDraft({
      draft,
      name,
      addCopyToTitle = false,
    }) {
      const strategy = {
        [DraftTypes.Docx]: async () => {
          return await this.copyCkDraft({
            draft,
            name,
            addCopyToTitle,
          });
        },
        [DraftTypes.Pdf]: async () => {
          return await this.copyPdfDraft({
            draft,
            name,
            addCopyToTitle,
          })
        },
        default: async () => {
          console.log('not implemented');
          return null;
        },
      };

      const handler = strategy[draft.type] || strategy.default;
      const result = await handler();
      return result;
    },

    async copyCkDraft({
      draft,
      name,
      addCopyToTitle = false,
    }) {
      const isTemplate = draft.role === DraftRoles.Template;

      try {
        const htmlDataPromise = this.ckeditorStore.getCkeditorTemplateHtml(draft.agreementId);
        const copyAgreementRespPromise = templateApiService.copyAgreementTemplateRequest({
          templateId: draft.agreementId,
          fileDisplayId: draft.ckFileId,
          isTemplate,
          addCopyToTitle,
        });
        const [htmlData, copyAgreementRespData] = await Promise.all([
          htmlDataPromise,
          copyAgreementRespPromise,
        ]);

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newCkFileId = copyAgreementRespData.newfileid;
        const newName = name || `Copy of ${draft.name}`;

        const sameIds = (draft.ckFileId === newCkFileId || draft.agreementId === newAgreementId);
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const documentSharingPromise = this.ckeditorStore.saveCkeditorDocumentSharing({
          fileDisplayId: newCkFileId,
        });
        const ckeditorMetadataPromise = this.ckeditorStore.saveCkeditorMetadata({
          fileDisplayId: newCkFileId,
          refids: { activeagreementid: newAgreementId },
        });
        await Promise.all([documentSharingPromise, ckeditorMetadataPromise]);

        const newDraft = await this.storeDraft({
          name: newName,
          ckFileId: newCkFileId,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
          copiedFrom: draft.id,
        });
        return {
          draft: newDraft,
          htmlData,
          agreementId: newAgreementId,
          ckFileId: newCkFileId,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async copyPdfDraft({
      draft,
      name,
      addCopyToTitle = false,
    }) {
      const isTemplate = draft.role === DraftRoles.Template;

      try {
        const copyAgreementRespData = await templateApiService.copyAgreementTemplateRequest({
          templateId: draft.agreementId,
          fileDisplayId: draft.ckFileId,
          isTemplate,
          addCopyToTitle,
        });

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newName = name || `Copy of ${draft.name}`;

        const sameIds = (draft.agreementId === newAgreementId);
        if (sameIds) throw new Error('Error - new Id is the same as old Id');

        const newDraft = await this.storeDraft({
          name: newName,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
          copiedFrom: draft.id,
          type: DraftTypes.Pdf,
        });
        return {
          draft: newDraft,
          htmlData: null,
          agreementId: newAgreementId,
          ckFileId: null,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async createDraftFromTemplate({
      template,
      name,
      isTemplate = false,
      addCopyToTitle = false,
    }) {
      const strategy = {
        docx: async () => {
          return await this.createCkDraftFromTemplate({
            template,
            name,
            isTemplate,
            addCopyToTitle,
          });
        },
        pdf: async () => {
          return await this.createPdfDraftFromTemplate({
            template,
            name,
            isTemplate,
            addCopyToTitle,
          })
        },
        default: async () => {
          console.log('not implemented');
          return null;
        },
      };

      const templateType = this.checkCkTemplate(template) ? 'docx' : 'pdf';
      const handler = strategy[templateType] || strategy.default;
      const result = await handler();
      return result;
    },

    checkCkTemplate(template) {
      const customInputs = template.custom_input_fields_json;
      let ckFileId = customInputs.agreementcustominputfieldsjson?.ckeditoragreementid;
      if (!ckFileId) ckFileId = customInputs.ckeditoragreementid;
      const isCkTemplate = !!ckFileId;
      return isCkTemplate;
    },

    async createCkDraftFromTemplate({
      template,
      name,
      isTemplate = false,
      addCopyToTitle = false,
    }) {
      if (!template) return;

      try {
        const agreementId = template.agreement_id;
        const customInputs = template.custom_input_fields_json;
        let ckFileId = customInputs.agreementcustominputfieldsjson?.ckeditoragreementid;
        if (!ckFileId) ckFileId = customInputs.ckeditoragreementid;

        const htmlDataPromise = this.ckeditorStore.getCkeditorTemplateHtml(agreementId);
        const copyAgreementRespPromise = templateApiService.copyAgreementTemplateRequest({
          templateId: agreementId,
          fileDisplayId: ckFileId,
          isTemplate,
          addCopyToTitle,
        });
        
        const [htmlData, copyAgreementRespData] = await Promise.all([
          htmlDataPromise,
          copyAgreementRespPromise,
        ]);

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newCkFileId = copyAgreementRespData.newfileid;
        const newName = name || `Copy of ${template.title}`;

        const sameIds = (ckFileId === newCkFileId || agreementId === newAgreementId);
        if (sameIds) throw new Error('Error - new Id is the same as old Id');
        
        const documentSharingPromise = this.ckeditorStore.saveCkeditorDocumentSharing({
          fileDisplayId: newCkFileId,
        });
        const ckeditorMetadataPromise = this.ckeditorStore.saveCkeditorMetadata({
          fileDisplayId: newCkFileId,
          refids: { activeagreementid: newAgreementId },
        });
        await Promise.all([documentSharingPromise, ckeditorMetadataPromise]);

        const newDraft = await this.storeDraft({
          name: newName,
          ckFileId: newCkFileId,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
        });
        return {
          draft: newDraft,
          htmlData,
          agreementId: newAgreementId,
          ckFileId: newCkFileId,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async createPdfDraftFromTemplate({
      template,
      name,
      isTemplate = false,
      addCopyToTitle = false,
    }) {
      if (!template) return;

      try {
        const agreementId = template.agreement_id;
        const copyAgreementRespData = await templateApiService.copyAgreementTemplateRequest({
          templateId: agreementId,
          isTemplate,
          addCopyToTitle,
        });

        const newAgreementId = copyAgreementRespData.copiedagreementid;
        const newName = name || `Copy of ${template.title}`;

        const sameIds = agreementId === newAgreementId;
        if (sameIds) throw new Error('Error - new Id is the same as old Id');
        
        const newDraft = await this.storeDraft({
          name: newName,
          agreementId: newAgreementId,
          role: defineDraftRole(isTemplate),
          type: DraftTypes.Pdf,
        });
        return {
          draft: newDraft,
          htmlData: null,
          agreementId: newAgreementId,
          ckFileId: null,
        };
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    getFileOpener() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      const acceptTypes = [
        '.docx',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ]
      fileInput.accept = acceptTypes.join(',');

      const openFile = () => {
        return new Promise((resolve, reject) => {
          fileInput.onchange = async () => {
            const files = fileInput.files;
            if (!files) return resolve(null);
            const file = files.item(0);
            if (!file) return resolve(null);
            return resolve({ file });
          };
          fileInput.oncancel = () => resolve(null);
          fileInput.onerror = reject;
          fileInput.click();
        });
      };
      return openFile;
    },

    async openFromUpload({ parentComponent }) {
      try {
        const open = this.getFileOpener();
        const result = await open();
        if (!result) return;
        const { file } = result;
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        
        const strategy = {
          docx: () => {
            this.openCkDocumentFromFile({
              file,
              parentComponent,
            });
          },
          pdf: () => {
            this.openPdfDocumentFromFile({
              file,
              parentComponent,
            });
          },
          default: () => {
            console.log('not implemented');
          },
        };
        const handler = strategy[fileExtension] || strategy.default;
        handler();
      } catch (err) {
        console.error(err);
      }
    },

    async copySharedLink(ckFileId) {
      const path = '/shared-agreement';
      const url = new URL(path, window.location.origin);
      const urlParams = new URLSearchParams();
      urlParams.set('docId', ckFileId);
      url.search = urlParams.toString();
      try {
        await navigator.clipboard.writeText(url);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    async copySharedLinkWithNotify(ckFileId) {
      const result = await this.copySharedLink(ckFileId);
      if (result) {
        Toast.open({
          duration: 2000,
          message: 'Copied document link!',
          position: 'is-top',
          type: 'is-success',
        });
      }
    },

    async getDocxFile({ agreementId, name }) {
      try {
        const html = await this.ckeditorStore.getCkeditorTemplateHtml(agreementId);
        const blob = await this.ckeditorStore.exportToDocx(name, html);
        return blob;
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    async downloadDocxFileWithNotify({ agreementId, name }) {
      Toast.open({
        duration: 3000,
        message: `Downloading .docx file...`,
        position: 'is-bottom',
        type: 'is-black',
      });

      const blob = await this.getDocxFile({ agreementId, name });
      if (!blob) {
        Toast.open({
          duration: 3000,
          message: `Error: Could not download .docx file. Try again later or contact support.`,
          position: 'is-top',
          type: 'is-danger',
        });
      }

      const blobUrl = URL.createObjectURL(blob);
      const linkElem = document.createElement('a');
      linkElem.href = blobUrl;
      linkElem.download = name;
      linkElem.click();
      URL.revokeObjectURL(blobUrl);
    },

    openBlankDocument({ parentComponent }) {
      const props = {
        creationMode: 'agreement',
        isBlankCkDocument: true,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openCkDocumentFromFile({ file, parentComponent }) {
      const props = {
        creationMode: 'agreement',
        isCkDocumentFromFile: true,
        fileToUpload: file,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openPdfDocumentFromFile({ file, parentComponent }) {
      const props = {
        creationMode: 'agreement',
        isPdfDocumentFromFile: true,
        fileToUpload: file,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openDraftDocument({
      draft,
      htmlData = ' ',
      addUrlParams = false,
      parentComponent,
    }) {
      const strategy = {
        [DraftTypes.Docx]: () => {
          this.openCkDraftDocument({
            draft,
            htmlData,
            addUrlParams,
            parentComponent,
          })
        },
        [DraftTypes.Pdf]: () => {
          this.openPdfDraftDocument({
            draft,
            parentComponent,
          });
        },
        default: () => {
          console.log('not implemented');
        },
      };

      const handler = strategy[draft.type] || strategy.default;
      handler();
    },

    openCkDraftDocument({
      draft,
      htmlData = ' ',
      addUrlParams = false,
      parentComponent,
    }) {
      Vue.prototype.$openCkeditorModal({
        props: {
          fileVersionDisplayId: draft.ckFileId,
          uploadedFileName: draft.name,
          sourceDraftId: draft.id,
          // data will come from ckeditor cloud,
          // but null htmlData prop not allowed in ckeditor v40
          htmlData: htmlData || ' ',
          hasPolling: true,
          syncComplete: true,
          isAgreementEditorWorkflow: false,
          parent: parentComponent,
        },
        events: {
          'prepare-for-signature': (ckeditorData) => {
            this.openDraftForSignature({
              draft,
              ckeditorData,
              parentComponent,
            });
          },
        },
      });

      if (addUrlParams) {
        this.addCkUrlParams({
          action: 'draftdoc',
          ckFileId: draft.ckFileId,
        });
      }
    },

    openPdfDraftDocument({
      draft,
      parentComponent,
    }) {
      const props = {
        agreementId: draft.agreementId,
        sourceDraftId: draft.id,
        creationMode: draft.role,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    addCkUrlParams({ action, ckFileId }) {
      const url = new URL(window.location);
      if (action) url.searchParams.set('ckeditoraction', action);
      if (ckFileId) url.searchParams.set('ckeditorfileid', ckFileId);  
      window.history.replaceState({}, '', url);
    },

    clearCkUrlParams() {
      const url = new URL(window.location);
      const hasAction = url.searchParams.has('ckeditoraction');
      const hasCkFileId = url.searchParams.has('ckeditorfileid');
      if (hasAction || hasCkFileId) {
        url.searchParams.delete('ckeditoraction');
        url.searchParams.delete('ckeditorfileid');
        window.history.replaceState({}, '', url);
      }
    },

    openDraftForSignature({ draft, ckeditorData, parentComponent, source = null }) {
      const props = {
        agreementId: draft.agreementId,
        ckeditorFileId: draft.ckFileId,
        sourceDraftId: draft.id,
        creationMode: draft.role,
        isSkipToCk: false,
        source,
        documentTitle: ckeditorData?.documentTitle || null,
        ckHtmlAnchors: ckeditorData?.anchors,
        lastbrand: true,
        parent: parentComponent,
      };
      Vue.prototype.$openAgreementEditorModal({ props });
    },

    openFromTemplateModal() {
      Modal.open({
        component: HrbrDraftsTemplateModal,
        hasModalCard: true,
        width: 320,
        canCancel: false,
      });
    },

    checkDraftSameOrg(orgId) {
      const contextOrgId = this.harbourStore.contextDict?.organizationid;      
      return !!orgId && orgId === contextOrgId;
    },

    checkUserOrgAdminPermission(orgId) {
      const isDraftSameOrg = this.checkDraftSameOrg(orgId);
      return this.isUserOrgAdmin && isDraftSameOrg;
    },

    onDraftCreatedEvent(draft) {
      if (!draft) return;
      const newDraft = this.createReactiveDraft(draft);
      const newMyDrafts = [newDraft, ...this.myDrafts];
      this.myDrafts = newMyDrafts;
    },

    // TODO - rename for general use?
    async onShareSettingsUpdatedEvent(draftId) {
      if (!draftId) return;
      const draft = await this.loadDraftById(draftId);
      if (!draft) return;
      const newDraft = this.createReactiveDraft(draft);
      const index = this.myDrafts.findIndex((i) => i.id === draftId);
      if (index !== -1) {
        const newMyDrafts = [...this.myDrafts];
        newMyDrafts.splice(index, 1, newDraft);
        this.myDrafts = newMyDrafts;
        await nextTick();
        const rowNode = this.agGridApi?.getRowNode(draftId);
        if (rowNode) this.agGridApi?.redrawRows({ rowNodes: [rowNode] });
      }
    },

    updateGridTextFilter(currentText) {
      if (!currentText) {
        this.textFilterValue = '';
        this.agGridApi?.setQuickFilter('');
        return;
      }
      this.textFilterValue = currentText;

      clearTimeout(this.quickFilterTimeout);
      this.quickFilterTimeout = setTimeout(() => {
        const filterText = this.textFilterValue;
        this.agGridApi?.setQuickFilter(filterText);
      }, 200);
    },

    draftsGridAutoSize() {
      const gridReference = this.gridReference;
      const gridApi = this.agGridApi;
      const gridColumnApi = this.agGridColumnApi;
      this.harbourStore.autosizeGridByReference(gridReference, gridApi, gridColumnApi);
    },
  },
});
