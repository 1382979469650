<script>
export default {
  name: 'HrbrPlusWelcomeModal',

  methods: {
    handleClose() {
      this.$parent.close();
    },
  },
};
</script>

<template>
  <!-- HARBOUR+ UPGRADE -->
  <div class="harbour-plus-modal">
    <lottie-player
      src="/src/assets/animations/harplus-title-fade-new.json"
      background="transparent"
      class="harplus-lottie"
      speed="1.5"
      autoplay />
    <div class="harbourplus">
      <div class="spacer" style="height: 200px"></div>

      <p class="description">Your workspace has been upgraded</p>
      <p class="description">(you can now complete up to 50 agreements per month)</p>
      <button class="start-button" @click="handleClose()">
        <span>Get started</span>
      </button>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
#hrbr-add-new-pages-modal .hrbr-add-new-pages-modal__page-location-select select {
  padding-left: 36px;
}

.harbour-plus-modal {
  height: 100%;
  min-height: 480px;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 50px;
  background-color: #1255fe;
  border-radius: 10px;
  background-image: url('/static/imgs/banner-bg-2.svg');
  background-size: 57%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.harbourplus {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  color: #fff;
}

.harplus-lottie {
  pointer-events: none;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='your_transparent.png', sizingMethod='scale');
  background: none !important;
  position: absolute;
  top: -25px;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
}

.description {
  font-size: 14px;
}

.start-button {
  margin-top: 30px;
  padding: 14px 35px;
  outline: none;
  background-color: #fff;
  border: none;
  border-radius: 24px;
  font-weight: 600;
  font-size: 17px;
  color: #061237;
  cursor: pointer;
}
</style>
