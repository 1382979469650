import Vue from 'vue';

const basePath = "/api/drafts";

const _updateDraft = async (draftId, {
  name = null,
  ck_file_id = null,
  agreement_id = null,
  
  // api default: started
  // api allowed values: started, review:internal, review:external, approved
  status = null,
} = {}) => {
  // null will be excluded backend
  const payload = {
    name,
    ck_file_id,
    agreement_id,
    status,
  };
  const resp = await Vue.prototype.$harbourData.put(`${basePath}/${draftId}`, payload);
  return resp.data;
}

export default {
  async getDrafts({ ckFileId = null, draftId = null } = {}) {
    const params = [];
    if (ckFileId) params.push(`ck_file_id=${ckFileId}`);
    if (draftId) params.push(`draft_id=${draftId}`);

    let path = basePath;
    if (params.length > 0) {
      path = `${path}?${params.join('&')}`;
    }
    const resp = await Vue.prototype.$harbourData.get(path);
    return resp.data;
  },

  async createDraft({ 
    name,
    agreementId,
    ckFileId = null, 
    role = null,
    // api default: started
    // api allowed values: started, review:internal, review:external, approved
    status = null,
    // api default: docx
    // api allowed values: pdf, docx
    type = null,
    copiedFrom = null,
  } = {}) {
    const resp = await Vue.prototype.$harbourData.post(basePath, {
      name,
      ck_file_id: ckFileId,
      agreement_id: agreementId,
      role,
      status,
      type,
      copied_from: copiedFrom,
    });
    return resp.data;
  },

  async deleteDraft(draftId) {
    const resp = await Vue.prototype.$harbourData.delete(`${basePath}/${draftId}`)
    return resp.data;
  },

  async renameDraft(draftId, name) {
    return await _updateDraft(draftId, { name });
  },

  async updateStatus(draftId, status) {
    return await _updateDraft(draftId, { status });
  },
  
  // public endpoints - get/set draft title
  async getDraftNameByCkFileId(ckFileId) {
    const resp = await Vue.prototype.$harbourData.get(`${basePath}/${ckFileId}/title`)
    return resp.data.name;
  },

  async setDraftNameByCkFileId(ckFileId, name) {
    const resp = await Vue.prototype.$harbourData.put(`${basePath}/${ckFileId}/title`, { name });
    return resp.data.name;
  },

  // async updateAgreement(draftId, agreementId) {
  //   const resp = await Vue.prototype.$harbourData.put(`${basePath}/${draftId}/title`, { name });
  //   return resp.data.name;
  // },

  async updateDraftUpdatedTimeByCkFileId(ckFileId) {
  const resp = await Vue.prototype.$harbourData.put(`/api/drafts/${ckFileId}/time`, {update_time_now: true});
  return resp.data.updated_at;
  }
};
