import { ModalProgrammatic as Modal } from 'buefy';
import Vue, { getCurrentInstance } from 'vue';

import { useFolder } from '@/composables/use-folder';
import WfApprovalEditorModal from '@/pages/Workflows/components/WfApprovalEditorModal.vue';
import filesystemApiService from '@/services/filesystem-api-service';
import generalApiService from '@/services/general-api-service';
import realtimeActions from './harbour-realtime-controls';
import HarbourStoreLogger from './harbour-store-logger';

import {
  getAttachmentFileSizeString,
  getAttachmentFileTypeFromName,
} from '@/utils/helpers/inline-attachments.js';

import {
  getAvailableOrganizationalThemes,
  getLocalStoredValue,
  setLocalStoredValue,
} from '@/utils/helpers/functions';

const initializedLocations = new Set();

export default {
  // Import logger
  ...HarbourStoreLogger,
  ...realtimeActions,

  // Initialize the harbour central store.
  // This is the first thing that happens on page load.
  async init() {
    if (this.hasInitialized) return;
    this.hasInitialized = true;

    if ('serviceWorker' in navigator) this.initializeWorker();

    // If user is not org user we do not load any more data
    // Calls will error anyway if user is not org user
    const roles = this.contextDict.auth_roles || [];
    const isOrgUser = roles.includes('orgUser');
    if (!isOrgUser) return;

    this.initLogger();
    this.log('Starting store...');
    this.agreementEditorStore.loadLinksChangedSignersLocal();

    this.getPersonalizationContent();
    this.templatesStore.getRecentUsedTemplates();
    await this.initializeDataByPriority(this.location);

    // Load the rest of the data - except for dashboard
    // Which we will handle separately - see DashboardGrid.vue
    if (this.location !== 'dashboard') this.initializeDataByPriority();
  },

  addVisibilityChangeWatcher() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.lastVisibilityChange = Date.now();
        this.requestSSEStatus();
      }
    });
  },

  async initializeDataByPriority(location = null) {
    if (this.hasInitializedData) return;

    const loadersMap = {
      dashboard: this.loadDashboard,
      drafts: this.loadDrafts,
      'awaiting-my-review': this.loadAwaitingMyReview,
      'signed-by-me': this.loadMySignedAgreements,
      templates: this.loadTemplates,
      workflows: this.loadWorkflows,
    };

    if (location in loadersMap) {
      // Initialize the current section
      await loadersMap[location]();
      initializedLocations.add(location);
    } else {
      // Initialize all other sections
      this.hasInitializedData = true;

      const locations = Object.keys(loadersMap);
      for (const locationKey of locations) {
        const isInitialized = initializedLocations.has(locationKey);
        if (isInitialized) continue;
        loadersMap[locationKey]();
        initializedLocations.add(locationKey);
      }
    }
  },

  async loadWorkflows() {
    await this.workflowsStore.initializeWorkflows();
  },

  logMemory(msg) {
    if ('performance' in window && 'memory' in window.performance) {
      try {
        const usage = window.performance.memory.usedJSHeapSize / 1024 / 1024;
        console.debug(`🚛 ${msg}`, usage, 'MB');
      } catch (e) {}
    }
  },

  async loadDashboard() {
    this.initDashboard();
    this.isCoreReady = true;
    this.timing = Date.now();
    this.logMemory('Memory use after dashboard init');
  },

  async initApprovalLink(magicLink) {
    Modal.open({
      component: WfApprovalEditorModal,
      parent: Vue.$root,
      fullScreen: false,
      hasModalCard: true,
      canCancel: ['outside', 'modal'],
      props: {
        magicLink,
      },
    });
  },

  async initOnlySignerFlow(fileId) {
    const instance = getCurrentInstance();
    if (!instance) return;

    const props = {
      initial_workspaceCustomProperties: this.workspaceCustomProperties,
      system_email: this.contextDict.systememail,
      gae_service: this.contextDict.gae_service,
      creationMode: 'agreement',
      lastbrand: true,
      parent: instance?.proxy,
      onlySigner: true,
      fileId,
    };
    Vue.prototype.$openAgreementEditorModal({ props });

    const url = new URL(window.location);
    url.searchParams.delete('magic');
    window.history.replaceState({}, '', url);
  },

  async loadDrafts() {
    await this.draftsStore.initDrafts();
  },

  async loadAwaitingMyReview() {
    await this.getAwaitingMyReview();
  },

  async loadMySignedAgreements() {
    await this.getMySignedAgreements();
  },

  async loadTemplates() {
    this.loadWorkflows();
    await this.initTemplates();
  },

  initializeParamsFromServer(params) {
    const { folders, userSettings, contextDict, organization_info, userGroups } = params;
    const jsonString = JSON.stringify(params);
    const bytes = new TextEncoder().encode(jsonString).length;
    const kb = bytes / 1024;

    this.savedSettings = { ...userSettings }; // Store starts with some defaults, so we need to merge
    this.processSavedSettings(this.savedSettings);

    this.contextDict = contextDict;

    this.foldersRawData = folders;
    this.workspaceCustomProperties = organization_info?.cms_properties;

    if (organization_info) this.settingsStore.processOrgData(organization_info);
    if (userGroups) this.settingsStore.processUserGroups(userGroups);

    if (this.contextDict?.organizationid) this.initFolders();

    if (process.env.NODE_ENV === 'development') {
      this.log('*** HARBOUR STORE INIT PARAMS ***', kb, 'kb');
      this.log('\n -- User settings: ', this.savedSettings, '\n');
      this.log('\n -- Context: ', this.contextDict, '\n');
      this.log('\n -- Folders: ', this.myFolders, '\n');
      this.log('\n -- Workspace: ', this.workspaceCustomProperties, '\n');
      this.log('\n -- User groups: ', userGroups, '\n');
    }

    // Store current context (used to define last organization the user was in)
    generalApiService.storeContextDict();
  },

  processSavedSettings(data) {
    try {
      if ('savedsettings' in data && 'converttopdf' in data.savedsettings) {
        this.isConvertToPdf = data.savedsettings.converttopdf;
      }
      if ('savedsettings' in data && 'istemplatessource' in data.savedsettings) {
        this.templatesStore.isTemplatesSource = data.savedsettings.istemplatessource;
      }
      if ('savedsettings' in data && 'inputsettings' in data.savedsettings) {
        this.agreementEditorStore.inputSettingLastChanged = data.savedsettings.inputsettings;
      }
    } catch (e) {
      console.log('# Error retrieving account saved settings.');
    }
  },

  async initDashboard() {
    this.automationsStore.listParagonIntegrations();
    this.initializeTableData();
    this.isCoreReady = true;

    this.dashboardStore.generateColumns();
    this.dashboardStore.setInitialView();
  },

  async initSearchFilters() {
    await this.searchStore.loadFilters();
  },

  async refreshFolders() {
    this.foldersRawData = null;
    this.isFolderDataReady = false;
    await this.getFolders();
  },

  async getFolders() {
    const handleCatch = () => ({ allfolders: [] });
    if (!this.foldersRawData) {
      if (this.isFolderDataReady) return;

      this.isFolderDataReady = false;
      const respData = await filesystemApiService.getAllFolders().catch(handleCatch);
      this.foldersRawData = respData.allfolders;
    }
    this.initFolders();
  },

  initFolders() {
    if (!this.foldersRawData) return this.getFolders();
    const foldersData = this.foldersRawData.map(this._processFolderData);
    const foldersWithoutHome = foldersData.filter((folder) => !folder.id.startsWith('folder-home'));

    const creatorEmails = foldersData.map((folder) => folder.creatorEmail);
    this.loadProfilePictures(creatorEmails);

    const homeFolderRawData = foldersData.find((folder) => folder.id.startsWith('folder-home'));

    const homeFolder = this.createFolderData({
      id: '#home',
      name: 'My folders',
      parent: null,
      columnState: homeFolderRawData?.columnState,
      creatorEmail: homeFolderRawData?.creatorEmail,
    });
    const sharedFolder = this.createFolderData({
      id: '#shared',
      name: 'Shared with me',
      parent: null,
    });

    const linkFolderToParentPartial = this._linkFolderToParent.bind(null, foldersWithoutHome);

    let foldersLinked = JSON.parse(JSON.stringify(foldersWithoutHome));
    foldersLinked = foldersLinked
      .filter(this._excludeSpecialFolder)
      .map(linkFolderToParentPartial)
      .concat(homeFolder, sharedFolder)
      .sort(this._foldersByName);

    const reactiveFolders = [];
    foldersLinked.forEach((folder) => {
      const newFolder = useFolder(folder);
      newFolder.setFolderPath(foldersLinked);
      reactiveFolders.push(newFolder);
    });

    reactiveFolders.forEach((folder) => {
      folder.setAncestors(reactiveFolders);
    });

    const foldersBeingCopied = reactiveFolders
      .filter((folder) => folder.isCopying === true)
      .map((folder) => folder.id);

    this.foldersBeingCopied = new Set(foldersBeingCopied);
    this.setFolders(reactiveFolders);
    this.setInitialCurrentFolder();
    this.foldersMenuStore.agGridApi?.redrawRows();
    this.isFolderDataReady = true;
  },

  createFolderData(options) {
    return {
      id: options.id,
      name: options.name,
      parent: options.parent,
      origin: options.origin ?? null,
      creatorName: options.creatorName ?? null,
      creatorEmail: options.creatorEmail ?? null,
      collaborators: options.collaborators ?? [],
      viewers: options.viewers ?? [],
      columnState: options.columnState ?? [],
      isCopying: !!options.isCopying,
    };
  },

  _processFolderData(folder) {
    return this.createFolderData({
      id: folder.Id,
      name: folder.Name,
      parent: folder.Parent,
      origin: folder.Origin,
      creatorName: folder.CreatorName,
      creatorEmail: folder.CreatorEmail,
      collaborators: folder.Collaborators,
      viewers: folder.Viewers,
      columnState: folder.folder_view,
      isCopying: folder.foldercopy_is_loading,
    });
  },

  _linkFolderToParent(folders, folder) {
    const target = { ...folder };
    const isParentFound = folders.find((f) => f.id === target.parent);
    if (isParentFound) return target;
    const isCreatedByMe = target.origin === 'CREATEDBYME';
    if (isCreatedByMe) return { ...target, parent: '#home' };
    else return { ...target, parent: '#shared' };
  },

  _excludeSpecialFolder(folder) {
    return !['#home', '#shared'].includes(folder.id);
  },

  _foldersByName(a, b) {
    const name1 = a.name.toLowerCase();
    const name2 = b.name.toLowerCase();
    return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
  },

  setFolders(reactiveFolders) {
    this.myFolders = reactiveFolders;
  },

  setCurrentFolder(folderId) {
    const special = ['#shared'];
    const containsSpecial = special.includes(folderId);
    this.isMyAssetsLoading = true;

    this.libraryStore.clearSelectedAssets();
    if (!containsSpecial) {
      this.previousFolder = this.currentFolder;
      this.currentFolder = folderId;
      this.setCurrentFolderLocal(folderId);
    }

    this.getAssets();
  },

  setCurrentFolderLocal(folderId) {
    localStorage.setItem('harbourCurrentFolder', folderId);
  },

  setInitialCurrentFolder() {
    const urlParams = new URLSearchParams(window.location.search);
    const containsFolderId = urlParams.has('folderid');
    const foldersPath = window.location.pathname === '/folders';
    if (foldersPath && containsFolderId) return;
    const currentLocal = localStorage.getItem('harbourCurrentFolder');
    const folder = this.myFolders.find((folder) => folder.id === currentLocal);
    if (folder) {
      this.setCurrentFolder(folder.id);
      return;
    }
    this.setCurrentFolder('#home');
  },

  setFolderName(folderId, folderName) {
    const folder = this.myFolders.find((folder) => folder.id === folderId);
    if (folder) folder.name = folderName;
  },

  async loadProfilePictures(emails = []) {
    const emailsUnique = [...new Set(emails)];
    const handleCatch = () => ({ userdictlist: [] });
    const existingEmails = this.profilePictures.map((profile) => profile.email);
    const emailsUniqueNotLoaded = emailsUnique.filter((email) => !existingEmails.includes(email));
    if (emailsUniqueNotLoaded.length === 0) {
      // all profiles are loaded
      return;
    }

    const respData = await generalApiService
      .getProfilePicture(emailsUniqueNotLoaded)
      .catch(handleCatch);
    let userDictList = respData.userdictlist;
    userDictList = userDictList.filter((user) => user.profile_image_url);
    const newProfiles = userDictList.map((user) => {
      return { email: user.emails[0], profileImageUrl: user.profile_image_url };
    });
    this.profilePictures = [...this.profilePictures, ...newProfiles];
  },

  updateFoldersBeingCopied(folderId) {
    this.foldersBeingCopied.add(folderId);
  },

  async initTemplates() {
    try {
      const results = await Promise.allSettled([
        this.templatesStore.getTemplates(),
        this.templatesStore.getTemplateGroups(),
      ]);
      return results;
    } catch (err) {
      Sentry.captureException(new Error(err));
      console.error(err);
    }
  },

  initAnalytics() {
    this.analyticsStore.refreshAnalytics();
  },

  onWorkerInstalled(e) {
    console.debug('🦺 [service-worker] Installed', e);
  },

  // If the service worker is available, connect to it and init the sync SSE service
  initializeWorker() {
    if (!navigator?.serviceWorker) return;

    this.worker = navigator.serviceWorker;
    this.worker.addEventListener('message', this.receiveData);
    this.worker.addEventListener('controllerchange', this.initializeWorker);

    this.isWorkerReady = true;
    this.onWorkerReady();
    this.realtimeUserSync();
  },

  onWorkerReady() {
    if (!navigator?.serviceWorker) return;
    this.isWorkerReady = true;

    console.debug('🦺 [service-worker] Worker ready');
    this.addVisibilityChangeWatcher();

    console.debug('🦺 [service-worker] Requesting SSE connection');
    this.worker.controller?.postMessage({ request_type: 'sse' });
    this.requestSSEStatus();
  },

  requestSSEStatus() {
    if (this.sseRequestInterval) {
      clearInterval(this.sseRequestInterval);
    }

    this.sseRequestInterval = setInterval(() => {
      this.worker?.controller?.postMessage({ request_type: 'sse-status' });
    }, 15000);
  },

  processSSEStatus(data) {
    if (!data || (!!data && data.data?.state !== 1)) {
      this.log('SSE not active - asking worker to start it');
    } else {
      this.log('SSE status:', data);
    }
  },

  updateFolderView(folderId, view) {
    const folder = this.myFolders.find((folder) => folder.id === folderId);
    if (!view | !folder) return;
    folder.columnState = view;
  },

  async getAssets(folderId = null, forceUpdate = false) {
    if (!folderId && !this.currentFolder) return;
    if (!folderId) folderId = this.currentFolder;
    const normalizedFolderId = this.getNormalizedFolderId(folderId);

    this.logMemory('Memory use before loading assets');
    const previouslyLoaded = folderId in this.loadedFolders;

    const expireFolder = this.loadedFolders[folderId] < Date.now() - this.expireFolders;
    if (!forceUpdate && previouslyLoaded && !expireFolder) {
      this.assetsFolderName = null;
      this.isMyAssetsLoading = false;
      return;
    }

    if (previouslyLoaded) this.isMyAssetsLoading = false;
    else this.isMyAssetsLoading = true;

    // If already requested we need to wait for completion first
    if (this.folderAssetsPending.has(folderId)) return;
    this.folderAssetsPending.add(folderId);
    const result = await Vue.prototype.$harbourData.get(`/folders/myAssets/${normalizedFolderId}`);
    if (result.status !== 200) return;
    this.folderAssetsPending.delete(folderId);
    this.logMemory('Memory use before loading assets');

    // Update the folder view definition
    this.updateFolderView(folderId, result.data.folder_view);

    this.myAssets = this.myAssets.filter((asset) => {
      return asset.folder_id !== folderId;
    });

    // Some pre-processing of the asset data
    result.data.assets.data.forEach((asset) => {
      // Add the "user_typing" to properly lock the "shared notes" column
      if (asset.notes?.user_typing) {
        asset.notes.user_typing = {
          locked_by_user: asset.notes.user_typing.locked_by_user,
          locked_by_user_name: asset.notes.user_typing.locked_by_user_name,
          locked_by_user_image_url: asset.notes.user_typing.locked_by_user_image_url,
          lock_expiry_time: asset.notes.user_typing.lock_expiry_time,
        };
      }

      // Add inline attachments
      const { attachments } = asset;
      if (!attachments) {
        asset.attachments = [];
        return;
      }

      attachments.forEach((attachment, index) => {
        const attachmentJson = attachment;
        // uploaded by agreement owner, or signer, or as image stamp
        let uploadContext = null;
        const attachmentSource = attachmentJson.source;

        if (attachmentSource === 'USERATTACHMENT') {
          uploadContext = 'Signer attachment';
        } else if (attachmentSource === 'REFERENCEATTACHMENT') {
          uploadContext = 'Agreement owner attachment';
        } else if (attachmentSource === 'USERUPLOADATTACHMENT') {
          uploadContext = 'Image stamp';
        }

        const fileType = getAttachmentFileTypeFromName(attachmentJson.name || '');
        const attachmentUrl = `/attachment/${attachmentJson.id}`;
        const isLivePhoto = attachmentJson.name?.toLowerCase().endsWith('.heic');
        const thumbnailUrl = `/attachment/${attachment.id}/thumbnail`;
        const displayUrl = isLivePhoto ? attachmentUrl + '&jpegpreview=true' : attachmentUrl;
        attachments[index] = {
          id: attachmentJson.id,
          name: attachmentJson.name,
          uploaderEmail: attachmentJson.uploaderemail,
          uploadContext,
          displayUrl,
          url: attachmentUrl,
          thumbnailUrl,
          videoDuration: attachment.videoduration || 0,
          fileType,
          fileSizeString: getAttachmentFileSizeString(attachmentJson.filesizebytes || 0),
          fileSizeBytes: attachmentJson.filesizebytes,
        };
      });
    });

    // New folder, add it to our current assets
    this.myAssets = [...this.myAssets, ...result.data.assets.data];
    this.loadedFolders[folderId] = result.data.timestamp;
    this.assetsFolderName = result.data.folder_name;
    this.isMyAssetsLoading = false;
  },

  // Update user's saved settinggs(admin and view state)
  updateSavedSettings() {
    const options = {
      requesttype: 'account-updatesavedsettings',
      settingstoupdate: {
        dashboard_view_type: this.dashboardStore.adminState,
        dashboard_agreelinks_state: this.dashboardStore.mylinksCompleteState,
      },
    };
    this.updateUserSettings(options);
  },

  async processUpdates(type, updates) {
    updates.forEach((item) => {
      const id = item.id;
      const currentItem = this[type].find((item) => item.id === id);
      currentItem?.updateByColumnName(item.key, item.value);
    });
  },

  // Called when the service worker sends a message
  // This is used for pushing updates or sync data that do not get loaded via http
  receiveData(e) {
    // Routing for worker messages
    const actionsRouting = {
      'sw-installed': this.onWorkerInstalled,
      'sw-activated': this.onWorkerReady,
      'sse-status': this.processSSEStatus,
      update: this.workerMessageUpdate,
      new: this.workerMessageNew,
      delete: this.workerMessageDelete,
      liveSigner: this.workerMessageLiveSigner,
      workspaceProperties: this.workerMessageUpdateCustomProperties,
      sync_update: this.syncUpdateHandler,
      user_locations: this.userLocationsResponse,
    };

    const { request_type } = e.data;
    if (!request_type || !(request_type in actionsRouting)) return;

    // Call the appropriate function based on the request type
    actionsRouting[request_type](e.data);
  },

  sendWorkerMessage(message) {
    const worker = navigator.serviceWorker?.controller;
    if (!worker) return;
    worker.postMessage(message);
  },

  syncUpdateHandler(data) {
    const request = data.data.request_type;

    const realtimeRequests = [
      'sync_folder_viewers',
      'sync_column_change',
      'sync_folders_ai_update',
      'sync_user_entry',
      'sync_title_change',
      'sync_asset_changed',
      'sync_mage_suggestions',
      'sync_cell_lock',
    ];
    if (request === 'locations') {
      // The service worker has requested our location. We send the current location back.
      // This is used to track real time user locations (which folder someone is in).
      const folderId = this.getNormalizedFolderId(this.currentFolder);
      const location = {
        request_type: 'locations',
        data: { location: this.location, folder: folderId },
      };
      this.sendWorkerMessage({ request_type: 'sync_update', data: location });
    } else if (realtimeRequests.includes(request)) {
      this.parseRealtimeAction(data.data);
    }
  },

  workerMessageUpdateCustomProperties(data) {
    this.log('Updating workspace properties...', data.data);
    this.workspaceCustomProperties = data.data;
  },

  workerMessageLiveSigner(data) {
    const dataLinkId = data.data.link_id;
    const gridApi = this.dashboardStore.gridApi;
    const liveSessions = this.dashboardStore.liveSessions;
    if (!gridApi) return;

    const sessionId = data.data.session_id;
    let session = liveSessions?.find((session) => session.session_id === sessionId);

    const newData = {
      id: crypto.randomUUID(),
      created_time: parseInt(data.data.created_time) || Date.now(),
      item_id: data.data.item_id,
      item_type: data.data.item_type,
      item_value: data.data.item_value,
      item_details: data.data.item_details,
    };

    // Remove a live session on completion
    if (newData.item_value === '#AGREELINKVALIDATEDANDSUCCESFULLYSUBMITTED') {
      if (session)
        this.dashboardStore.liveSessions = liveSessions.filter(
          (session) => session.session_id !== sessionId,
        );
      return;
    }

    if (session) {
      const auditTrail = session.audit_trail_data_json;

      // Check if the item has a cmpletion event and if so, remove it
      if (
        auditTrail.some((item) => item.item_value === '#AGREELINKVALIDATEDANDSUCCESFULLYSUBMITTED')
      ) {
        this.dashboardStore.liveSessions = liveSessions.filter(
          (session) => session.session_id !== sessionId,
        );
      }
      session.last_updated = Date.now();
      auditTrail.unshift(newData);
    } else {
      session = {
        link_id: dataLinkId,
        session_id: data.data.session_id,
        location_data: data.data.location_data,
        audit_trail_data_json: [newData],
        total_inputs: data.data.total_inputs,
        last_updated: Date.now(),
      };
      liveSessions.unshift(session);
    }

    // Expire sessions after no activity for 5 minutes
    const currentExpiry = this.dashboardStore.liveSessionExpiries[sessionId];
    if (currentExpiry) clearInterval(currentExpiry);
    this.dashboardStore.liveSessionExpiries[sessionId] = setInterval(() => {
      this.dashboardStore.liveSessions = liveSessions.filter(
        (session) => session.session_id !== sessionId,
      );
    }, 300000);
  },

  // Process delete message from worker
  workerMessageDelete(message) {
    const { type, data } = message;
    if (type !== 'myLinks') return;

    const gridApi = this.dashboardStore.gridApi;
    if (!gridApi) return;

    // Remove the data
    const nodes = [];
    data.forEach((id) => {
      const node = gridApi.getRowNode(id);
      if (node) nodes.push(node);
    });
    gridApi.applyTransaction({ remove: nodes });
  },

  // Process push message from server with UPDATED data
  workerMessageUpdate(message) {
    this.log('** SW Update **', message.data);
    const { type, data } = message;

    // Only my links uses this right now
    if (type !== 'myLinks') return;

    // Get the grid api
    const gridApi = this.dashboardStore.gridApi;
    if (!gridApi) return;

    // Prepare the data to update
    const updatedData = [];
    const updatedIds = new Set(data.map((item) => item.id));
    Array.from(updatedIds).forEach((id) => {
      const updatesForId = data.filter((item) => item.id === id);
      const updateObj = { id };
      updatesForId.forEach((update) => (updateObj[update.key] = update.value));
      updatedData.push(updateObj);
    });

    // Update the data and flash cells
    updatedData.forEach((item) => {
      const currentItem = gridApi?.getRowNode(item.id);
      if (!currentItem) return;

      // If this is a workflow status update check if the trail is currently open
      // If the user is viewing the trail, we need to update the trail
      const currentId = currentItem.data.id;
      const isWorkflowsUpdate = 'workflows_status' in item;
      if (isWorkflowsUpdate && currentId === this.dashboardStore.selectedLink?.id) {
        const previewLink = this.dashboardStore.selectedLink;
        this.dashboardStore.selectedLink = null;
        setTimeout(() => {
          this.dashboardStore.selectedLink = previewLink;
        }, 50);
      }

      const updatedItem = { ...currentItem.data, ...item };
      gridApi?.applyTransaction({ update: [updatedItem] });
      gridApi?.redrawRows({ rowNodes: [currentItem] });

      const flashWholeRow = ['submissions', 'email_recipients_completed'];
      const updatedKeys = Object.keys(item);
      if (updatedKeys.some((key) => flashWholeRow.includes(key))) {
        gridApi?.flashCells({ rowNodes: [updatedItem] });
      } else {
        gridApi?.flashCells({ rowNodes: [updatedItem], columns: updatedKeys });
      }
    });
  },

  // Process push message from server with NEW data entry
  workerMessageNew(message) {
    console.debug('** SW New **', message.data);
    const { type, data } = message;
    this.log('** SW New **', type, data);

    // We only handle links currently
    if (type !== 'myLinks') return;
    const gridApi = this.dashboardStore.gridApi;
    if (!gridApi) return;

    const linkExists = gridApi?.getRowNode(data.id);
    if (linkExists) {
      gridApi.applyTransaction({ update: data });
    } else {
      gridApi.applyTransaction({ add: data });
    }

    // Flash cells
    const allNewItems = data.map((item) => gridApi.getRowNode(item.id));
    gridApi?.flashCells({ rowNodes: allNewItems });
  },

  // Initialize the grid
  initializeTableData() {
    this.dashboardStore.restoreUserColumnState();
    this.dashboardStore.dashboardGridAutoSize();
  },

  loadPersonalizationFromStorage() {
    // This is wrapped in try catch as sometimes accessing local storage can fail based on user settings
    // or private browsing
    try {
      const personalization = getLocalStoredValue('personalizationContent');
      const availableThemes = getAvailableOrganizationalThemes();
      const foundTheme = availableThemes.find((t) => t.themename === personalization?.theme);
      if (foundTheme) {
        this.bannerSrc = foundTheme?.themecss;
        this.titleColor = foundTheme?.titlecolor;
      }

      if (personalization?.logo) this.logoSrc = personalization?.logo;
      if (personalization?.banner) this.bannerSrc = personalization?.banner;
    } catch (err) {
      console.error(err);
    }
  },

  setPersonalization({ logo, banner, theme }) {
    const availableThemes = getAvailableOrganizationalThemes();
    const foundTheme = availableThemes.find((t) => t.themename === theme);
    this.bannerSrc = foundTheme?.themecss;
    this.titleColor = foundTheme?.titlecolor || 'fff';

    if (logo) this.logoSrc = logo;
    if (banner) this.bannerSrc = banner.startsWith('url') ? banner : `url(${banner})`;

    const latestPersonalization = {
      logo: this.logoSrc,
      banner: this.bannerSrc,
      theme,
      titleColor: this.titleColor,
    };
    setLocalStoredValue('personalizationContent', latestPersonalization);
  },

  async getPersonalizationContent(successCallback) {
    if (!this.contextDict.organizationid) return;
    try {
      const respData = await generalApiService.getPersonalizationContent();

      if (respData.latestbrand) this.latestBrand = respData.latestbrand;
      this.workspaceCustomProperties = respData.workspacecustomproperties;

      if (typeof successCallback === 'function') {
        successCallback();
      }
    } catch (err) {
      console.error(err);
    }
  },

  async setUserLocation(to, from) {
    // Tracks the user's current location within the webapp
    // e.g: folders, dashboard, etc.
    // Used for real time folders and in the future, real time anything
    if (from) this.previousLocation = from;
    if (to) this.location = to;
  },

  userExiting(folder = null) {
    // Called when a user is leaving real time land, or leaving the webapp altogether
    const folderId = this.getNormalizedFolderId(this.currentFolder);
    const locationsData = {
      request_data: {
        data: {
          folders: [],
          previousFolders: [folderId],
        },
      },
    };
    this.userLocationsResponse(locationsData);
  },

  userLocationsResponse(locationsData) {
    // This is triggered once the service worker has gathered all tab locations,
    // and then we trigger the real time server request from here.
    const { data } = locationsData.request_data;
    const folders = data?.folders || [];
    const previousFolders = data?.previousFolders;

    // Always make sure the current folder is in fact listed to avoid missing SSE events
    const currentFolder = this.getNormalizedFolderId(this.currentFolder);
    const currentLocation = this.location;
    if (currentLocation === 'folders' && !folders.includes(currentFolder)) {
      folders.push(currentFolder);
    }

    if (currentFolder in previousFolders) {
      previousFolders.splice(previousFolders.indexOf(currentFolder), 1);
    }

    const options = {
      sync_request: {
        request_type: 'user_navigated',
        request_data: {
          folders,
          previous_folders: previousFolders,
          user_image: this.contextDict?.picture,
        },
      },
    };
    Vue.prototype.$harbourData.post('/realtime/navigation', options);
  },

  async requestOtherTabLocations() {
    // This is called when the user navigates to a folder
    // And it triggers the service worker to gather all tab locations
    // So that we can send an update to the real time server
    const request = {
      request_type: 'user_navigated',
      data: {},
    };
    this.sendWorkerMessage({ request_type: 'sync_update', data: request });
  },

  autoSync() {
    // Update the user's location regularly, and use the opportunity to double check the SSE status
    const requestTime = Date.now();
    if (requestTime < this.nextSyncTime) {
      clearTimeout(this.userLocationRequestTimeout);
    }

    this.nextSyncTime = requestTime + this.syncUpdateInterval;
    this.userLocationRequestTimeout = setTimeout(() => {
      this.realtimeUserSync();
    }, this.syncUpdateInterval);
  },

  async realtimeUserSync() {
    // Track what folder the user is in, for real time updates
    // This logic is slightly complex due to a user being able to be in the same folder in multiple tabs
    // Thus leaving from one tab does not necessarily mean the user has left that folder.

    // This works by first sending a message to the service worker to gather all tabs locations
    // The worker gathers all tabs locations, and then we response to the original client with all locations
    // The original client then updates the real time server.
    if (!navigator.serviceWorker || !navigator.serviceWorker.controller) return;

    this.autoSync(); // Start auto-sync timer
    if (this.location !== 'folders') this.userExiting();
    else this.requestOtherTabLocations();
  },

  async sendColumnChangeSync(e, change, newColumn = null) {
    if (!!e && !e.finished) return;

    // Only tracking column changes in folders for now
    if (this.location !== 'folders') return;

    const folderId = this.getNormalizedFolderId(this.currentFolder);

    const state = this.columnStore.saveState();
    this.getCurrentFolder.columnState = state;

    let options = {
      column_request: {
        state: state,
        change,
        changes: [newColumn],
        folder: folderId,
      },
    };

    const viewChangeTypes = ['column-title', 'column-change', 'add-column'];
    let destination = change;
    if (viewChangeTypes.includes(change)) destination = 'column-change';
    await Vue.prototype.$harbourData.post(`/realtime/${destination}`, options);
  },

  getNormalizedFolderId(folderId) {
    if (folderId === '#home') return this.libraryStore.getHomeFolder;
    return folderId;
  },

  async loadContextDict() {
    this.contextDict = await generalApiService.getContextDict().catch(() => null);
  },

  updateNotesByAssetId(assetId, data) {
    const asset = this.myAssets.find((asset) => asset.id === assetId);
    if (!asset) {
      return;
    }

    asset.notes = data;
  },

  lockNotesByAssetId(data) {
    const asset = this.myAssets.find((asset) => asset.id === data.asset_id);
    if (!asset) {
      return;
    }

    if (!asset.notes) {
      Vue.set(asset, 'notes', { value: { extraction: '' } });
    }

    if (data.notes.user_typing) {
      Vue.set(asset.notes, 'user_typing', {
        locked_by_user: data.notes.user_typing.locked_by_user,
        locked_by_user_name: data.notes.user_typing.locked_by_user_name,
        locked_by_user_image_url: data.notes.user_typing.locked_by_user_image_url,
        lock_expiry_time: data.notes.user_typing.lock_expiry_time,
      });
    } else {
      Vue.set(asset.notes, 'user_typing', null);
    }
  },
};
