import Vue from 'vue';

export default {
  async getOrganizationById(id) {
    const { data } = await Vue.prototype.$harbourData.get(`/api/organizations/${id}`);
    return data;
  },

  async updateOrganization(id, payload) {
    const { data } = await Vue.prototype.$harbourData.put(`/api/organizations/${id}`, payload);
    return data;
  },
};
