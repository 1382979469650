import { defineStore } from 'pinia';

export const useFoldersMenuStore = defineStore('foldersMenu', {
  state: () => ({
    agGridApi: null,
    agGridColumnApi: null,
  }),

  actions: {
    setAgGridApi(api) {
      this.agGridApi = api;
    },
    setAgGridColumnApi(api) {
      this.agGridColumnApi = api;
    },
  },
});
