import Vue from 'vue';

export default {
  async getContextDict() {
    const res = await Vue.prototype.$harbourData.get('/context', {
      requesttype: 'context',
    });
    return res.data;
  },

  async getPersonalizationContent() {
    const res = await Vue.prototype.$harbourData.post('/data?account-getpersonalizationcontent', {
      requesttype: 'account-getpersonalizationcontent',
    });
    return res.data;
  },

  async getProfilePicture(emails = []) {
    const res = await Vue.prototype.$harbourData.post('/account-getprofilepicture', {
      requesttype: 'account-getprofilepicture',
      emailsarray: emails,
    });
    return res.data;
  },

  async storeContextDict() {
    const res = await Vue.prototype.$harbourData.post('/storecontext');
    return res.data;
  },

  async httpGet({ url, payload = {}, query = {}, config = {} }) {
    const res = await Vue.prototype.$harbourData.get(`/get?url=${url}`, payload, query, config);
    return res.data;
  },

  async httpPost({ url, payload = {}, query = {}, config = {} }) {
    const res = await Vue.prototype.$harbourData.post(`/post?url=${url}`, payload, query, config);
    return res.data;
  },
};
