<script setup>
import {computed } from 'vue';

const props = defineProps({
  disabled: {
    required: false,
    type: Boolean,
    default: false,
  },
  toggledOn: {
    required: false,
    type: Boolean,
    default: false,
  },
  outerOnColor: {
    required: false,
    type: String,
    default: 'var(--new-harbour-blue-light)',
  },
  innerOnColor: {
    required: false,
    type: String,
    default: 'var(--new-harbour-blue)',
  },
});

const emit = defineEmits(['click']);

const outerStyle = computed(() => {
  return {
    'background-color': props.toggledOn ? props.outerOnColor : '#dcdbdb',
  };
});

const innerStyle = computed(() => {
  return {
    'background-color': props.toggledOn ? props.innerOnColor : '#555555',
  };
});

const clickHandler = () => {
  if (props.disabled) return;
  emit('click');
};

</script>

<template>
    <div class="switch-outer"
    @click="clickHandler"
    :style="outerStyle"
    :class="{'toggled-on': toggledOn, disabled: disabled}">
        <div class="switch-inner" :style="innerStyle">
        </div>
    </div>
</template>

<style scoped>

.switch-outer,
.switch-inner {
  border-radius: 9999px;
}

.switch-outer {
  cursor: pointer;
  width: 44px;
  height: 25px;
  padding: 3px;
}

.switch-inner {
  width: 50%;
  height: 100%;
  position: relative;
  transition: .1s;
  left: 0;
}

.switch-outer.toggled-on .switch-inner {
  left: 50%;
}

.switch-outer.disabled {
  opacity: .5;
  cursor: not-allowed;
}

</style>