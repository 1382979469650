<script setup>
import { ref, computed, watchEffect } from 'vue';

const props = defineProps({
  groupId: {
    type: String,
  },
  groupName: {
    type: String,
  },
  templates: {
    type: Array,
    default: () => [],
  },
  search: {
    type: String,
  },
});

const emit = defineEmits([
  'select-template'
]);

const isOpen = ref(false);

const groupTemplates = computed(() => {
  const templatesList = [...props.templates];
  const filtered = templatesList.filter((template) => {
    return template.template_group_id === props.groupId;
  });
  return filtered;
});

watchEffect(() => {
  if (props.search) isOpen.value = true;
  else isOpen.value = false;
});

const onGroupClick = () => {
  isOpen.value = !isOpen.value;
};

const selectTemplate = (template) => {
  emit('select-template', { template })
};

const onTemplateClick = (template) => {
  selectTemplate(template);
};
</script>

<template>
  <div 
    class="hrbr-drafts-template-dropdown-item"
    :class="{'hrbr-drafts-template-dropdown-item--open': isOpen}">
    <div class="hrbr-drafts-template-dropdown-item__group" @click="onGroupClick">
      <div class="hrbr-drafts-template-dropdown-item__group-arrow">
        <i class="fa-light fa-angle-right"></i>
      </div>
      <div class="hrbr-drafts-template-dropdown-item__group-icon">
        <i class="fa-light fa-layer-group"></i>
      </div>
      <div class="hrbr-drafts-template-dropdown-item__group-name">{{ groupName }}</div>
    </div>
    
    <div class="hrbr-drafts-template-dropdown-item__templates" v-show="isOpen">
      <div class="hrbr-drafts-template-dropdown-item__templates-list">
        <div 
          class="hrbr-drafts-template-dropdown-item__template"
          v-for="template in groupTemplates"
          :key="template.agreement_unique_id"
          @click="onTemplateClick(template)">
          <div class="hrbr-drafts-template-dropdown-item__template-icon">
            <i class="fa-light fa-file"></i>
          </div>
          <div class="hrbr-drafts-template-dropdown-item__template-name">
            {{ template.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-drafts-template-dropdown-item {
  font-size: 14px;
  color: #333;
  line-height: 1.2;

  &--open {
    .hrbr-drafts-template-dropdown-item__group-arrow {
      transform: rotate(90deg);
    }
  }

  &__group {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: rgb(228 233 236 / 40%);
    }
  }

  &__group-arrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 13px;
    height: 13px;
  }

  &__group-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  &__group-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__template {
    font-size: 14px;
    color: #333;
    display: flex;
    gap: 6px;
    padding: 8px 16px 8px 40px;
    cursor: pointer;

    &:hover {
      background-color: rgb(228 233 236 / 40%);
    }
  }

  &__template-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__template-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
