import Vue from 'vue';

export default {
  async getHarbourIntegrationsNames() {
    const res = await Vue.prototype.$harbourData.post(
      'data?integrations-listparagonintegrationnames',
      {
        requesttype: 'integrations-listparagonintegrationnames',
      },
    );
    if (res.status !== 200 || res.data.state !== 'SUCCESS') {
      alert(`Error listing integration names. Please try again later. Response data: ${res.data}`);
      return [];
    }
    return res.data.paragon_integration_names;
  },

  async listHarbourIntegrations(params) {
    const res = await Vue.prototype.$harbourData.get('/api/automations', {}, params);
    if (res.status < 200 || res.status >= 300) {
      alert(`Error listing integrations. Please try again later. Response data: ${res.data}`);
      return [];
    }
    return res.data?.items || [];
  },

  async updateHarbourIntegration(automationId, data) {
    const res = await Vue.prototype.$harbourData.put(`/api/automations/${automationId}`, {
      ...data,
    });
    if (res.status < 200 || res.status >= 300) {
      let error = new Error('Error updating integration parameters');
      Sentry.captureException(error);
      throw error;
    }
    return res.data;
  },

  async deleteHarbourIntegration(automationId) {
    const res = await Vue.prototype.$harbourData.delete(`/api/automations/${automationId}`);
    if (res.status < 200 || res.status >= 300) {
      let error = new Error(`Error deleting integration parameters. Response data: ${res.data}`);
      Sentry.captureException(error);
      throw error;
    }
    return res.data;
  },

  async createHarbourIntegration(data) {
    const res = await Vue.prototype.$harbourData.post('/api/automations/', { ...data });
    if (res.status < 200 || res.status >= 300) {
      let error = new Error('Error creating integration parameters');
      Sentry.captureException(error);
      throw error;
    }
    return res.data;
  },

  async getAutomationEvents(automationId) {
    const res = await Vue.prototype.$harbourData.get(`/api/automations/${automationId}/events`);
    if (res.status < 200 || res.status >= 300) {
      let error = new Error(`Error querying automation events`);
      Sentry.captureException(error);
      throw error;
    }
    return res.data;
  },

  async triggerAutomationEvent(automationId, data) {
    const res = await Vue.prototype.$harbourData.post(`/api/automations/${automationId}/trigger`, {
      ...data,
    });
    if (res.status < 200 || res.status >= 300) {
      let error = new Error(`Error triggering automation event`);
      Sentry.captureException(error);
      throw error;
    }
    return res.data;
  },
};
