<script setup>
const props = defineProps({
  options: {
    type: Array,
    default: () => {
      return [];
    },
  },
  action: {
    type: Function,
    default: () => {},
  },
  parentId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close', 'selected']);
const handleClick = (key) => {
  emit('selected', props.options[key]);
  const option = props.options[key];
  if (!option.action) {
    console.debug('[HrbrDropdown error]',
      `No action defined for option id: ${option.id}. Did you forget to define the action key?`,
    );
  }

  option.action(props.parentId);
  emit('close');
};
</script>

<template>
  <div class="container" v-if="props.options?.length">
    <div class="inner">
      <div
        class="item"
        v-for="(item, key) in props.options"
        :key="key"
        :id="item.id"
        :class="{disabled: item.disabled}"
        @click="handleClick(key)"
        @touchend="handleClick(key)"
      >
        <div class="item-separator" v-if="item.value === 'separator'"></div>
        <div class="item-container" v-else>
          <div v-if="item.icon" class="item-icon">
            <i class="fal" :class="item.icon"></i>
          </div>
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.item-separator {
  width: 100%;
  height: 2px;
  border-bottom: 1px solid #eaeaea;
  padding: 0;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  pointer-events: none;
}
.item-container {
  display: flex;
  white-space: nowrap;
}
.container {
  background-color: white;
  border-radius: 3px;
  font-size: 14px;
  z-index: 9999;
}
.inner {
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  padding: 6px 15px;
  color: #333;
  transition: all 150ms ease;
  white-space: nowrap;
  cursor: pointer;
}
.item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-right: 5px;
}
.item:hover {
  background-color: rgb(228 233 236 / 40%);
}
</style>
