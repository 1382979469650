import Vue from 'vue';

export default {
  getWorkflows: async () => {
    const response = await Vue.prototype.$harbourData.get('/api/workflows');
    if (response.status !== 200) throw new Error('Failed to get workflows groups');
    return response.data;
  },

  getWorkflowGroups: async () => {
    const response = await Vue.prototype.$harbourData.get('/api/workflows_groups');
    if (response.status !== 200) throw new Error('Failed to get workflows groups');
    return response.data;
  },

  getWorkflowMappings: async () => {
    const response = await Vue.prototype.$harbourData.get('/api/workflow-connections');
    if (response.status !== 200) throw new Error('Failed to get workflow mappings');
    return response.data;
  },

  /*
  * Used to send events any time a value changes in the agreement editor if the user is editing
  * an approval-workflow linked agreement.
  * 
  * TODO: Need to trigger this anywhere there are changes to record (many places).
  * 
  * @param {Object} workflow
  */
  sendWorkflowsApprovalEvent: async (event) => {
    const URLParams = new URLSearchParams(window.location.search);
    const magicLink = URLParams.get('approvalid');
    event.event_location = window.location.href;
    event.magic_link = magicLink;

    await Vue.prototype.$harbourData.post('/api/workflows_approval_events', event);
  },

  

}