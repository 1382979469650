const unrenderableAttachmentIconMap = {
    pdf: 'file-pdf',
    xlsx: 'file-spreadsheet',
    xls: 'file-spreadsheet',
    docx: 'file-word',
    doc: 'file-word',
    default: 'file',
}

const renderableFileTypes = ['image', 'video', 'pdf'];

const videoFileExtensions = ['mp4', 'mov', '3gp', 'avi', 'mpeg'];
const imageFileExtensions = ['png', 'jpg', 'jpeg', 'heic', 'gif', 'tif', 'tiff'];

export const getAttachmentFileTypeFromName = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (imageFileExtensions.includes(extension)) {
        return 'image';
    } 
    if (videoFileExtensions.includes(extension)) {
        return 'video';
    } 
    if (['pdf'].includes(extension)) {
        return 'pdf';
    }
    return 'file';
}

export const attachmentIsRenderable = (attachment) => {
    return renderableFileTypes.includes(attachment.fileType) && attachment.thumbnailUrl;
}   

export const getAttachmentExtension = (attachment) => attachment.name.split('.').pop().toUpperCase();

export const getAttachmentFileIcon = (attachment) => {
    const extension = getAttachmentExtension(attachment);
    return unrenderableAttachmentIconMap[extension] || unrenderableAttachmentIconMap['default'];
}

export const getAttachmentFileSizeString = (fileSize) => {
    if (fileSize < 1000) {
        return `${fileSize} B`;
    }
    if (fileSize < 1000000) {
        return `${Math.round(fileSize / 1000)} KB`;
    }
    return `${Math.round(fileSize / 1000000)} MB`;
}

export const getAttachmentDurationString = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;

    let durationString = '';
    if (minutes) {
        durationString += `${minutes}m `;
    }
    durationString += `${seconds}s`;
    return durationString;
}

export const getAllAttachmentsTotalDuration = (attachments) => {
    const videoAttachments = attachments.filter(attachment => attachment.videoDuration);
    let duration = 0;
    videoAttachments.forEach(attachment => {
      duration += attachment.videoDuration;
    });
    return duration;
}