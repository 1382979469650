<script>
import emailService from '@/services/services-email-api-service.js';
import { emailInputStoreRecentContacts, getEmailRequest } from '@/utils/helpers/email-input-helpers';
import HrbrInput from '@/components/ui/HrbrInput.vue';
import { isCustomEmailValid } from '@/utils/helpers/input-validation-helpers.js';

export default {
  name: 'HrbrEmailSend',

  props: [
    'requestemailtype',
    'requesttitle',
    'agreementtitle',
    'requestmessage',
    'toemailsarray',
    'ccemailsarray',
    'linkpasscode',
    'agreelinkdisplayid',
    'emailInputData',
    'autoClose',
    'workflowdata',
  ],

  components: {
    HrbrInput,
  },

  data() {
    return {
      toEmailsArray: [],
      ccEmailsArray: [],
      bccEmailsArray: [],
      emailTitle: null,
      emailBody: 'Please review and sign',
      emailType: 'NUDGEEMAIL',
      localDateTimeStr: '',
      agreementTitle: '',
      isSendingByServer: false,
      isAutoClose: false,
      storeRecentContacts: emailInputStoreRecentContacts,
      notificationTypeMap: {
        NUDGEEMAIL: 'links:sign_reminder',
        REVIEWANDSIGNEMAIL: 'links:sign_request',
        WF_PENDING_APPROVER: 'workflows:approval_reminder',
      },
      ccFieldVisible: false,
      bccFieldVisible: false
    };
  },

  computed: {
    cancelBtn() {
      return {
        show: true,
        disabled: false,
        text: 'Cancel',
        action: this.closeModal,
      }
    },
    saveBtn() {
      return {
        show: true,
        disabled: this.isSendingByServer,
        loading: this.isSendingByServer,
        text: 'Send',
        iconLeft: 'fa-regular fa-paper-plane',
        styleType: 'primary',
        action: this.validateEmailsAndSend,
      }
    },
    modalTitle() {
      if (this.emailType === 'NUDGEEMAIL') return 'Send reminder';
      if (this.emailType === 'WF_PENDING_APPROVER') return 'Send approval reminder';
      return 'Final step: send to signers'
    }
  },

  mounted() {
    //Set defaults
    this.emailType = this.requestemailtype;
    this.toEmailsArray = this.toemailsarray || [];
    this.ccEmailsArray = this.ccemailsarray || [];
    this.agreementTitle = this.agreementtitle;
    this.emailTitle = this.emailType === 'NUDGEEMAIL' ? `Reminder: ${this.agreementTitle} - Please review and complete` : this.agreementTitle;
    if (this.autoClose) this.isAutoClose = true;

    if (this.emailType === 'WF_PENDING_APPROVER') {
      this.isAutoClose = true;
    }

    // overwrite subject line with request title if present
    this.emailTitle = this.requesttitle || this.emailTitle;
    // overwrite email body with request message if present
    this.emailBody = this.requestmessage || this.emailBody;
  },
  methods: {
    //Update button
    closeModal() {
      this.$parent.close();
    },

    //Update datetime string for display
    updateLocalDateTimeStr() {
      try {
        const createDate = new Date();
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        this.localDateTimeStr = createDate.toLocaleTimeString('en-US', options);
        this.localDateTimeStr = this.localDateTimeStr.replace(/^0+/, ''); //*replace any leading 0
      } catch (err) {
        this.localDateTimeStr = 'now';
      }
    },

    //Validate emails
    async validateEmailsAndSend() {
      const isEmailValid = isCustomEmailValid({
        toEmails: this.toEmailsArray,
        ccEmails: this.ccEmailsArray,
        bccEmails: this.bccEmailsArray,
        subject: this.emailTitle,
        message: this.emailBody
      });

      if (!isEmailValid) return;

      //Initiate sending
      this.isSendingByServer = true;
      try {
        if (this.emailType === 'WF_PENDING_APPROVER') {
          await this.$harbourData.post('/data?workflow-send-approver-nudge-email', {
            requesttype: 'workflow-send-approver-nudge-email',
            emailsubject: this.emailTitle,
            personalizedmessage: this.emailBody,
            agreelinkdisplayid: this.agreelinkdisplayid,
            workflowdata: this.workflowdata,
          });
        } else {
          const emailRequest = getEmailRequest(
            this.agreelinkdisplayid,
            {
              toEmails: this.toEmailsArray,
              ccEmails: this.ccEmailsArray,
              bccEmails: this.bccEmailsArray,
            },
            this.emailBody,
            this.notificationTypeMap[this.emailType],
            this.emailTitle,
          );

          await emailService.sendEmail(emailRequest);
        }
      } catch (error) {
        this.isSendingByServer = false;
        console.log(error);
        this.$buefy.toast.open({
          message: 'Not able to send email at time. Try again in a bit or contact suppport@harbourshare.com',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        return false;
      }
      this.isSendingByServer = false;

      //Success
      this.updateLocalDateTimeStr();

      this.$emit('email-sent', { toEmails: this.toEmailsArray });
      this.$buefy.toast.open({
        message: `Success - your message was sent at ${this.localDateTimeStr}`,
        type: 'is-success',
        position: 'is-top',
        duration: 3500,
      });
      if (this.isAutoClose) this.closeModal();

      // store recent contacts in DB
      this.storeRecentContacts(
        [...this.toEmailsArray, ...this.ccEmailsArray, ...this.bccEmailsArray],
        this.emailInputData.emailsToNames,
      );
    },
  },
};
</script>

<template>
  <div class="modal-emailsend modal-card hrbr-modal">

    <header class="modal-card-head">
      <p class="modal-emailsend__modal-card-title modal-card-title">
        <i class="fa-light fa-paper-plane"></i>
        <span data-testid="success-modal-title">{{ modalTitle }}</span>
      </p>
    </header>

    <section class="modal-card-body">
      <template>
        <h3 class="section-title">Personalize message and recipients</h3>

        <div class="autocomplete">
          <!-- TO emails(s) -->
          <EmailInput
            label="To"
            v-model="toEmailsArray"
            :email-store="toEmailsArray"
            :data-store="emailInputData"
            :disabled="isSendingByServer"
            :results-limit="30"
            :recents-tags-limit="5"
            :show-self-in-recents="true"
            icon-pack="far"
            icon="envelope"
            attached
            :readonly="emailType === 'WF_PENDING_APPROVER'"
            :with-cc-icons="true"
            @add-cc="ccFieldVisible = true"
            @add-bcc="bccFieldVisible = true"
          >
          </EmailInput>
        </div>

        <div
          v-if="ccFieldVisible"
          class="autocomplete"
        >
          <!-- CC emails -->
          <EmailInput
            label="Cc"
            v-model="ccEmailsArray"
            :email-store="ccEmailsArray"
            :data-store="emailInputData"
            :disabled="isSendingByServer"
            :results-limit="30"
            :recents-tags-limit="5"
            :show-self-in-recents="true"
            icon-pack="far"
            icon="envelope"
            attached
          >
          </EmailInput>
        </div>

        <div
          v-if="bccFieldVisible"
          class="autocomplete"
        >
          <!-- BCC emails(s) -->
          <EmailInput
            label="Bcc"
            v-model="bccEmailsArray"
            :email-store="bccEmailsArray"
            :data-store="emailInputData"
            :disabled="isSendingByServer"
            :results-limit="30"
            :recents-tags-limit="5"
            :show-self-in-recents="true"
            icon-pack="far"
            icon="envelope"
            attached
          >
          </EmailInput>
        </div>
      </template>

      <!-- Subject -->
      <hrbr-input
        v-model="emailTitle"
        :disabled="isSendingByServer"
        label="Subject"
      />

      <!-- Message -->
      <hrbr-input
        v-model="emailBody"
        :disabled="isSendingByServer"
        label="Message"
        is-textarea
      />
    </section>
    <footer class="modal-card-foot">
      <HrbrButton
        :button="cancelBtn"
      />

      <HrbrButton
        :button="saveBtn"
      />
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/elements/email-autocomplete-new.css";
@import "@/assets/css/elements/modal.css";

.modal-emailsend {
  height: 640px;
  >>> .input-container {
    --border-light: #dbdbdb;
    --border-focus: #1355FF;
    --label-text: #333;
    --text-color-light: #555;
    --text-color-dark: #333;
    --placeholder-color: #555;
    .input-wrap, .input-wrap textarea {
      border-radius: 8px;
    }
  }
  >>> .section-btn {
    padding: 5px 12px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    border-radius: 8px;
  }
  .section-title {
    margin-bottom: 16px;
    font-weight: 600;
    color: #333;
  }
  &__workflow-recipient {
    margin-bottom: 16px;
  }
}
.autocomplete {
  margin-bottom: 16px;
}
</style>
