<script setup>
import 'quill/dist/quill.bubble.css';
import { ref, computed, onMounted } from 'vue';
import { useWorkflowsStore } from '@/stores/workflows-store';
import WfVariablePill from '@/pages/Workflows/components/settings-modules/WfVariablePill.vue';

const workflowsStore = useWorkflowsStore();
const { generateUserVariable } = workflowsStore;

const props = defineProps({
  mapping: {
    type: Object,
    required: true,
  },
  autocompleteData: {
    type: Array,
    required: false,
  },
});

const variable = ref(props.mapping);
const entry = ref(null);
const selection = ref(null);
const emit = defineEmits(['selection']);

const handleSelect = (option) => {
  if (option.source === 'users') {
    option = generateUserVariable(option);
  }

  selection.value = option;
  variable.value.destination = option;
  emit('selection');
}

const handleBlur = (e) => {
  if (e.relatedTarget?.className === "dropdown-item") return;
  variable.value.destination = entry.value;
  emit('selection');
}

const handleRemoveLink = () => {
  selection.value = null;
  variable.value.destination = null;
  emit('selection');
}

const getFilteredData = computed(() => {
  if (!entry.value) return props.autocompleteData;

  return props.autocompleteData.filter((option) => {
    if (option.source === 'users') {
      const value = option.value?.toLowerCase().includes(entry.value.toLowerCase());
      const displayName = option.displayName?.toLowerCase().includes(entry.value.toLowerCase());
      const grouping = option.grouping?.toLowerCase().includes(entry.value.toLowerCase());
      return value || displayName || grouping;
    } else {
      const value = option.value?.toLowerCase().includes(entry.value.toLowerCase());
      const title = option.title?.toLowerCase().includes(entry.value.toLowerCase());
      const userTitle = option.userTitle?.toLowerCase().includes(entry.value.toLowerCase());
      const name = option.name?.toLowerCase().includes(entry.value.toLowerCase());
      const blockTitle = option.blockTitle?.toLowerCase().includes(entry.value.toLowerCase());
      return value || title || userTitle || name || blockTitle;
    }
  });
})

onMounted(() => {
  if (variable.value.destination) {
    if (typeof variable.value.destination === 'string') {
      entry.value = variable.value.destination;
    } else {
      selection.value = variable.value.destination;
    }
  }
})
</script>

<template>
  <div class="fields-container">
      <div class="input-container">
        <b-autocomplete
          append-to-body
          :data="getFilteredData"
          v-model="entry"
          field="value"
          class="plain-input"
          position="bottom-left"
          open-on-focus
          size="is-small"
          v-if="!selection"
          @blur="handleBlur"
          @select="handleSelect">
          <template slot-scope="props">
            <div class="autocomplete-container" v-if="props.option.source === 'users'">
              <div class="autocomplete-header">
                <i class="fal" :class="props.option.icon"></i>
                <div class="autocomplete-source">{{ props.option.userTitle }}</div>
                <div class="autocomplete-user-title" v-if="props.option.userTitle !== props.option.title">
                  {{ props.option.title }}
                </div>
              </div>
              <div class="autocomplete-user-title">{{ props.option.name }}</div>
              <div v-if="props.option.value" class="var-value">{{ props.option.value }}</div>
              <div v-else-if="props.option.title" class="var-value">{{  props.option.title }}</div>
            </div>
            
            <div class="autocomplete-container autocomplete-pill-container" v-else>
              <div class="block-title">{{ props.option.blockTitle }}</div>
              <WfVariablePill
                class="autocomplete-pill"
                :key="props.option.key"
                :variable="props.option"
                :is-locked="true"
                :allow-drag="false" />
            </div>
          </template>
        </b-autocomplete>

        <div v-else class="variable-pill">
          <i class="fal fa-link"></i>
          <WfVariablePill
            :variable="selection"
            :show-source="true"
            :allow-drag="false"
            class="autocomplete-pill"
            @remove="handleRemoveLink" />
        </div>
      </div>

  </div>
</template>

<style>
.input-container .input,
.input-container input:focus,
.input-container input:active {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
</style>

<style scoped>
.autocomplete-pill {
  width: fit-content;
}
.block-title {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 600;
}
.input-container {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  min-height: 24px;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 8px;
  margin: 3px 0;
  flex-grow: 1;
  width: 100%;
  background-color: #ffffff77;
}
.input-container i {
  margin-right: 10px;
}
.variable-pill {
  display: flex;
  align-items: center;
}
.component-row {
  width: 100%;
}
.var-value {
  font-size: 12px;
}
.field-row-cell {
  width: 50%;
  padding-right: 5px;
}
.autocomplete-header {
  display: flex;
  align-items: center;
}
.autocomplete-header i {
  margin-right: 5px;
}
.frc-source {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.fields-container {
  display: flex;
  margin: 3px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.autocomplete-container {
  z-index: 900;
  border-bottom: 1px solid #dadada;
  padding: 6px 0;
}
.autocomplete-pill-container {
  display: flex;
  align-items: center;
}
.autocomplete-user-title {
  font-size: 12px;
  color: #999;
}
.autocomplete-source {
  font-weight: 600;
  font-size: 12px;
  margin-right: 10px;
}

.frc-destination {
  padding-left: 37px;
}
.frc-input {
  :deep(input) {
    border-radius: 8px;
  }
}
.frc-input {
  font-size: 12px !important;
  width: 100%;
}
.field-details {
  width: 100%;
  padding: 10px;
  font-size: 14px;
}
.field-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}
.source-side {
  display: flex;
  align-items: center;
}
.frc-icon {
  width: auto;
  padding-right: 10px;
  color: #999;
}
.frc-icon-header {
  color: transparent;
  padding-right: 5px;
}
</style>