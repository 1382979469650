<script setup>
import { computed } from 'vue';

const props = defineProps({
  block: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  variable: {
    type: Object,
    required: true,
  },
  showSource: {
    type: Boolean,
    default: false,
  },
  isLocked: {
    type: Boolean,
    default: false,
  },
  allowDrag: {
    type: Boolean,
    default: true,
  },
  allowClick: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['remove']);

const getPillStyle = computed(() => {
  return {
    cursor: props.allowClick ? 'pointer' : 'default',
    '--wf-dark-color': props.variable?.darkColor || '#666',
    '--wf-light-color': props.variable?.lightColor || '#999',
    '--wf-light-color-faded': `${props.variable?.lightColor}33`,
    '--wf-dark-faded': props.variable?.darkColor ? props.variable.darkColor + '55' : '#66666655',
  };
});

const removeLink = () => {
  emit('click', props.variable);
  if (props.isLocked) return;
  emit('remove', props.variable);
};

/* DRAG N DROP */
const startDrag = (e) => {
  e.stopPropagation();
  const transferData = { ...props.variable };
  transferData.blockId = props.variable.owner;
  delete transferData.block;
  e.dataTransfer.setData('pill-params', JSON.stringify(transferData));
};

</script>

<template>
  <div
    :draggable="allowDrag"
    class="pill-container"
    :style="getPillStyle"
    @click="removeLink"
    @dragstart="startDrag"
    :class="{ 'can-remove': !isLocked, 'can-grab': allowDrag }">
    <i v-if="props.variable.icon" :class="props.variable.icon" class="pill-icon"></i>
    <i v-else :class="variable.icon" class="pill-icon"></i>

    <div class="link-text">
      <div v-if="props.showSource">{{ variable.blockTitle }}:</div>
      <div v-else-if="variable.displayName">{{ variable.displayName }}:</div>
      <div class="var-value">{{ variable.value }}</div>
    </div>

    <i class="fal fa-close link-remove" v-if="!props.isLocked"></i>
  </div>
</template>

<style scoped>
.var-value {
  font-weight: 600;
}
.can-remove {
  cursor: pointer;
}
.pill-container {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 12px;
  height: 25px;
  max-width: 100%;
  position: relative;
  user-select: none;
  background-color: white;
  border: 1px solid var(--wf-dark-faded);
  transition: all 250ms ease;
  cursor: pointer;
}
.can-grab {
  cursor: grab;
}
.can-remove:hover {
  background-color: var(--wf-light-color-faded);
  border: 1px solid var(--wf-dark-color);
}

.can-remove:hover .link-remove {
  opacity: 1;
}
.pill-icon {
  color: var(--wf-dark-color);
  margin-right: 5px;
}
.link-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: flex;
}
.link-text > div {
  margin-right: 5px;
}
.link-remove {
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
  background-color: white;
  font-size: 10px;
  border-radius: 50%;
  border: 1px solid #dadada;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: all 250ms ease;
  opacity: 0;
  color: #333;
  transition: all 250ms ease;
}
</style>
