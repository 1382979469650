<script setup>
import Vue, { onMounted, ref, computed, reactive } from 'vue';
import { ToastProgrammatic as Toast } from 'buefy';
import debounce from '@/utils/debounce.js';
import QRCodeStyling from 'qr-code-styling';
import { readFileAsDataUrl } from '@/utils/helpers/functions.js';
import HrbrButton from '@components/ui/HrbrButton.vue';

const props = defineProps({
  shareLink: {
    type: String
  },
  isOpened: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['qr-loaded']);

const qrCodeOverrideLogoUrl = ref(null);
const qrCodeOverrideBaseColor = ref(null);
const qrCodeImgBase64 = ref(null);
const qrCodeJsonLoaded = ref(false);
const qrHovered = ref(false);
const qrCopied = ref(false);

const qrDownloadBtn = reactive({
  show: true,
  disabled: false,
  text: 'Download',
  iconLeft: 'fa-regular fa-file-arrow-down',
  action: () => createQRCode(true, false, 'png')
});

const copyQrBtn = reactive({
  show: true,
  disabled: false,
  text: computed(() => qrCopied.value ? 'Copied!' : 'Copy QR'),
  iconLeft: 'fa-regular fa-copy',
  action: () => createQRCode(false, true, 'png'),
  styleType: 'primary'
})

const qrFileName = computed(() => `qrstore-${props.shareLink.replace(/\W/g, '')}.png`);

const createQRCode = (isdownload = false, iscopy = false, outputtype = 'png', shouldSave = true) => {
  let qrUrl = null;

  if (props.shareLink !== null) {
    qrUrl = props.shareLink;
  }

  if (qrUrl === null) return;
  if (qrUrl.includes('...')) return;

  let qrLogo = new URL('/static/imgs/harbour-logo-new.svg', window.location.origin).href;

  if (qrCodeOverrideLogoUrl.value && qrCodeOverrideLogoUrl.value.includes('base64')) {
    qrLogo = qrCodeOverrideLogoUrl.value;
  }

  let color = '#1355FF';
  if (qrCodeOverrideBaseColor.value) {
    color = qrCodeOverrideBaseColor.value;
  }

  const qrConfig = {
    width: 900,
    height: 900,
    type: outputtype,
    data: qrUrl,
    margin: 30,
    image: qrLogo,
    dotsOptions: {
      color: color,
      type: 'square',
    },
    backgroundOptions: {
      color: 'white',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 15,
    },
  };

  const qrCode = new QRCodeStyling(qrConfig);

  qrCode.getRawData(outputtype).then(async (qrBlob) => {
    const qrBlobBase64 = await readFileAsDataUrl(qrBlob);
    qrCodeImgBase64.value = qrBlobBase64;

    if (iscopy) {
      await copyPngImageBlobToClipboard(qrBlob, 'Success - QR code copied to clipboard');
    }
    if (isdownload) {
      if (outputtype === 'png') {
        qrCode.download({ name: 'QR', extension: 'png' });
      }
      if (outputtype === 'svg') {
        qrCode.download({ name: 'QR', extension: 'svg' });
      }
    }
  });

  if (shouldSave) filesystemSaveQRJson(qrFileName.value, qrConfig);
};

const copyPngImageBlobToClipboard = async (pngImageBlob, successMsg) => {
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'image/png': pngImageBlob,
      }),
    ]);

    qrCopied.value = true;

    Toast.open({
      message: successMsg,
      type: 'is-success',
      position: 'is-top',
      duration: 3500,
    });
  } catch (e) {
    console.error(e);
  }
};

const setQRCodeSetOverrideLogoUpload = () => {
  const fileInputElement = document.createElement('input');
  fileInputElement.style.display = 'none';
  fileInputElement.type = 'file';

  fileInputElement.addEventListener(
    'change',
    async (event) => {
      const [file] = event.target.files;
      if (!file) return;

      const tgtFile = file;
      const tgtFileName = tgtFile.name;
      const tgtFileNameForComparison = tgtFileName.toLowerCase().replace('.jpeg', '.jpg');

      if (
        !tgtFileNameForComparison.includes('.jpg') &&
        !tgtFileNameForComparison.includes('.png')
      ) {
        Toast.open({
          message:
            'The provided image is not a required type (JPG or PNG). Please re-export and try again',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        return;
      }

      const tgtFileBytes = tgtFile.size;
      if (tgtFileBytes < 5) {
        Toast.open({
          message: 'The provided image is too small. Please try again with a larger version',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        return;
      }

      qrCodeOverrideLogoUrl.value = await readFileAsDataUrl(tgtFile);
      createQRCode(false, false, 'png');
    },
    false,
  );

  document.body.appendChild(fileInputElement);
  fileInputElement.click();
};

const filesystemGetQRCodeJson = (filename) => {
  return Vue.prototype.$harbourData.post('/data?filesystem-getorganizationalsimplejsonstore', {
    requesttype: 'filesystem-getorganizationalsimplejsonstore',
    filename
  })
    .catch(() => {
      console.log('Error: while working on this, an error occurred in getting qr code data. Please refresh and try again later or contact support@harbourshare.com');
    });
};

const filesystemSaveQRJson = (qrStoreName, qrJSON) => {
  const dataJson = {
    requesttype: 'filesystem-saveorganizationalsimplejsonstore',
    filename: qrStoreName,
    json_value: qrJSON
  }
  Vue.prototype.$harbourData.post('/data?filesystem-saveorganizationalsimplejsonstore', dataJson)
    .catch(() => {
      console.log('Error: while working on this, an error occurred in saving qr code data. Please refresh and try again later or contact support@harbourshare.com');
    });
};

const createQRCodeDebounced = debounce((...params) => {
  createQRCode(...params);
}, 150);

onMounted(() => {
  filesystemGetQRCodeJson(qrFileName.value)
    .then((response) => {
      // eslint-disable-next-line camelcase
      const { json_result: json } = response.data;
      qrCodeOverrideLogoUrl.value = json?.image || null;
      qrCodeOverrideBaseColor.value = json?.dotsOptions.color || null;
    })
    .finally(() => {
      qrCodeJsonLoaded.value = true;
      emit('qr-loaded');
      createQRCode(false, false, 'png', false);
    });
});
</script>

<template>
  <div
    :class="['qr-code-panel', { opened: isOpened }]"
  >
    <template v-if="isOpened">
      <div
        class="qr-code-panel-wrapper"
        v-if="qrCodeJsonLoaded"
      >
        <div class="qr-code-holder">
          <div
            class="qr-code-image"
            @mouseover="qrHovered = true"
            @mouseout="qrHovered = false"
          >
            <img
              class="qr-code-img"
              :src="qrCodeImgBase64"
              title="QR code for agreement URL"
            />
            <transition
              name="fade"
              mode="out-in"
              :duration="250"
            >
              <div
                v-show="qrHovered"
                class="logo-upload"
                @click="setQRCodeSetOverrideLogoUpload"
              >
                <i class="fa-regular fa-file-arrow-up"></i>
                Upload
              </div>
            </transition>
          </div>
        </div>

        <div class="qr-code-web-share">
          <HrbrButton
            class-name="qr-section-btn"
            :button="copyQrBtn"
          />

          <HrbrButton
            class-name="qr-section-btn"
            type="dropdown"
            :button="qrDownloadBtn"
          />

          <input
            class="qr-code-web-share-btn--color-selector"
            type="color"
            :value="qrCodeOverrideBaseColor || '#1355FF'"
            @input="
              qrCodeOverrideBaseColor = $event.target.value;
              createQRCodeDebounced(false, false, 'png');
            " />
        </div>
      </div>

      <div class="qr-code-panel-loader" v-else>
        <i class="fa-light fa-spinner-third fa-spin"></i>
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.qr-code-panel {
  &.opened {
    margin-top: 20px;
  }
}
.qr-code-image {
  position: relative;
  width: 296px;
  height: 296px;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
}
.qr-code-holder {
  display: flex;
  justify-content: center;
}
.qr-code-web-share {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 15px;
  >>> .btn-icon-left {
    margin-right: 6px;
  }
}

.logo-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
  border-radius: 2px;
  border: 1px solid #DBDBDB;
  background: rgba(255, 255, 255, 0.50);
  transform: translate(-50%, -50%);
  cursor: pointer;
  i {
    margin-bottom: 2px;
    font-size: 18px;
  }
}
.qr-section-btn {
  >>> .section-btn {
    min-width: 50px;
    font-size: 14px !important;
  }
}
.qr-code-web-share-btn--color-selector {
  height: 40px;
  padding: 6px 8px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  border-radius: 8px;
  &::-webkit-color-swatch {
    border: none;
    border-radius: 2px;
  }
}

.qr-code-panel-loader {
  font-size: 40px;
  color: var(--border-focus);
  text-align: center;
  i {
    animation-duration: 1.2s;
  }
}

</style>
