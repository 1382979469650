import Vue from 'vue';
import { errorHandler } from '@/utils/helpers/error-handler.js';
export const endpointUrl = '/api/verifications';

export default {
  async listVerifications(payload = {}, params = {}) {
    // TODO: Add pagination
    const { data } = await Vue.prototype.$harbourData
      .get(endpointUrl, payload, params)
      .catch((err) => {
        errorHandler('listVerifications', err);
      });
    return data?.items || [];
  },

  async createVerification(payload = {}, params = {}) {
    try {
      const { data } = await Vue.prototype.$harbourData.post(endpointUrl, payload, params);
      return data;
    } catch (err) {
      errorHandler('createVerification', err);
      throw new Error(err);
    }
  },

  async getVerification(verificationId, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .get(`${endpointUrl}/${verificationId}`, payload, params)
      .catch((err) => {
        errorHandler('getVerification', err);
      });
    return data;
  },

  async updateVerification(verificationId, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .put(`${endpointUrl}/${verificationId}`, payload, params)
      .catch((err) => {
        errorHandler('updateVerification', err);
      });
    return data;
  },

  async deleteVerification(verificationId, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .delete(`${endpointUrl}/${verificationId}`, payload, params)
      .catch((err) => {
        errorHandler('deleteVerification', err);
      });
    return data;
  },
};
