import Vue from 'vue';

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

// store contacts in recent contacts history (call on email send)
export const emailInputStoreRecentContacts = (emailsArray, emailsToNames) => {
  if (!(emailsArray && emailsToNames)) {
    console.log('Not storing recent contacts');
    return;
  }

  const contacts = [];
  const seen = new Set();
  emailsArray
    .filter((email) => email && email.includes('@'))
    .forEach((email) => {
      if (!seen.has(email)) {
        // no duplicate contacts
        const name = emailsToNames[email] || null;
        contacts.push({ email, name });
        seen.add(email);
      }
    });

  if (!contacts.length) {
    return;
  }

  Vue.prototype.$harbourData
    .post('/account-updaterecentcontacts', {
      requesttype: 'account-updaterecentcontacts',
      contacts,
    })
    .then(() => {
      console.log('Updated recent contacts');
    })
    .catch(() => {
      console.log("Couldn't update recent contacts");
    });
};

export const makeEmailInputDataStore = () => ({
  emailsToNames: {},
  namesToEmails: {},
  emailsToProfileImageUrls: {},
  recentEmails: [],
});

export const getInitials = (name, email) => {
  if (name) return name[0].toUpperCase();
  else if (email) return email[0].toUpperCase();
  else return '';
};

export const getEmailRequest = (linkId, emails, customMessage, notificationType, emailTitle) => {
  const recipients = emails.toEmails.map((email) => ({ email, type: 'to' }));

  if (emails.ccEmails?.length)
    recipients.concat(emails.ccEmails.map((email) => ({ email, type: 'cc' })));
  if (emails.bccEmails?.length)
    recipients.concat(emails.bccEmails.map((email) => ({ email, type: 'bcc' })));

  const content = {
    subject: emailTitle,
    sections: {
      custom_message: customMessage,
    },
  };

  return {
    data: {
      object: {
        id: linkId,
      },
      recipients: recipients,
    },
    ...(customMessage && emailTitle && { content }),
    notification_type: notificationType,
  };
};
