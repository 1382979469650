import Vue from 'vue';

export default {
  /**
   * This method is not used.
   * @deprecated
   */
  getHtmlWithListNumbers(htmlString) {
    const DOCX_LIST_MARKER = 'HRBR_LIST_ITEM_NUMBER';

    const dom = new DOMParser().parseFromString(htmlString, 'text/html');
    // get all orderer lists except nested ones
    const lists = dom.documentElement.querySelectorAll('li');

    lists.forEach((list) => {
      const listContent = list.innerText.trim();
      console.log('list inner html', listContent, listContent.startsWith(`%%${DOCX_LIST_MARKER}=`));
      if (listContent.startsWith(`%%${DOCX_LIST_MARKER}=`)) {
        const tokens = listContent.split('%%');
        const marker = tokens.find((t) => t.includes(DOCX_LIST_MARKER));
        const markerContent = marker?.split('=')[1];
        if (markerContent) {
          list.setAttribute('data-list-decoration', markerContent);
        }
      }
    });

    return dom.documentElement.innerHTML;
  },

  /**
   * This method is not used.
   * @deprecated
   *
   * Change the text of ckeditor annotations by replacing them with new ones.
   * @param {Object} editor - ckeditor instance
   * @param {Object} input - input object containing ID and color
   * @param {string} newText - text the new annotation should contain
   */
  updateLinkbuilderCkeditorAnnotationsText(editor, input, newText) {
    const newAnnotationObj = this.createCkeditorAnnotation({
      displayLabel: newText,
      itemId: input.itemid,
      itemFieldType: input.itemfieldtype,
      color: '#980043',
      fontFamily: input.fontfamily,
    });
    this.replaceCkeditorAnnotations(editor, input.itemid, newAnnotationObj);
  },

  /**
   * This method is not used.
   * @deprecated
   *
   * Used when exporting to word.
   * CKEditor DOCX converter does not support decimal list styles,
   * so we need to add the numbers manually.
   */
  createCkeditorHtmlDecimalLists(html) {
    if (!html) return '';

    // create div and insert html
    const div = document.createElement('div');
    div.innerHTML = html;

    const orderedLists = Array.from(div.children).filter(
      (c) => c.nodeName === 'OL' && c.getAttribute('style') === 'list-style-type:decimal;',
    );

    // add number to <ol> items recursively
    const addNumberToOlItems = function (el, prefix = null) {
      let orderedLists = el.querySelectorAll('ol');
      if (!orderedLists) return;
      orderedLists.forEach((ol) => {
        let li = ol.querySelectorAll('li');
        li.forEach((item, index) => {
          const newPrefix = (prefix || '') + `<span class="number">${index + 1}.</span>`;
          item.innerHTML = newPrefix + ' ' + item.innerHTML;
          addNumberToOlItems(item, newPrefix);
        });
      });
    };

    orderedLists.forEach((ol) => {
      const children = Array.from(ol.children);
      children.forEach((child, i) => {
        child.innerHTML = `${i + 1}. ` + child.innerHTML;
        addNumberToOlItems(child, `${i + 1}.`);
      });
    });

    const { innerHTML } = div;
    div.remove();
    return innerHTML;
  },

  /**
   * This method is not used.
   * @deprecated
   */
  async exportToDocxDeprecated(title, editorHTML, comments = null, suggestions = null) {
    let documentTitle = title || 'document.docx';
    // make sure the file name ends with .docx and doesn't end with .pdf
    if (!documentTitle.endsWith('.docx')) {
      documentTitle = documentTitle.replace(/\.pdf$/, '');
      documentTitle += '.docx';
    }

    let formattedSugestions = null;
    if (suggestions) {
      formattedSugestions = {};
      suggestions.forEach((suggestion) => {
        formattedSugestions[suggestion.id] = {
          author: suggestion.author.name,
          created: suggestion.createdAt,
        };
      });
    }

    let css = '';
    try {
      const resp = await fetch('/src/assets/css/ckeditor/docx-converter.css');
      css = await resp.text();
    } catch (error) {
      console.debug('Failed to load CSS data from /src/assets/css/ckeditor/docx-converter.css');
      return;
    }

    // Generate the current HTML
    // Adding page sizing for export
    // ${css}
    const documentCSS = `
      .ck.ck-content.ck-editor__editable {
        /* US Letter size. */
        width: 215.9mm;
        /* Padding is your document's margin. */
        padding: 19mm;
        /* You don't want to change the size of the editor by applying the new padding values. */
        box-sizing: border-box;
        /* ... */
      }
      ol {
        margin: 0cm;
        padding: 0cm;
        list-style-type: decimal;
        list-style-position: outside;
      }
      li {
        margin: 0cm;
        padding: 0cm;
      }
      @page {
        format: letter portrait;
        font-family: sans-serif;
        margin-left: 2cm;
        margin-right: 2cm;
        margin-top: 2cm;
        margin-bottom: 2cm;
        padding: 0cm;
        font-family: sans-serif;
      }
      ${css}
    `;

    // Process HTML to remove extra empty lines that CK creates
    let html = editorHTML;
    html = html.replace(/<p>&nbsp;<\/p>/g, '');
    html = html.replace(
      /<p style="border-left-style:hidden;border-left-width:1px;border-right-style:hidden;border-right-width:1px;border-top-style:hidden;border-top-width:1px;">&nbsp;<\/p>/g,
      '',
    );
    html = html.replace(/<h4\s/g, '<p ');
    html = html.replace(/<\/h4>/g, '</p>');
    html = html.replace(/<h3\s/g, '<p ');
    html = html.replace(/<\/h3>/g, '</p>');
    html = html.replace(/<li>[^<p]*<p/g, '<li><span');
    html = html.replace(/margin:0cm;padding:0cm;list-style-type:decimal;/g, '');
    html = html.replace(/<p style="margin-left:48px;text-align:justify;">&nbsp;<\/p>/g, '');
    html = html.replace(/<p style="margin-left:48px;">&nbsp;<\/p>/g, '');

    // If exporting without comments or suggestions, strip comments and suggestions from html
    // or else ckeditor exporter will reject.
    if (!comments || !suggestions) {
      const tags = ['comment-start', 'comment-end', 'suggestion-start', 'suggestion-end'];
      tags.forEach((tag) => {
        // remove tags
        html = html.replace(new RegExp(`<${tag}[^>]*>.*?<\/${tag}>`, 'g'), '');
      });
    }

    const result = await Vue.prototype.$harbourData.get('cke-token');
    if (!result.data?.state || result.data?.state !== 'SUCCESS') {
      console.log('An error occured while retrieving CK Editor token');
      return null;
    }

    // converter options
    // https://docx-converter.cke-cs.com/docs#tag/API/paths/~1v1~1convert/post
    const options = {
      suggestions: {},
      comments: {},
      format: 'Letter',
      margin_top: '0cm',
      margin_bottom: '0cm',
      margin_left: '2cm',
      margin_right: '2cm',
      auto_pagination: false,
      orientation: 'portrait',
    };
    if (suggestions) {
      options.suggestions = formattedSugestions;
    }
    if (comments) {
      options.comments = comments;
    }

    const { token } = result.data;
    const convert = await fetch('https://docx-converter.cke-cs.com/v1/convert', {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        html,
        css: documentCSS,
        options,
      }),
    });
    if (!convert.ok) {
      console.log('An error occured while converting CK Editor HTML to DOCX');
      return null;
    }

    const blob = await convert.blob();
    const newBlob = new Blob([blob]);
    return newBlob;
  },
};
