export const PARAGON_PROJECT_ID_MAIN = '63289750-b46f-457e-bbcd-acb2b89c8736';
export const PARAGON_PROJECT_ID_STAGING = '6e2fae72-afdd-47e3-9f2f-784f99965b11';
export const PARAGON_PROJECT_ID_PRODUCTION = '89520fa0-9f10-4297-861e-6498b9afe6ef';

const PARAGON_CDN_VERSION = 'latest';
export const PARAGON_CDN_PUBLIC_URL = `https://cdn.useparagon.com/${PARAGON_CDN_VERSION}/dashboard/public/integrations`;
export const HUBSPOT_FORM_URL = 'https://share.hsforms.com/1P1FbL84QTe2znCzN6lwyBg4q7x8';
export const HUBSPOT_KBPAGE_URL = 'https://knowledgebase.harbourshare.com/knowledge/automations';
export const TRIGGER_TYPES = {
  VIEW_AGREEMENT: 'viewed',
  COMPLETE_AGREEMENT: 'completed',
  SIGN_AGREEMENT: 'signed',
};

export const TRIGGER_TYPE_KEYS = {
  viewed: 'VIEW_AGREEMENT',
  completed: 'COMPLETE_AGREEMENT',
  signed: 'SIGN_AGREEMENT',
};

export const PAYLOAD_OPTIONS = {
  viewed: {
    datetime_last_viewed: '2023-01-30T00:00:00',
    device_browser_name: 'Chrome',
    device_ip_address: '255.255.255.255',
    device_os_name: 'Mac OS X',
    device_type: 'Mac',
    location_city: 'san francisco',
    location_coordinates: '37.798014, -122.427886',
    location_country: 'US',
    location_region: 'ca',
  },
  signed: {
    agreement_reference_id: 'HBRREF-AbCd',
    datetime_signed: '2023-01-30T00:00:00',
    download_expires_at: '2023-01-30T00:00:00',
    download_url: 'https://url.to/download.pdf',
    location_city: 'san francisco',
    location_coordinates: '37.798014, -122.427886',
    location_country: 'US',
    location_region: 'ca',
    signer_email: 'signer@email.com',
    signer_inputs: [
      {
        id: 'input-id',
        value: 'input value',
      },
    ],
  },
  completed: {
    agreement_reference_id: 'HBRREF-AbCd',
    datetime_signed: '2023-01-30T00:00:00',
    download_expires_at: '2023-01-30T00:00:00',
    download_url: 'https://url.to/download.pdf',
    location_city: 'san francisco',
    location_coordinates: '37.798014, -122.427886',
    location_country: 'US',
    location_region: 'ca',
    signer_email: 'signer@email.com',
    signer_inputs: [
      {
        id: 'input-id',
        value: 'input value',
      },
    ],
  },
};

export const TRIGGER_OPTIONS = [
  {
    id: 'viewed',
    name: 'Viewed agreement',
    disabled: ['googledrive'],
  },
  {
    id: 'signed',
    name: 'Signed agreement',
    disabled: ['googledrive'],
  },
  {
    id: 'completed',
    name: 'Completed agreement',
    disabled: [],
  },
];

export const INTEGRATION_CUSTOM_PARAMETERS = {
  googledrive: {
    name: 'googledrive',
    navigationProvider: 'googledrive',
    navigationUrls: {
      root: "files?orderBy=name&fields=files(id,name,mimeType,kind,capabilities(canEdit),owners,modifiedTime,parents)&q='root' in parents and trashed=false and mimeType='application/vnd.google-apps.folder'",
      shared:
        "files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&q=sharedWithMe=true and trashed=false and mimeType='application/vnd.google-apps.folder'",
      starred:
        "files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&q=starred=true and trashed=false and mimeType='application/vnd.google-apps.folder'",
      drives: 'drives',
      search:
        "files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&includeItemsFromAllDrives=true&supportsAllDrives=true&q=trashed=false and mimeType='application/vnd.google-apps.folder' and ",
      getItem: (id) => `files/${id}?fields=id,name,mimeType,kind,parents&supportsAllDrives=true`,
      openItem: (id) =>
        `files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&q='${id}' in parents and trashed=false and mimeType='application/vnd.google-apps.folder'`,
      openDrive: (id) =>
        `files?corpora=drive&driveId=${id}&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&includeItemsFromAllDrives=true&supportsAllDrives=true&q=trashed=false and mimeType='application/vnd.google-apps.folder'`,
    },
    getProps: (initialProps, harbourParameters) => {
      const props = {
        initialData: {},
      };
      if (Object.keys(harbourParameters).length > 0) {
        props.activeTrigger = TRIGGER_TYPES[harbourParameters.trigger_type];
        props.initialData.initialFolderID = harbourParameters.custom_parameters.folder_id;
        props.initialData.initialFolderName = harbourParameters.custom_parameters.folder_name;
      }

      return {
        ...initialProps,
        ...props,
      };
    },
    getSavedParameters: ({ folderId, folderName }, parameters, selectedTrigger) => {
      const localParams = {
        ...parameters,
      };
      if (!localParams.custom_parameters) {
        localParams.custom_parameters = {};
      }

      localParams.custom_parameters.folder_id = folderId;
      localParams.custom_parameters.folder_name = folderName;
      localParams.trigger_type = TRIGGER_TYPE_KEYS[selectedTrigger];

      return localParams;
    },
    selectedResponseKey: 'folder_id',
    controls: [
      {
        navigationComponent: true,
        label: (selected) => (selected ? `Selected folder: ${selected}` : 'Select folder'),
        selectedItemComponentKey: 'selectedFolderID',
        initialItemPropKey: 'initialFolderID',
        initialItemPropName: 'initialFolderName',
        selectedItemKey: 'folderId',
        selectedItemName: 'folderName',
        dataComponentKey: 'googleDriveFolders',
        selectedResponseKey: 'folder_id',
        payload: {
          action: 'googledrive',
          parameters: [],
          sourceKey: 'folders',
        },
      },
    ],
  },
  googlesheets: {
    name: 'googlesheets',
    isLimited: true,
    navigationProvider: 'googledrive',
    navigationUrls: {
      root: "files?orderBy=name&fields=files(id,name,mimeType,kind,capabilities(canEdit),owners,modifiedTime,parents)&q='root' in parents and trashed=false and mimeType='application/vnd.google-apps.spreadsheet'",
      shared:
        "files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&q=sharedWithMe=true and trashed=false and mimeType='application/vnd.google-apps.spreadsheet'",
      starred:
        "files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&q=starred=true and trashed=false and mimeType='application/vnd.google-apps.spreadsheet'",
      drives: 'drives',
      search:
        "files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&includeItemsFromAllDrives=true&supportsAllDrives=true&&q=trashed=false and mimeType='application/vnd.google-apps.spreadsheet' and ",
      getItem: (id) => `files/${id}?fields=id,name,mimeType,kind,parents&supportsAllDrives=true`,
      openItem: (id) =>
        `files?orderBy=name&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&q='${id}' in parents and trashed=false and mimeType='application/vnd.google-apps.spreadsheet'`,
      openDrive: (id) =>
        `files?corpora=drive&driveId=${id}&fields=files(id,name,mimeType,kind,owners,modifiedTime,capabilities(canEdit),parents)&includeItemsFromAllDrives=true&supportsAllDrives=true&q=trashed=false and mimeType='application/vnd.google-apps.spreadsheet'`,
    },
    getProps: (initialProps, harbourParameters) => {
      const props = {
        initialData: {},
      };
      if (Object.keys(harbourParameters).length > 0) {
        props.activeTrigger = TRIGGER_TYPES[harbourParameters.trigger_type];
        props.initialData.initialSpreadSheetID =
          harbourParameters?.custom_parameters?.google_spreadsheet_id;
        props.initialData.initialSpreadSheetName =
          harbourParameters?.custom_parameters?.google_spreadsheet_name;
        props.initialData.initialWorkSheetID =
          harbourParameters?.custom_parameters?.google_worksheet_id;
        props.initialData.initialWorkSheetName =
          harbourParameters?.custom_parameters?.google_worksheet_name;
      }
      return {
        ...initialProps,
        ...props,
      };
    },
    getSavedParameters: ({ spreadsheetId, spreadsheetName, worksheetId, worksheetName }, parameters, selectedTrigger) => {
      const localParams = {
        ...parameters,
      };
      if (!localParams.custom_parameters) {
        localParams.custom_parameters = {};
      }
      localParams.trigger_type = TRIGGER_TYPE_KEYS[selectedTrigger];
      localParams.custom_parameters.google_spreadsheet_id = spreadsheetId;
      localParams.custom_parameters.google_spreadsheet_name = spreadsheetName;
      localParams.custom_parameters.google_worksheet_id = worksheetId;
      localParams.custom_parameters.google_worksheet_name = worksheetName;

      return localParams;
    },
    controls: [
      {
        navigationComponent: true,
        label: (selected) =>
          selected ? `Selected spreadsheet: ${selected}` : 'Select spreadsheet',
        selectedItemComponentKey: 'selectedSpreadSheetID',
        initialItemPropKey: 'initialSpreadSheetID',
        initialItemPropName: 'initialSpreadSheetName',
        selectedItemKey: 'spreadsheetId',
        selectedItemName: 'spreadsheetName',
        dataComponentKey: 'googleSpreadSheets',
        selectedResponseKey: 'google_spreadsheet_id',
        updateNextOnSelection: true,
        payload: {
          action: 'googlesheets',
          parameters: [],
          sourceKey: 'spreadSheets',
        },
      },
      {
        label: 'Select worksheet',
        selectedItemComponentKey: 'selectedWorkSheetID',
        initialItemPropKey: 'initialWorkSheetID',
        initialItemPropName: 'initialWorkSheetName',
        selectedItemKey: 'worksheetId',
        selectedItemName: 'worksheetName',
        dataComponentKey: 'googleWorkSheets',
        selectedResponseKey: 'google_worksheet_id',
        payload: (spreadSheetID) => ({
          action: 'googlesheets',
          externalApi: true,
          parameters: [
            {
              key: 'spreadSheets',
              source: {
                type: 'VALUE',
                value: spreadSheetID,
              },
            },
          ],
          sourceKey: 'worksheetId',
        }),
      },
    ],
    selectedResponseKey: 'google_spreadsheet_id',
  },
  slack: {
    name: 'slack',
    getProps: (initialProps, harbourParameters) => {
      const props = {
        initialData: {},
      };
      if (Object.keys(harbourParameters).length > 0) {
        props.activeTrigger = TRIGGER_TYPES[harbourParameters.trigger_type];
        props.initialData.initialChannelID = harbourParameters?.custom_parameters?.channel_id;
      }

      return {
        ...initialProps,
        ...props,
      };
    },
    getSavedParameters: ({ channelId }, parameters, selectedTrigger) => {
      const localParams = {
        ...parameters,
      };
      if (!localParams.custom_parameters) {
        localParams.custom_parameters = {};
      }
      localParams.custom_parameters.channel_id = channelId;
      localParams.trigger_type = TRIGGER_TYPE_KEYS[selectedTrigger];

      return localParams;
    },
    controls: [
      {
        label: 'Select channel',
        selectedItemComponentKey: 'selectedChannelID',
        initialItemPropKey: 'initialChannelID',
        selectedItemKey: 'channelId',
        dataComponentKey: 'slackChannels',
        dataResponseKey: 'records',
        selectedResponseKey: 'channel_id',
        payload: {
          action: 'slack',
          intent: 'SLACK_LIST_CHANNELS',
          parameters: {
            paginationParameters: {
              pageCursor: 0,
              selectedItem: 'general',
              search: '',
            },
          },
        },
      },
    ],
  },
};

export const EXTERNAL_URLS = {
  googledrive: 'https://drive.google.com/drive/u/0/folders/',
  googlesheets: 'https://docs.google.com/spreadsheets/d/',
};

const DEFAULT_ORGANIZATIONS = [
  'ORG13123134123123212',
  'ORG-livetest-c9619cec30ed4e26808b51bfb8bdf62c',
  'ORG-demo-b909f9fe859f4765975f6a06255b4f37',
  'ORG-runninglegends-staging-3b0c8087ed0e4e52b764ab4af625ef21',
];
export const ALLOWED_INTEGRATIONS = {
  salesforce:
    DEFAULT_ORGANIZATIONS +
    [
      'ORG-kudo-49a76484caf7430f97472673ce9f1202',
      'ORG-mandtsystem-1697667648930_8897bc9e90fc42ffa7f48350e43772f8',
    ],
  'custom.catalis': DEFAULT_ORGANIZATIONS + ['ORG-kissimmee-f2e96f4d196644148d1818ece38a9748'],
};

export const GOOGLE_FILE_PICKER_GOOGLEDRIVE_API_KEY = "AIzaSyCyfJfQOMmIHDIex-sp05wrb5nhRZ9sItg";
export const GOOGLE_FILE_PICKER_GOOGLESHEETS_API_KEY = "AIzaSyBKfHLBg3T8IrjEY1ivFmOvTaQ6KZ0cUHg";
