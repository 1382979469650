import { defineStore } from 'pinia';
import { useHarbourStore } from './harbour-store';
import { useDashboardStore } from './dashboard-store';

export const useAnalyticsStore = defineStore('analytics', {
  state: () => ({
    harbourStore: useHarbourStore(),
    dashboardStore: useDashboardStore(),

    isAnalyticsReady: false,
    linksGeoData: [],
    dashboardGeoPointsData: [],

    // Completed agreements chart in dashboard
    completedAgreementsMap: {},
    totalCompleted: 0,
    completedLinks: [],

    // 'Total signers' chart in dashboard
    totalSignersMap: {},
    totalSignersForPeriod: 0,
    seenSigners: {},

    chartOnHover: {},
    chartDisplayUnit: '',
  }),

  getters: {
    getDashboardPointsData: (state) => state.dashboardGeoPointsData,
    hasAnalytics: (state) => {
      return (
        state.totalCompleted > 0 && state.totalSignersForPeriod > 0 && state.linksGeoData.length > 0
      );
    },
  },

  actions: {
    async refreshAnalytics(dateFilter = []) {
      this.zeroAllCounts();
      const gridApi = this.dashboardStore.gridApi;
      if (!gridApi) return;

      const updatedData = [];
      this.completedLinks = [];
      
      gridApi?.forEachNodeAfterFilterAndSort((rowNode) => {

        if (this.dashboardStore.isLinkCompleted(rowNode.data)) {
            this.completedLinks.push(rowNode.data);
            this.totalCompleted += 1;
        }

        const submissions = rowNode.data.submissions;
        if (!submissions || submissions.length === 0) return;

        submissions.forEach((submission) => {
          this.processSubmission(submission, [], this.chartDisplayUnit);

          if (!submission.latlong) return;

          const signers = submission.multiparty_results?.map((party) => {
              return `${party.agreementlinkopened_fullname} (${party.agreementlinkopened_email})`;
            }) || [];
          updatedData.push({
            latlong: submission.latlong,
            signer_email: submission.signer_email,
            upload_datetime: submission.upload_datetime,
            signers: signers,
            title: submission.agreement_title,
            asset_id: submission.id,
            link_id: submission.link_id,
            folder: submission.folder,
          });
        });
      });

      this.linksGeoData = updatedData;

      this.loadDashboardPointsData(dateFilter);
      this.getAnalyticsNumbers(dateFilter);
      this.isAnalyticsReady = true;
    },

    zeroAllCounts() {
      this.totalCompleted = 0;
      this.totalSignersForPeriod = 0;
      this.seenSigners = {};
      this.completedAgreementsMap = {};
      this.totalSignersMap = {};
    },

    // Generate data for the 'Completed agreements' chart
    // Places data into completedAgreementsMap
    generateCompletionDataFromSubmission(submission, displayType) {
      const year = submission.year;
      const month = submission.month;
      const date = submission.date;
      const map = this.completedAgreementsMap;

      const subItem = {
        id: submission.id,
        link_id: submission.link_id,
      };

      if (displayType === 'date') {
        if (!(date in map)) map[date] = [];
        map[date].push(subItem);
      } else {
        if (!(year in map)) map[year] = {};
        if (!(month in map[year])) map[year][month] = [];
        map[year][month].push(subItem);
      }
    },

    // Generate data for the 'Total distinct signers' chart
    // Places data into totalSignersMap
    generateSignersDataFromSubmission(submission, displayType) {
      // Get all possible signer emails
      const multiPartySigners =
        submission.multiparty_results?.map((signer) =>
          signer.agreementlinkopened_email?.toLowerCase(),
        ) || [];
      const singlePartySigner = submission.signer_email?.toLowerCase();
      const emails = [...multiPartySigners];
      if (!emails.includes(singlePartySigner)) emails.push(singlePartySigner);

      const year = submission.year;
      const month = submission.month;
      const date = submission.date;
      const map = this.totalSignersMap;
      let currentValues;

      if (displayType === 'date') {
        if (!(date in map)) map[date] = [];
        currentValues = map[date];

        emails.forEach((email) => {
          if (this.seenSigners[date]?.has(email)) {
            return;
          }
          this.trackSeenSignersForDate(date, email);
          currentValues.push({
            email,
            submission,
          });
          this.totalSignersForPeriod += 1;
        });
      } else {
        if (!(year in map)) map[year] = {};
        if (!(month in map[year])) map[year][month] = [];
        currentValues = map[year][month];

        emails.forEach((email) => {
          if (this.seenSigners[year]?.[month]?.has(email)) {
            return;
          }
          this.trackSeenSignersForMonth(year, month, email);
          currentValues.push({
            email,
            submission,
          });
          this.totalSignersForPeriod += 1;
        });
      }
    },

    trackSeenSignersForDate(date, email) {
      if (!(date in this.seenSigners)) this.seenSigners[date] = new Set();
      this.seenSigners[date].add(email);
    },

    trackSeenSignersForMonth(year, month, email) {
      if (!(year in this.seenSigners)) this.seenSigners[year] = {};
      if (!(month in this.seenSigners[year])) this.seenSigners[year][month] = new Set();
      this.seenSigners[year][month].add(email);
    },

    monthDiff(dateFrom, dateTo) {
      return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    },

    getAnalyticsNumbers(dateFilter = []) {
      const completedLinks = this.completedLinks;

      let months;
      // Date range filter was set
      if (dateFilter[0] && dateFilter[1]) {
        months = this.monthDiff(dateFilter[0], dateFilter[1]);
      } else {
        const firstLink = completedLinks.find((link) => link.submissions?.length > 0);
        const lastLink = completedLinks.findLast((link) => link.submissions?.length > 0);

        months = this.monthDiff(
          new Date(parseInt(lastLink?.submissions[0].agreement_completed_epochms)),
          new Date(parseInt(firstLink?.submissions[0].agreement_completed_epochms)),
        );
      }
      this.chartDisplayUnit = months > 6 ? 'month' : 'date';
    },

    // Process a single submission and place data into
    // completedAgreementsList and uniqueSignersList
    // Which are then used to generate the charts
    processSubmission(submission, dateFilterVal = [], displayType) {
      const signedDate = new Date(parseInt(submission.agreementcompleted_epochms));
      const year = signedDate.getUTCFullYear();
      const month = signedDate.getUTCMonth();
      const formattedDate = signedDate.toLocaleString('en-us', {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      });

      // Append the month and year to the submission for easier mapping
      submission.month = month;
      submission.year = year;
      submission.date = formattedDate;

      // Process completed agreement counts per month
      this.generateCompletionDataFromSubmission(submission, displayType);

      // Process number of signers per month
      this.generateSignersDataFromSubmission(submission, displayType);
    },

    // Globe Helper function to group points by proximity
    groupDashboardGeoDataByProximity(proximityRadius, dateFilterVal) {
      const buckets = [];

      // Helper function to check if a point belongs to a bucket
      const findBucket = (pointLat, pointLng) => {
        return buckets.find((bucket) => {
          const { lat, lng } = bucket;
          const distance = Math.sqrt(Math.pow(pointLat - lat, 2) + Math.pow(pointLng - lng, 2));
          return distance <= proximityRadius;
        });
      };

      this.linksGeoData.forEach((item) => {
        const lat = parseFloat(+item.latlong.split(',')[0]);
        const lng = parseFloat(+item.latlong.split(',')[1]);
        const existingBucket = findBucket(lat, lng);
        const timestamp = this.harbourStore.convertToUnixMillis(item.upload_datetime);

        if (dateFilterVal[0] && dateFilterVal[1]) {
          const filterTimeFrom = dateFilterVal[0].getTime();
          const filterTimeTo = dateFilterVal[1].getTime();

          if (!(timestamp > filterTimeFrom && timestamp < filterTimeTo)) return;
        }

        if (existingBucket) {
          // Add the point to the existing bucket
          existingBucket.points.push(item);
          existingBucket.pointCount += 0.5;
        } else {
          // Create a new bucket for this point
          buckets.push({
            lat: lat,
            lng: lng,
            points: [item],
            pointCount: 1,
            color: '#03b09d',
            item,
          });
        }
      });

      return buckets;
    },

    // Get all points of geo data, grouped by proximity
    loadDashboardPointsData(dateFilter = [], proximityRadius = 3) {
      this.dashboardGeoPointsData = this.groupDashboardGeoDataByProximity(
        proximityRadius,
        dateFilter,
      );
    },

    // Used for dashboard hero analytics globes
    getHeightAdjustedPoint(count, min, max) {
      return (count / (max - min)) * 0.6;
    },
  },
});
