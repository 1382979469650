import Vue from 'vue';

export const endpointUrl = '/api/external-services/paragon';

export default {
  async getParagonToken(params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .get(`${endpointUrl}/token`, null, params)
      .catch((err) => {
        throw new Error(err);
      });
    return data;
  },
};
