import { useLibraryStore } from '@/stores/library-store';
import { defineStore } from 'pinia';
import { PANE_DEFAULT_WIDTH, ROUTES_WITHOUT_PANE } from '../scripts/constants';

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    isSidebarOpen: false,
    isSidebarPaneOpen: false,
    sidebarPaneWidth: PANE_DEFAULT_WIDTH,
    libraryStore: useLibraryStore(),
    filter: '',
    dashboardPaneActive: 'all-contracts',
    settingsPaneActive: 'contact-info',
    automationsPaneActive: 'microsoft-word',
    supportUrl: 'https://knowledgebase.harbourshare.com/knowledge/kb-tickets/new',
    primaryNavItems: [
      {
        id: 'dashboard',
        icon: 'signature',
        title: 'Dashboard',
        permissions: ['permissions.links.view'],
      },
      {
        id: 'templates',
        icon: 'layer-plus',
        title: 'Templates',
        permissions: ['permissions.agreebuilder.view'],
      },
      {
        id: 'folders',
        icon: 'folder',
        title: 'Folders',
        permissions: ['permissions.filesystem.folder.view'],
      },
      {
        id: 'drafts',
        icon: 'file-pen',
        title: 'Drafts',
        permissions: ['permissions.links.view'],
      },
      {
        id: 'workflows',
        icon: 'code-branch',
        title: 'Workflows',
        permissions: ['permissions.links.view'],
      },
    ],
    secondaryNavItems: [
      {
        id: 'settings',
        icon: 'gear',
        title: 'Settings',
        permissions: ['permissions.settings.view'],
      },
      {
        id: 'automations',
        icon: 'bolt',
        title: 'Automations',
        permissions: ['permissions.settings.view'],
      },
      {
        id: 'support',
        icon: 'circle-question',
        title: 'Support',
        permissions: [],
      },
    ],
    settingsPanePersonalItems: [
      {
        id: 'contact-info',
        title: 'Contact info',
        subtitle: 'Update your account info',
        icon: '<i class="fa-light fa-user-pen"></i>',
      },
      {
        id: 'my-signatures',
        title: 'My signatures',
        subtitle: 'Upload a saved signature for quick future use',
        icon: '<i class="fa-light fa-signature"></i>',
      },
    ],
    settingsPaneAdminItems: [
      {
        id: 'add-users',
        title: 'Users',
        icon: '<i class="fa-light fa-user-group"></i>',
      },
    ],
    settingsPaneOrgItems: [
      {
        id: 'company-info',
        title: 'Company info',
        subtitle: 'Update your company information',
        icon: '<i class="fa-light fa-buildings"></i>',
      },
      {
        id: 'site-personalization',
        title: 'Site personalization',
        subtitle: 'Personalize your workspace',
        icon: '<i class="fa-light fa-image-landscape"></i>',
      },
      {
        id: 'billing',
        title: 'Billing',
        subtitle: 'Update billing settings',
        icon: '<i class="fa-light fa-landmark"></i>',
      },
    ],
    automationsPaneAddonsItems: [
      {
        id: 'microsoft-word',
        title: 'Microsoft Word',
        icon: '<i class="fa-brands fa-microsoft"></i>',
      },
      {
        id: 'google-docs',
        title: 'Google Docs',
        icon: '<i class="fa-brands fa-google"></i>',
      },
    ],
    automationsPaneNoCodeItems: [
      {
        id: 'zapier',
        title: 'Zapier',
        icon: '<i class="fa-light fa-bolt"></i>',
      },
    ],
    automationsPaneIntegrationsItems: [
      {
        id: 'api',
        title: 'API',
        icon: '<i class="fa-light fa-rectangle-terminal"></i>',
      },
      {
        id: 'webhook',
        title: 'Webhook',
        icon: '<i class="fa-light fa-arrow-right-arrow-left"></i>',
      },
    ],

    // Right side bar
    rSidebarComponent: null,
    rSidebarOpen: false,
    rSidebarOpenedOnce: false,
    rSidebarWidth: 0,
    rSidebarLastWidth: 420,
    rSidebarIsDragging: false,
  }),

  getters: {
    settingsPaneAllItems: (state) => {
      return [
        ...state.settingsPanePersonalItems,
        ...state.settingsPaneOrgItems,
        ...state.settingsPaneAdminItems,
      ];
    },
    automationsPaneAllItems: (state) => {
      return [
        ...state.automationsPaneAddonsItems,
        ...state.automationsPaneNoCodeItems,
        ...state.automationsPaneIntegrationsItems,
      ];
    },
    getRightSidebarStyle: (state) => {
      return {
        width: state.rSidebarWidth + 'px',
      };
    },
  },

  actions: {
    setSidebarOpen(val) {
      this.isSidebarOpen = val;
    },

    setSidebarPaneOpen(val) {
      this.isSidebarPaneOpen = val;
    },

    setFilter(val) {
      this.filter = val;
    },

    clearFilter() {
      this.setFilter('');
    },

    setDashboardPaneActive(id) {
      this.dashboardPaneActive = id;
    },

    setSettingsPaneActive(id) {
      this.settingsPaneActive = id;
    },

    setAutomationsPaneActive(id) {
      this.automationsPaneActive = id;
    },

    setSidebarPaneWidth(val) {
      this.sidebarPaneWidth = val;
    },

    setSidebarPaneDefaultWidth() {
      this.setSidebarPaneWidth(PANE_DEFAULT_WIDTH);
    },

    checkSidebarPaneEnabled(routeName) {
      if (ROUTES_WITHOUT_PANE.includes(routeName)) return false;
      return true;
    },

    // Right sidebar
    setRightSidebarWidth(width) {
      this.rSidebarWidth = width;
      this.rSidebarLastWidth = width;
    },
    setrSidebarComponent(component) {
      this.rSidebarComponent = component;
    },
    closeRightSidebar() {
      this.rSidebarOpen = false;
      this.rSidebarWidth = 0;
      this.libraryStore.currentExtraction = null;
    },
    openRightSidebar() {
      this.rSidebarOpenedOnce = true;
      this.rSidebarOpen = true;
      this.rSidebarWidth = this.rSidebarLastWidth;
      this.libraryStore.currentExtraction = null;
    },
    toggleRightSideBar() {
      if (this.rSidebarOpen) {
        this.closeRightSidebar();
      } else {
        this.openRightSidebar();
      }
    },
  },
});
