<script setup>
import { ref, computed } from 'vue';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import HrbrDraftsTemplateDropdownItem from './HrbrDraftsTemplateDropdownItem.vue';

const emit = defineEmits([
  'select-template'
]);

const templatesStore = useTemplatesStore();

const selectedTemplate = ref(null);
const search = ref('');
const isOpen = ref(false);

const closeMenu = () => isOpen.value = false;
const toggleMenu = () => isOpen.value = !isOpen.value;

const byTitle = (a, b) => {
  const aTitle = a.title.toLowerCase();
  const bTitle = b.title.toLowerCase();
  return aTitle < bTitle ? - 1 : Number(aTitle > bTitle);
};

const templates = computed(() => {
  const templatesList = [...templatesStore.myTemplates];
  // filter by search value
  const filtered = templatesList.filter((template) => {
    const searchValue = search.value.toLowerCase();
    const templateTitle = template.title.toLowerCase();
    return templateTitle.includes(searchValue);
  })
  .sort(byTitle);
  return filtered;
});

const templateGroups = computed(() => {
  const templateGroupsList = [...templatesStore.templatesGroups];
  // only groups with filtered templates
  const filtered = templateGroupsList.filter((group) => {
    return templates.value.some((t) => t.template_group_id === group.groupId);
  });
  return filtered;
});

const selectTemplate = (template) => {
  selectedTemplate.value = template;
  emit('select-template', { template })
};

const onTemplateSelect = ({ template }) => {
  selectTemplate(template);
  closeMenu();
};
</script>

<template>
  <div class="hrbr-drafts-template-dropdown" v-click-outside="closeMenu">
    <div class="hrbr-drafts-template-dropdown__selected" @click.stop="toggleMenu">
      <div class="hrbr-drafts-template-dropdown__selected-icon">
        <i class="fa-light fa-layer-group"></i>
      </div>
      <div class="hrbr-drafts-template-dropdown__selected-text">
        {{ selectedTemplate ? selectedTemplate.title : 'Select template' }}
      </div>
      <div class="hrbr-drafts-template-dropdown__selected-arrow">
        <i class="fa-solid fa-caret-down"></i>
      </div>
    </div>

    <div class="hrbr-drafts-template-dropdown__menu" v-show="isOpen">
      <div class="hrbr-drafts-template-dropdown__menu-wrapper">
        <div class="hrbr-drafts-template-dropdown__search">
          <b-input
            class="hrbr-drafts-template-dropdown__search-input" 
            type="search"
            v-model="search"
            icon="magnifying-glass"
            icon-pack="far"
            placeholder="Filter or search"
            expanded
            ref="searchInput">
          </b-input>
        </div>

        <div class="hrbr-drafts-template-dropdown__list">
          <div 
            class="hrbr-drafts-template-dropdown__list-item" 
            v-for="group in templateGroups" 
            :key="group.groupId">
            <HrbrDraftsTemplateDropdownItem
              :groupId="group.groupId"
              :groupName="group.groupName"
              :templates="templates" 
              :search="search"
              @select-template="onTemplateSelect" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-drafts-template-dropdown {
  font-size: 16px;
  display: flex;
  position: relative;
  width: 100%;
  user-select: none;

  &__selected {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 40px;
    padding: 5px 12px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #DBDBDB;
    transition: all 0.2s;

    &:hover {
      border-color: #b5b5b5;
    }
  }

  &__selected-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__selected-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-arrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__menu {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    user-select: none;
    z-index: 10;
  }

  &__menu-wrapper {
    height: 260px;
    overflow-y: auto;
  }

  &__search {
    padding: 8px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
  }

  &__list {
    padding-bottom: 8px;
  }
}
</style>
