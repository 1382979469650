export default {
  makeRows() {
    if (!this.activeWorkflow) return;
    this.blocksByRow = [];
    const nodes = this.activeWorkflow.blocks;
    const maxLevel = Math.max(...nodes.map(n => n.level));
    for (let i = 0; i <= maxLevel; i++) {
      const row = nodes.filter(n => n.level === i);
      this.blocksByRow.push(row);
    };
  },

  refreshStage() {
    this.makeRows();
    this.calculateLocations();
    this.drawConnectorPaths();
  },

  calculateLocations() {
    if (!this.activeWorkflow || !this.activeWorkflow.blocks?.length) return;
    if (this.isCalculatingPositions) return;

    this.isCalculatingPositions = true;
    this.blocksByRow[0][0].x = 0;
    this.possibleOutcomes = [];
    this.depthFirstTraversal(this.blocksByRow[0][0]);

    this.isCalculatingPositions = false;
  },

  depthFirstTraversal(node, path = []) {
    if (!node) return;

    const xShift = this.blockSize + (node.blockSpacing * 2);
    // Track the path to this node
    if (node.path) path = node.path;

    for (const [index, child] of node.outcomes.entries()) {
      const domElement = child.getDomElement();
      this.possibleOutcomes.push({ ref: child, x: domElement?.offsetLeft, y: domElement?.offsetTop });
  
      // Set the X value depending on 1 or 2 outcomes.
      // Need to generalize this to n outcomes.
      if (child.child) {
        // Update the path to this node not including itself
        child.child.path = [...path, node.id]
        if (node.outcomes.length === 1) child.child.x = node.x;
        else {
          const sign = index === 0 ? -1 : 1;
          child.child.x = node.x + xShift / 2 * sign;
        }

        if (child.child.x < this.leftmostX) this.leftmostX = child.child.x;
      }
  
      // We have assigned this node an X value. Do we have a collision?
      // Here we would need to check nodes in the same row with x <= child.child.x
      // And handle the collision
  
      const collisionIds = new Set();
      if (child.child) {
        const nodesInRow = this.blocksByRow[child.child.level];

        const collisions = [];
        nodesInRow.forEach(n => {
          if (n.id === child.child.id) return;
          if (n.x === null) return;
  
          const nLeftBound = n.x;
          const childLeftBound = child.child.x;
          const rightDelta = Math.abs(nLeftBound - childLeftBound);

          if (rightDelta < xShift - 1) {
            if (collisionIds.has(child.child.id) || collisionIds.has(n.id)) return;

            collisionIds.add(child.child.id)
            collisionIds.add(n.id);
            console.debug('\n\t\t 💥💥💥💥', xShift, rightDelta, n, child.child.id, collisionIds, "\n\n\n")

            collisions.push(n);
          }
        })

        if (collisions.length > 0) {
          // Slide over
          const xDelta = child.child.x - collisions[0].x;

          // Move the node depending on where it is
          if (xDelta === 0) child.child.x += xShift;
          else if (node.outcomes.length === 1) child.child.x += xShift / 2;
          else child.child.x += xShift / 2;
  
          // Now we need to go up the tree and adjust all nodes in the path.
          // We adjust all nodes up to the branching node by half the shift.
          // Then we adjust all nodes after the branching node by 25% of the shift.
          const childPathReversed = child.child.path.reverse();
          const collisisionPathReversed = collisions[0].path?.reverse();
          if (!collisisionPathReversed) return;

          const sharedParentNode = collisisionPathReversed.find(id => childPathReversed.includes(id));
          let hasPassedSharedParent = false;
          childPathReversed.forEach((id) => {
            
            const node = this.activeWorkflow.blocks.find(n => n.id === id);
            let shifts = 0;
            xDelta === 0 ? shifts = 1 : shifts = Math.floor(xShift / xDelta);
            const moveAmount = xShift / shifts;

            if (id === sharedParentNode && !hasPassedSharedParent) {
              const move = xShift / (shifts * 2);
              node.x += move;
              hasPassedSharedParent = true;
            } else if (hasPassedSharedParent) {
              const move = xShift / (shifts * 2);
              node.x += move;
            } else {
              node.x += Math.abs(moveAmount);
            }
          });
          if (node.x > this.rightmostX) this.rightmostX = node.x;
        }
        if (child.child.x > this.rightmostX) this.rightmostX = child.child.x;
      }
      this.depthFirstTraversal(child.child, path);
    }
  },

  async drawConnectorPaths() {
    this.paths = [];

    // Delay to ensure any resizing has happened in vue     
    await new Promise((resolve) => setTimeout(resolve, 200));

    if (!this.activeWorkflow) return;

    // Iterate through blocks and make lines
    this.activeWorkflow.blocks.forEach((block) => {
      block.outcomes.forEach((outcome) => {
        const el = block.getDomElement();
        if (!el) return;

        const nodeStageBounds = this.nodeStage?.getBoundingClientRect();
        const rowOffset = block.level * 145 + 98;

        if (!nodeStageBounds) return;
  
        const x = block.x + 160;
        const y = rowOffset;
        const childElement = outcome.child?.getDomElement();

        //If no child elemenet, then we just connect the outcome button
        if (!childElement) {
          const childX = outcome.location.x + 20 + block.x + 1;
          const childY = y + 40;
          const line = this.makeLine({ x, y, childX, childY, type: 'outcome', });

          // Generate the SVG and append to the paths list
          this.paths.push(line);
        } else {
          const childX = outcome.child.x + 160;
          const childY = y + 102;
          const line = this.makeLine({ x, y, childX, childY });

          // Generate the SVG and append to the paths list
          this.paths.push(line);
        }
      })
    })
  },

  makeLine({ x, y, childX, childY }) {

    const startX = x;
    const startY = y;
    const endX = childX;
    const endY = childY;

    const svgSettings = {
      stroke: "#9f9f9f",
      strokeWidth: "2",
      fill: "none",
    }
  
    if (Math.abs(startX - endX) < 10) {
      const curve1 = `M${startX},${startY} ${startX},${endY}`
      svgSettings.paths = [curve1]
    } else {
      const sign = startX < endX ? 1 : -1;
      const controlPointX1 = startX - 30;
      const controlPointY1 = startY + 200;
      const controlPointX2 = endX - 30;
      const controlPointY2 = endY - 200;
      svgSettings.pathData = `M${startX},${startY} C${controlPointX1},${controlPointY1} ${controlPointX2},${controlPointY2} ${endX},${endY}`

      const xDelta = 30 * sign;
      const yDelta = 30;

      const firstY = 10;
      const secondY = startY + firstY;
      
      const firstX = 20 * sign;
      const secondX = startX + firstX;
      
      const curve0 = `M${startX}, ${startY} ${startX}, ${startY + firstY}`
      const curve1 = `M${startX}, ${secondY} C${startX},${startY + yDelta} ${startX + firstX},${startY + yDelta} ${startX + firstX},${startY + yDelta}`
      const curve2 = `M${secondX},${startY + yDelta} ${endX - xDelta + (10 * sign)},${startY + yDelta}`
      const curve3 = `M${endX - (sign * 20)},${startY + yDelta} Q${endX},${startY + yDelta} ${endX},${startY + yDelta + 20}`;
      const curve4 = `M${endX}, ${startY + yDelta + 20}, ${endX},${endY}`
      svgSettings.paths = [curve0, curve1, curve2, curve3, curve4]
    }
    return svgSettings
  },

}