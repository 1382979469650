<script setup>
import HrbrDropdown from '@/components/ui/HrbrDropdown.vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { computed, nextTick, onMounted, ref } from 'vue';

const harbourStore = useHarbourStore();
const props = defineProps({
  button: {
    type: Object,
    default: () => {
      return {};
    },
  },
  component: {
    type: Object,
    default: () => {
      return null;
    },
  },
  componentParams: {
    type: Object,
    default: () => {
      return {};
    },
  },
  componentEvents: {
    type: Object,
    default: () => {
      return {};
    },
  },
  initialSelection: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'button',
  },
  parentId: {
    type: String,
    default: null,
  },
  className: {
    type: String
  }
});
const buttonRef = ref(null);
const showDropdown = ref(false);
const currentComponent = ref(null);
const action = ref(props.button?.action);
const options = ref(props.button?.options || []);
const dropdownStyle = ref(null);
const dropdown = ref(null);

const emit = defineEmits(['click']);

// @reset emit to refresh component style
const handleReset = () => updateComponentStyle();

// Toggle dropdown on click
// Defaults to HrbrDropdown unless a custom component is passed in
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
  if (props.component) currentComponent.value = props.button.component;
  if (showDropdown.value) {
    nextTick(() => {
      updateComponentStyle();
    });
  }
};

// Button returns the given action if any, or emits a click event and toggles dropdown
const handleClick = (e) => {
  if (props.button.disabled || props.button.loading) return;
  if (action.value && typeof action.value === 'function') return action.value();
  emit('click', e);
  toggleDropdown(e);
};

// Place the dropdown in the right spot and store it in dropdownStyle ref
const updateComponentStyle = () => {
  const el = buttonRef.value;
  const side = props.button?.side || 'left';
  const buttonWidth = el.offsetWidth;

  // Set up the style. Defaults to left buttons
  const style = {
    left: 0,
  };

  if (side === 'right') {
    style.left = 0 - dropdown.value.clientWidth + buttonWidth + 'px';
  }

  if (harbourStore.isMobileView) {
    style.position = 'fixed';
    style.left = 0;
    style.width = '100%';
  }
  dropdownStyle.value = style;
};

const closeMenu = () => {
  showDropdown.value = false;
  currentComponent.value = null;
};

const getButtonName = computed(() => {
  if (props.button.active) return props.button.active;
  else return props.button.text;
});

const activeSelection = ref(null);
const handleSelection = (params) => {
  activeSelection.value = params;
}

const hasDropdown = computed(() => options.value.length > 0);
const containerClasses = computed(() => {
  const classes = [
    `hrbr-button-container--${hasDropdown.value ? 'dropdown' : 'button'}`,
    props.className,
  ];
  return classes;
});

onMounted(() => {
  if (props.button.options?.length && props.initialSelection && props.type !== 'dropdown') {
    const index = props.button.options.findIndex((option) => option.id === props.initialSelection);
    activeSelection.value = props.button?.options[index]
  } else {
    props.button.options?.length && (activeSelection.value = props.button?.options[0]);
  }
})

const getButtonText = computed(() => {
  if (activeSelection.value?.stickyTitle) {
    return activeSelection.value?.value || activeSelection.value?.text;
  }
  return props.button.text;
});

const getStyle = computed(() => {
  if (props.button.style) return props.button.style;
  return null;
});

const params = computed(() => props.componentParams);
</script>

<template>

<div class="hrbr-button-container" :class="containerClasses">
  <button
    ref="buttonRef"
    class="section-btn"
    :class="[
      props.button.styleType ? `is-${props.button.styleType}` : '',
      { disabled: props.button.disabled },
      { active: props.button.isFocused }
    ]"
    :style="getStyle"
    :title="props.button.title"
    :disabled="props.button.disabled"
    @click.stop="handleClick">

    <div class="highlight-tag-position" v-if="button.ai">
      <div class="highlight-tag">
        <div class="highlight-tag-content">
          <i class="fa fa-sparkles"></i><span>AI</span>
        </div>
      </div>
    </div>

    <!-- Show a loader if this button's loading key is set -->
    <div v-if="props.button.loading">
      <span class="btn-icon btn-icon-center">
        <i class="fa-light fa-spinner-third fa-spin" data-testid="test-spinner"></i>
      </span>
    </div>

    <!-- For dropdowns with selection active -->
    <div v-else-if="activeSelection && props.type !== 'overflow'">
      <span v-if="props.button.iconLeft && !activeSelection.icon" class="btn-icon btn-icon-left">
        <i class="fal" :class="props.button.iconLeft"></i>
      </span>
      <span v-if="activeSelection.icon" class="btn-icon btn-icon-left">
        <i class="fal" :class="activeSelection.icon" data-testid="icon-left"></i>
      </span>
      <span v-if="activeSelection.value && !props.button.text" class="btn-text">
        <span v-if="!harbourStore.isMobileView || (harbourStore.isMobileView && !props.button.collapseOnMobile)">
          {{ getButtonText }}
        </span>
      </span>
      <span v-if="props.button.text" class="btn-text">
        {{ getButtonName }}
      </span>
      <span v-if="props.button.iconRight" class="btn-icon btn-icon-right">
        <i class="fal" :class="props.button.iconRight" data-testid="icon-right"></i>
      </span>
    </div>

    <div v-else>
      <slot
        v-if="props.button.hasSelection"
        name="selected"
      >
      </slot>
      <template v-else>
        <span v-if="props.button.iconLeft" class="btn-icon btn-icon-left">
          <i class="fal" :class="props.button.iconLeft" data-testid="icon-left"></i>
        </span>
        <span v-if="props.button.text" class="btn-text">
          {{ getButtonName }}
        </span>
      </template>
      <span v-if="props.button.iconRight" class="btn-icon btn-icon-right">
        <i class="fal" :class="props.button.iconRight" data-testid="icon-right"></i>
      </span>
    </div>
  </button>

  <!-- If this button contains a drop down, this is where that gets rendered -->
  <div
    v-if="showDropdown"
    v-click-outside="closeMenu"
    :style="dropdownStyle"
    ref="dropdown"
    class="dropdown"
  >
    <HrbrDropdown
      :options="options"
      :parent-id="props.parentId"
      @selected="handleSelection"
      class="dropdown-component"
      v-if="!currentComponent && showDropdown"
      @close="closeMenu"
    />
    <component
      v-else-if="currentComponent && showDropdown"
      :is="currentComponent"
      :options="options"
      :params="props.componentParams"
      ref="customComponentRef"
      class="menu-dropdown"
      v-on="props.componentEvents"
      @reset="handleReset"
      @close="closeMenu"
    />
  </div>
</div>

</template>

<style scoped lang="postcss">
.hrbr-button-container {
  height: 40px;
  position: relative;
}
.section-btn {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px 10px;
  height: 100% !important;
  min-width: 40px;
  color: #4d4d4d;
  cursor: pointer;
  transition: all 200ms ease;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover, &.active {
    background-color: var(--section-header-border-light);
  }
  &.is-tertiary {
    background-color: #E2E9FB;
    color: #1355FF !important;
    border-color: #E2E9FB;
    &:hover {
      background-color: #BCCFFF;
      color: #0133B7;
      border-color: #BCCFFF;
    }
  }
  &.is-primary {
    background-color: #1355FF;
    color: #fff !important;
    border-color: #1355FF;
    &:hover {
      background-color: #0133B7;
      color: #fff;
      border-color: #0133B7;
    }
  }
  &.is-alert {
    background-color: #CB0E47;
    color: #fff;
    border-color: #CB0E47;
    &:hover {
      background-color: #8E0A32;
      color: #fff;
      border-color: #8E0A32;
    }
  }
  &.is-confirm {
    background-color: #00853D;
    color: #fff;
    border-color: #00853D;
    &:hover {
      background-color: #005D2B;
      color: #fff;
      border-color: #005D2B;
    }
  }
}

.highlight-tag-content:hover .section-btn {
  color: black;
  border: 1px solid #6b6b6b;
  background-color: #9a9a9a33;
}

.btn-icon {
  pointer-events: none;
  &-right {
    margin-left: 4px;
  }
  &-left:not(:only-child) {
    margin-right: 4px;
  }
}
.btn-text {
  font-weight: 500;
}
.dropdown {
  position: absolute;
  display: block;
  z-index: 9999;
}
.dropdown-component {
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}
.dropdown > div {
  background-color: white;
  padding: 10px 0;
  max-height: 750px;
  overflow: auto;
  border-radius: 8px;
  margin-top: 4px;
}
.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
}
.disabled:hover {
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 5px 10px;
  height: 100%;
  min-width: 40px;
  color: #777;
  cursor: not-allowed;
}
.menu-dropdown {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}
.highlight-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 9px;
  background-size: 110%;
  background: linear-gradient(270deg, rgba(34,193,195,1) 0%, rgba(191,100,100,1) 30%, rgba(77,82,217,1) 60%, rgb(255, 219, 102) 100%);
  background-size: 110%;
  background-position: center;
  background-repeat: repeat-y;
  border: 1.4px solid transparent;
  color: #8A1285;
  overflow: hidden;
}
@media (max-width: 768px) {
  .highlight-tag {
    display: none;
  }
}
.highlight-tag-position {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: 8px;
}
.highlight-tag-content {
  background-color: white;
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 1px 7px;
  margin-top: 0;
  justify-content: center;
  transition: all .25s ease;
}
.highlight-tag-content i::before {
  margin-right: 2px;
  background: radial-gradient(circle, rgba(253,245,45,1) 0%, rgba(253,187,45,1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
}
.section-btn:hover .highlight-tag-content {
  background-color: #eeeeeecc;
  color: #222;
}

@media (max-width: 768px) {
  .highlight-tag-position {
    right: -1px;
  }
}

</style>
