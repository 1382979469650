import { range } from '@/utils/helpers/functions';
import Vue from 'vue';

const PDF_CONVERTER_URL = 'https://pdf-converter.cke-cs.com/v1/convert';
const PDF_CONVERTER_CSS_PATH = '/src/assets/css/ckeditor/pdf-converter.css';
const PDF_CONVERTER_OPTIONS = {
  format: 'A4',
  margin_top: '2cm',
  margin_bottom: '2cm',
  margin_left: '1.6cm',
  margin_right: '1.6cm',
  page_orientation: 'portrait',
};

const SCALE_FACTOR = 2;

async function getPdfjsPages(pdf, firstPage, lastPage) {
  const allPagesPromises = range(firstPage, lastPage + 1).map((num) => pdf.getPage(num));
  const allPages = await Promise.all(allPagesPromises);
  return allPages;
}

async function getPdfjsDocument(file) {
  const loadingTask = await Vue.prototype.$pdfjs.getDocument({
    url: file,
  });
  const document = await loadingTask.promise;
  return document;
}

export {
  PDF_CONVERTER_URL,
  PDF_CONVERTER_CSS_PATH,
  PDF_CONVERTER_OPTIONS,
  SCALE_FACTOR,
  getPdfjsPages,
  getPdfjsDocument,
};
