import Vue from 'vue';
import { defineStore } from 'pinia';

export const useAgreementEditorStore = defineStore('agreement-editor', {
  state: () => ({
    settingsInput: null,
    linksChangedSigners: new Set(),
    selfServiceSections: [],
    inputSettingLastChanged: {},
  }),
  actions: {
    setSettingsActiveInput(input) {
      this.settingsInput = input;
    },

    addLinkChangedSigners(linkDisplayId) {
      this.linksChangedSigners.add(linkDisplayId);
      this.updateLinksChangedSignersLocal();
    },

    deleteLinkChangedSigners(linkDisplayId) {
      this.linksChangedSigners.delete(linkDisplayId);
      this.updateLinksChangedSignersLocal();
    },

    hasLinkChangedSigners(linkDisplayId) {
      return this.linksChangedSigners.has(linkDisplayId);
    },

    updateLinksChangedSignersLocal() {
      try {
        const stringified = JSON.stringify([...this.linksChangedSigners]);
        localStorage.setItem('linksChangedSigners', stringified);
      } catch (err) {
        console.error(err);
      }
    },

    loadLinksChangedSignersLocal() {
      try {
        const localValue = localStorage.getItem('linksChangedSigners');
        if (localValue) this.linksChangedSigners = new Set(JSON.parse(localValue));
      } catch (err) {
        this.linksChangedSigners = new Set();
      }
    },

    generateFileVersionDisplayId() {
      return `FILE-${Math.floor(Math.random() * 1000000000000)}_${Date.now()}`;
    },

    async storeFileVersion({
      fileVersionDisplayId,
      fileName,
      fileBase64,
      fileSizeBytes,
      isOriginalFile,
      ancestorDisplayId,
      inputMethod = 'UPLOAD',
    }) {
      const res = await Vue.prototype.$harbourData.post('data?agreementeditor-storefileversion', {
        requesttype: 'agreementeditor-storefileversion',
        filedisplayid: fileVersionDisplayId,
        isoriginalfile: isOriginalFile,
        filename: fileName,
        filesizebytes: fileSizeBytes,
        fileancestordisplayid: ancestorDisplayId,
        filebase64: fileBase64,
        inputmethod: inputMethod,
      });

      return res.data;
    },

    async updateFileVersion({
      fileVersionDisplayId,
      fileName,
      fileBase64,
      fileSizeBytes,
      isOriginalFile,
      ancestorDisplayId,
      inputMethod = 'UPLOAD',
    }) {
      const res = await Vue.prototype.$harbourData.post(
        'data?agreement_editor_update_file_version',
        {
          requesttype: 'agreement_editor_update_file_version',
          filedisplayid: fileVersionDisplayId,
          isoriginalfile: isOriginalFile,
          filename: fileName,
          filesizebytes: fileSizeBytes,
          fileancestordisplayid: ancestorDisplayId,
          filebase64: fileBase64,
          inputmethod: inputMethod,
        },
      );

      return res.data;
    },

    async storeUpdateFileVersion(metaData) {
      await this.storeFileVersion(metaData);
      this.updateFileVersion(metaData);
    },
  },
});
