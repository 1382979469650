<script setup>
import { ref, computed, getCurrentInstance } from 'vue';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import HrbrDraftsTemplateDropdown from './HrbrDraftsTemplateDropdown.vue';

const emit = defineEmits(['close']);

const instance = getCurrentInstance();
const draftsStore = useDraftsStore();

const copyName = ref('');
const selectedTemplate = ref(null);
const creatingInProgress = ref(false);
const nameInput = ref(null);

const isSelectButtonDisabled = computed(() => {
  const isDisabled = (
    !selectedTemplate.value ||
    !copyName.value ||
    creatingInProgress.value
  );
  return isDisabled;
});

const closeModal = () => emit('close');
const onCancelClick = () => {
  closeModal();
};

const onSelectButtonClick = async () => {
  if (!selectedTemplate.value) return;

  creatingInProgress.value = true;
  const copyData = await draftsStore.createDraftFromTemplate({
    template: selectedTemplate.value,
    name: copyName.value,
  });

  if (!copyData) {
    creatingInProgress.value = false;
    return;
  }
  creatingInProgress.value = false;

  draftsStore.openDraftDocument({
    draft: copyData.draft,
    htmlData: copyData.htmlData,
    parentComponent: instance.proxy,
  });
  closeModal();
};

const selectTemplate = (template) => {
  selectedTemplate.value = template;
  copyName.value = `Copy of ${template.title}`;
  nameInput.value?.focus();
};

const onTemplateSelect = ({ template }) => {
  selectTemplate(template);
};
</script>

<template>
  <div class="hrbr-drafts-template-modal modal-card">
    <header class="hrbr-drafts-template-modal__head modal-card-head">
      <p class="modal-card-title">Select a template</p>
    </header>

    <section class="hrbr-drafts-template-modal__body modal-card-body">
      <div class="hrbr-drafts-template-modal__template">
        <HrbrDraftsTemplateDropdown 
          @select-template="onTemplateSelect" />
      </div>

      <div class="hrbr-drafts-template-modal__name" v-show="selectedTemplate">
        <div class="hrbr-drafts-template-modal__name-title">Rename copy</div>
        <b-input
          class="hrbr-drafts-template-modal__name-input" 
          type="text"
          v-model="copyName"
          expanded
          ref="nameInput">
        </b-input>
      </div>
    </section>

    <footer class="hrbr-drafts-template-modal__foot modal-card-foot">
      <b-button @click="onCancelClick">Cancel</b-button>
      <b-button 
        @click="onSelectButtonClick" 
        type="is-primary"
        :disabled="isSelectButtonDisabled">
        <span class="hrbr-drafts-template-modal__select-spinner" v-if="creatingInProgress">
          <i class="fa-light fa-spinner-third fa-spin"></i>
        </span>
        <template v-else>Select</template>
      </b-button>
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-drafts-template-modal {
  max-width: 320px;
  overflow: visible;

  &__body {
    overflow: visible;
  }

  &__name {
    margin-top: 12px;
  }
  
  &__name-title {
    font-size: 14;
    font-weight: 600;
    color: #333;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  &__select-spinner {
    display: inline-flex;
    justify-content: center;
    min-width: 47px;

    i {
      animation-duration: 0.5s;
    }
  }
}
</style>
