export const DraftStatuses = Object.freeze({
  Started: 'started',
  ReviewInternal: 'review:internal',
  ReviewExternal: 'review:external',
  Approved: 'approved',
});

export const DraftTypes = Object.freeze({
  Docx: 'docx',
  Pdf: 'pdf',
});

export const DraftRoles = Object.freeze({
  Agreement: 'agreement',
  Template: 'template',
});

export const DraftMainGroups = Object.freeze({
  MyDrafts: 'my_drafts',
  SharedDrafts: 'shared_drafts', 
});

// contract for draft data
export function createDraft(data, opts = {}) {
  return {
    id: data.id,
    orgId: data.org_id,
    name: data.name,
    ckFileId: data.ck_file_id,
    createdAt: data.created_at,
    createdBy: data.created_by,
    updatedAt: data.updated_at,
    updatedBy: data.updated_by,
    agreementId: data.agreement_id,
    status: data.status || DraftStatuses.Started,
    copiedFrom: data.copied_from,
    isShared: data.is_shared || false,
    role: data.role || DraftRoles.Agreement,
    isCopy: data.is_copy,
    type: data.type || DraftTypes.Docx,
    reader: data.reader || [],
    writer: data.writer || [],
    commentator: data.commentator || [],
    owner: data.owner || [],
  };
}

export function defineDraftRole(asTemplate = false) {
  return asTemplate ? DraftRoles.Template : DraftRoles.Agreement;
}
