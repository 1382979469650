<script setup>
import Vue from 'vue'
import { storeToRefs } from 'pinia';
import { reactive, computed, ref, onMounted } from 'vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useWorkflowsStore } from '@/stores/workflows-store';

const workflowsStore = useWorkflowsStore();
const harbourStore = useHarbourStore();
const dashboardStore = useDashboardStore();
const { gridApi } = storeToRefs(dashboardStore);
const { formatUnixTimestamp } = harbourStore;
const { isEditingApprovalFlowLink } = storeToRefs(workflowsStore);

const emit = defineEmits(['close']);
const props = defineProps({
  magicLink: {
    type: String,
    required: true,
  }
});

const data = ref(null);
const isReady = ref(false);
const disableButtons = ref(false);
const creatorComment = ref(null);
const submissions = ref([]);

const handleClick = (linkId) => {
  const node = gridApi.value.getRowNode(linkId);
  const link = node?.data;

  isEditingApprovalFlowLink.value = true;

  const linkProps = {
    linkDisplayIdToEdit: link.id,
    linkDisplayId: link.id,
    agreementId: link.agreement_id,
    folderId: link.folder,
    magicLink: props.magicLink,
    parent: this,
    creationMode: 'edit',
    isEditingApprovalFlowLink: true,
  };

  const events = {
    submitted: (link) => {
      if (!submissions.value.includes(link.id)) submissions.value.push(link);
    },
  };

  // Check if this is a CK link
  const ckeditorFileId = link.ckeditor_agreement_id;
  if (ckeditorFileId) {
    linkProps.ckeditorFileId = ckeditorFileId;
  }

  Vue.prototype.$openAgreementLinkModal({ props: linkProps, events });
}

const showSuccessMessage = () => {
  Toast.open({
    message: '✅ Changes submitted for approval',
    type: 'is-success',
    position: 'is-top',
    duration: 3000,
  
  })
};

const handleSubmit = async () => {
  disableButtons.value = true;
  const options = {
    approval_event: {
      event_type: 'approval-link-updated',
      payload: {
        magic_link: props.magicLink,
        link_type: "creator",
        comments: creatorComment.value,
        accepted_all_changes: hasAcceptedAllChanges.value
      }
    }
  }
  await Vue.prototype.$harbourData.post('/api/workflows/approval-link-event', options);
  showSuccessMessage();

  const url = new URL(window.location.href);
  window.history?.replaceState({}, document.title, url.toString());
  disableButtons.value = false;
  emit('close');
}

const getApprovalData = async () => {
  const options = {
    approval_event: {
      event_type: 'approval-editor-hit',
      payload: {
        magic_link: props.magicLink,
        link_type: "creator"
      }
    }
  }

  const result = await Vue.prototype.$harbourData.post("/api/workflows/approval-link-event", options);

  if (result.status !== 201) {
    Toast.open({
      message: `Unable to open approval link: ${result.data}`,
      type: 'is-warning',
      duration: 5000,
    });
    emit('close')
  } else {
    data.value = result.data;
    isReady.value = true;
  }

  populateChangesByRequester();
  
  // Remove the magic link from the URL
  const baseUrl = window.location.origin + window.location.pathname;
  window.history.replaceState({}, document.title, baseUrl);
}


/** 
 * Approver edits section
*/

const allRequests = ref([]);
const allChangesByRequester = ref({});
const handleChangesAccepted = (requester) => {
  checkIfAllAccepted();
}

const populateChangesByRequester = () => {
  if (!data.value) return;
  data.value.approver_events.forEach((requester) => {
    Object.keys(requester.changes).map((key) => {
      const change = requester.changes[key];
      change.accepted = false;
      return change;
    });
    allRequests.value.push(requester);
    allChangesByRequester.value[requester.event_user_email] = requester.changes;
  });
}

const hasAcceptedAllChanges = ref(false);
const checkIfAllAccepted = () => {
  if (!allRequests.value) return false;

  for (const requester of allRequests.value) {
    const changes = requester.changes;
    for (const id in changes) {
      const change = changes[id];
      if (!change.accepted) {
        hasAcceptedAllChanges.value = false;
        return;
      }
    }
  };

  // If all accepted, set the flag
  hasAcceptedAllChanges.value = true;
};

onMounted(() => {
  getApprovalData();
})
</script>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <div class="modal-title"><i class="fal fa-code-branch"></i>Approval workflow</div>
      <div class="modal-subtitle">Changes requested</div>
    </header>

    <section class="modal-card-body" v-if="!isReady">
      <div class="loading-container">
        <i class="fal fa-spinner-third fa-spin"></i>
      </div>
    </section>
    
    <section class="modal-card-body" v-else>
      <div class="main-content">
        <div class="approval-data">
          <div class="comments" v-for="requester in allRequests" :key="requester.event_time">
            <div class="event-time">
              {{ formatUnixTimestamp(requester.event_time) }}
            </div>
            <div class="section-start">
              <div class="section-title inline">{{ requester.event_user_name }}</div>
              requested changes with comments:
            </div>
            <div class="comment inline">{{ requester.comments }}</div>

            <!-- For AO Field changes -->
            <WfFieldChangesReview
              v-if="requester.changes"
              @change-toggled="handleChangesAccepted(requester)"
              :requester="requester"
              :links="data.links" />
              
          </div>

        </div>

        <div class="section-title">
          Pending approval
        </div>

        <div class="pending-row" v-for="link in data.links" :key="link.id">
          <div class="link-title"><i class="fal fa-file-pdf"></i>{{ link.title }}</div>
          <div class="action">
            <i class="fas fa-check-circle" v-if="submissions.includes(link.id)"></i>
            <button class="button is-small is-outline" @click="handleClick(link.id)">Edit</button>
          </div>
        </div>

        <div class="comment-box">
          <textarea class="textarea" placeholder="Add a comment" v-model="creatorComment"></textarea>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot modal-footer">
      <button class="button is-outline is-small" @click="emit('close')" :disabled="disableButtons || !isReady">Cancel</button>
      <button class="button is-primary is-small" @click="handleSubmit" :disabled="disableButtons || !isReady">
        <i class="fal fa-check" v-if="!disableButtons"></i>
        <i class="fal fa-spinner-third fa-spin" v-else></i>
        
        <div v-if="!hasAcceptedAllChanges">Submit for approval</div>
        <div v-else>Accept changes</div>
      </button>
    </footer>
  </div>
</template>

<style scoped>
.loading-container {
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;;
  justify-content: center;
}
.loading-container i {
  font-size: 30px;
}
.section-start {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.button i {
  margin-right: 5px;
}
.button {
  border-radius: 8px !important;
  font-weight: 600;
}
.is-primary {
  background-color: #1355ff;  
}
.is-primary:hover {
  background-color: #0133B7;
}
.button[disabled] {
  color: #666;
  cursor: not-allowed;
}
.textarea {
  resize: none;
  margin: 0;
  padding: 16px;
  font-size: 13px;
}
.textarea:focus {
  border: 1px solid #1355ff;
}

.approval-data i {
  margin-right: 5px;
}
.action i {
  margin-left: 10px;
  color: #00853D;
}
.action {
  display: flex;
  align-items: center;
}
.event-time {
  width: 100%;
  color: #666;
  font-size: 12px;
  margin-right: 5px;
}
.comments {
  background-color: #D3DBF2;
  border-radius: 8px;
  margin: 5px 0;
  padding: 20px;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}
.comment {
  font-style: italic;
  font-weight: 400;
}
.item-title {
  font-weight: 300;
}
.approval-data {
  font-size: 13px;
  padding-bottom: 10px;
  border-bottom: 1px solid #DBDBDB;
  margin-bottom: 20px;
}
.modal-card-body > div {
  margin: 0;
}
.pending-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.pending-row i {
  margin-right: 10px;
}
.modal-card-head i {
  margin-right: 10px;
}
.pending-side i {
  margin-right: 5px;
}
.section-title {
  font-weight: 600;
  font-size: 14px;
}
.inline {
  display: inline;
}
.modal-title {
  font-weight: 600;
  font-size: 18px;
}
.modal-card-head {
  padding: 20px 30px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.modal-subtitle {
  font-weight: 300;
  margin-top: 5px;
  color: #00853D;
}
.main-content {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.approver {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}
.pending-side {
  font-size: 14px;
  color: #555;
  font-weight: 500;
}
.modal-footer {
  padding: 10px;
}
</style>
