import mitt from 'mitt';

const emitter = mitt();

export const publishEvent = emitter.emit;
export const subscribeTo = emitter.on;
export const unsubscribeFrom = emitter.off;

export const subscribeOnceTo = (type, handler) => {
  const wrapper = (...args) => {
    unsubscribeFrom(type, wrapper);
    handler(args);
  };
  subscribeTo(type, wrapper);
};
