export default () => {
  return [
    {
      themename: 'BLUE-LIGHT',
      label: 'Light blue',
      themecss: 'linear-gradient(155deg, rgba(85,178,222,1) 37%, rgba(151,216,246,1) 61%)',
      titlecolor: '#fff',
    },
    {
      themename: 'BLUE-DARK',
      label: 'Dark blue',
      themecss:
        'linear-gradient(155deg, rgba(4,34,123,1) 32%, rgba(43,123,198,1) 55%, rgba(162,204,243,1) 93%)',
      titlecolor: '#fff',
    },
    {
      themename: 'RED',
      label: 'Red',
      themecss:
        'linear-gradient(155deg, rgba(154,20,44,1) 40%, rgba(200,81,102,1) 52%, rgba(241,165,179,1) 69%)',
      titlecolor: '#fff',
    },
    {
      themename: 'GREEN-LIGHT',
      label: 'Light green',
      themecss: 'linear-gradient(155deg, rgba(91,189,136,1) 40%, rgba(167,224,193,1) 69%)',
      titlecolor: '#fff',
    },
    {
      themename: 'GREEN-DARK',
      label: 'Dark green',
      themecss:
        ' linear-gradient(155deg, rgba(25,97,54,1) 35%, rgba(54,157,96,1) 51%, rgba(134,216,167,1) 66%)',
      titlecolor: '#fff',
    },
    {
      themename: 'PURPLE',
      label: 'Purple',
      themecss: 'linear-gradient(155deg, rgba(144,143,233,1) 35%, rgba(198,197,255,1) 56%)',
      titlecolor: '#fff',
    },
    {
      themename: 'YELLOW',
      label: 'Yellow',
      themecss: 'linear-gradient(155deg, rgba(238,222,80,1) 38%, rgba(249,251,104,1) 63%)',
      titlecolor: '#fff',
    },
    {
      themename: 'ORANGE',
      label: 'Orange',
      themecss: 'linear-gradient(155deg, rgba(246,174,66,1) 38%, rgba(251,204,135,1) 63%)',
      titlecolor: '#fff',
    },
    {
      themename: 'PINK',
      label: 'Pink',
      themecss: 'linear-gradient(155deg, rgba(234,139,248,1) 38%, rgba(244,192,251,1) 63%)',
      titlecolor: '#fff',
    },
    {
      themename: 'BROWN',
      label: 'Brown',
      themecss:
        'linear-gradient(155deg, rgba(101,63,5,1) 36%, rgba(142,110,66,1) 48%, rgba(206,184,157,1) 63%)',
      titlecolor: '#fff',
    },
    {
      themename: 'GREY',
      label: 'Grey',
      themecss:
        'linear-gradient(155deg, rgba(209,214,214,1) 0%, rgba(232,233,233,1) 26%, rgba(241,241,241,1) 63%)',
      titlecolor: '#fff',
    },
    {
      themename: 'BLACK',
      label: 'Black',
      themecss: 'linear-gradient(155deg, rgba(36,36,36,1) 37%, rgba(79,84,91,1) 61%)',
      titlecolor: '#fff',
    },
  ];
};