import { defineStore } from 'pinia';
import searchApiService from '@/services/search-api-service';
import { useHarbourStore } from '@/stores/harbour-store';

export const useSearchStore = defineStore('search', {
  state: () => ({
    harbourStore: useHarbourStore(),
    agGridApi: null,
    agGridColumnApi: null,
    isActive: false,
    isGridReady: false,
    isLoadingView: false,
    isLoadingAgreements: false,
    isLoadingFolders: false,
    isLoadingCreators: false,
    agreementTypes: [],
    agreementFolders: [],
    agreementCreatorEmails: [],
    accessOption: 'defaultaccess',
    filters: null,
    creatorEmailFilterValue: '',
    selectedSearchResults: [],
    activeFilters: 0,
    isFileSearch: false,
    fileType: '',
    text: '',
    isExactTextMatch: false,
    searchResults: [],
    documents: null,
    totalHitsCount: 0,
    errorMesssage: '',
    resultsCurrentLimitNum: 15,
    parentFolderFilterValue: '',
    currentPage: 1,
    startPageIndex: 1,
    endPageIndex: 15,
    multipleAgreementTextFilter: [''],
    searchInput: '',
    isDirectResultAccess: true,
    searchResultsTitle: 'Getting search results...',
    gridReference: null,
  }),

  actions: {
    setActive(val) {
      this.isActive = val;
    },

    openSearch() {
      this.setActive(true);
    },

    closeSearch() {
      this.setActive(false);
    },

    checkSearchShortcut(event) {
      return event.code === 'KeyK' && (event.ctrlKey || event.metaKey);
    },

    handleSearchShortcut(event) {
      if (!this.checkSearchShortcut(event) || this.isActive) return;
      this.openSearch();
    },

    registerShortcutListener() {
      document.addEventListener('keydown', this.handleSearchShortcut);
    },

    unregisterShortcutListener() {
      document.removeEventListener('keydown', this.handleSearchShortcut);
    },

    async loadAgreementTypes() {
      try {
        this.isLoadingAgreements = true;

        const respData = await searchApiService.getAgreementTypes();
        const agreementTypes = respData.agreement_types ?? [];
        this.agreementTypes = agreementTypes.sort(this._agreemntsByTitle);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingAgreements = false;
      }
    },

    async loadAgreementFolders() {
      try {
        this.isLoadingFolders = true;

        const isAdminAccess = this.accessOption === 'defaultaccess' ? false : true;

        const respData = await searchApiService.getAccountFolders({isAdminAccess});
        const folders = respData.folders_details ?? [];
        folders.sort((a, b) => (a.preferred_name > b.preferred_name ? 1 : -1));
        this.agreementFolders = folders;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingFolders = false;
      }
    },

    async loadAgreementCreatorEmails(){
      try {
        this.isLoadingCreators = true;

        const isAdminAccess = this.accessOption === 'defaultaccess' ? false : true;

        const respData = await searchApiService.getAgreementCreators({ isAdminAccess });
        const linkCreators = respData.linkscreators ?? [];
        linkCreators.sort();
        this.agreementCreatorEmails = linkCreators;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingCreators = false;
      }
    },

    loadStoredUserFilters(){
      localStorage.getItem('advancedSearchFilters');
      let filters = localStorage.getItem('advancedSearchFilters');
      let filtersJSON = JSON.parse(filters);
      this.filters = filtersJSON;
    },

    async loadFilters (){
      this.loadStoredUserFilters();
      await this.loadAgreementCreatorEmails();
    },

    isSearchResultsInclude(display_id) {
      return this.selectedSearchResults.includes(display_id);
    },

    selectSearchResult(params) {
      let self = this;
      self.isAllSelected = true;
      const displayId = params.data?.source.display_id;

      //Uncheck behavior - remove the value from the array because it exists
      if (self.isSearchResultsInclude(displayId)) {
        self.selectedSearchResults = self.selectedSearchResults.filter((e) => e != displayId);
      } else {
        //check behavior - add the value to the array
        self.selectedSearchResults.push(displayId);
      }
    },
    toLowerKeys(obj) {
      const entries = Object.entries(obj);
      return Object.fromEntries(
          entries.map(([key, value]) => {
            return [key.toLowerCase(), value];
          }),
      );
    },
    loadFiltersFromUrlParams(clientUrlParams) {
      let tempAgreementFilters = {
        text_filter: {},
        input_filters: [],
        attachment_filters: [],
        tags_filter: {},
        creation_date_filter: {},
        is_admin_access: this.isOrgAdmin,
        page_num: 1,
      };
      this.activeFilters = 0;
      if (clientUrlParams.referenceid !== undefined && clientUrlParams.referenceid) {
        let tempInputFilter = {};
        tempInputFilter['filter_name'] = 'document.context_source.agreementreferenceid';
        tempInputFilter['filter_index'] = -1;
        tempInputFilter['filter_type'] = 'REFERENCEID';
        tempInputFilter['filter_value'] = clientUrlParams.referenceid;
        tempAgreementFilters['input_filters'].push(tempInputFilter);
        this.activeFilters += 1;
        this.filters = tempAgreementFilters;
      }
    },
    reloadStoredUserFilters() {
      this.loadStoredUserFilters();
      if (
          this.filters &&
          this.filters.text_filter &&
          'filter_value' in this.filters.text_filter
      ) {
        this.text = this.filters.text_filter.filter_value;
        this.isExactTextMatch = this.filters.text_filter.is_exact_match;
      }

      //Get the number of active filters
      this.activeFilters = localStorage.getItem('activeFilters');
      if (this.activeFilters == undefined) {
        this.activeFilters = 0;
      } else {
        this.activeFilters = parseInt(this.activeFilters);
      }
      if (
          this.filters &&
          this.filters.attachment_filters &&
          this.filters.attachment_filters.length > 0
      ) {
        //Check if the search is by files or images
        let searchByFileFilter = this.filters.attachment_filters.find(
            (a) => a.filter_name == 'document.asset_md5',
        );
        if (searchByFileFilter) {
          this.isFileSearch = true;
          this.fileType = searchByFileFilter.filter_type;
        }
      }
      if (this.filters && this.filters.input_filters
          && this.filters.input_filters.length > 0) {
        let creatorFilter = this.filters.input_filters.find(
            (a) => a.filter_name == 'creator_email',
        );
        if (creatorFilter) {
          this.creatorEmailFilterValue = creatorFilter.filter_value;
        }
      }
    },

    async requestFilteredResults() {
      try {
        this.isGridReady = false;
        this.searchResultsTitle = 'Getting search results...';

        const respData = await searchApiService.runAdvancedSearch(this.filters);

        this.searchResults = JSON.parse(respData.searchresults);
        this.errorMesssage = this.searchResults.error;
        this.documents = this.searchResults.hits;

        if (this.documents) {
          this.totalHitsCount = this.searchResults.total_document_hits;
          this.startPageIndex = (this.currentPage - 1) * 15 + 1;
          this.endPageIndex = this.startPageIndex + this.documents.length - 1;

          if (this.documents.length === 0) {
            this.searchResultsTitle = 'No search results found';
          } else {
            let title = (this.totalHitsCount > 1 || this.totalHitsCount == 0) ?
              `${this.totalHitsCount} results` :
              `${this.totalHitsCount} result`;

            if (this.totalHitsCount > this.resultsCurrentLimitNum) {
              title = `${title} (displaying ${this.startPageIndex} - ${this.endPageIndex})`
            }
            this.searchResultsTitle = title;
          }
        }

        this.isFiltersLoading = false;
        this.isGridReady = true;
      } catch(err) {
        console.error(err);
        this.isFiltersLoading = false;
        this.isGridReady = true;
        this.documents = null;
        this.errorMesssage =
          'Error while trying to search for matching documents, ' +
          'Try again or contact support@harbourshare.com for assistance.';
      }
      return this.documents;
    },

    async getPaginatedDocuments() {
      let clientUrlParams = Object.fromEntries(new URLSearchParams(location.search));
      clientUrlParams = this.toLowerKeys(clientUrlParams);

      this.documents = null;
      if (Object.keys(clientUrlParams).length === 0 && !this.filters) {
        this.isFiltersLoading = false;
        this.isGridReady = true;
        this.documents = null;
      } else {
        if (Object.keys(clientUrlParams).length > 0) {
          this.loadFiltersFromUrlParams(clientUrlParams);
        } else if (this.filters) {
          this.reloadStoredUserFilters();
        }
        this.filters.page_num = this.currentPage;
        return await this.requestFilteredResults();
      }
    },

    // Call the global grid autosizer with this grid's references
    searchGridAutoSize() {
      const gridReference = this.gridReference;
      const gridApi = this.agGridApi;
      const gridColumnApi = this.agGridColumnApi;
      this.harbourStore.autosizeGridByReference(gridReference, gridApi, gridColumnApi);
    },

    _agreemntsByTitle(a, b) {
      const name1 = a.title.toLowerCase();
      const name2 = b.title.toLowerCase();
      return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
    },

  },
});
