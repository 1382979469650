import Vue from 'vue';

export default {
  async getUserGroups() {
    const {data} = await Vue.prototype.$harbourData.get(`/api/user-groups`);
    return data;
  },

  async updateUserGroup(id, payload) {
    const { data } = await Vue.prototype.$harbourData.put(
      `/api/user-groups/${id}`,
      payload,
    );
    return data;
  },

  async createUserGroup(payload) {
    const { data } = await Vue.prototype.$harbourData.post(
      `/api/user-groups/`,
      payload,
    );
    return data;
  },

  async deleteUserGroup(id) {
    const { data } = await Vue.prototype.$harbourData.delete(
      `/api/user-groups/${id}`,
    );
    return data;
  },
};
