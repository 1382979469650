export const DEFAULT_COLUMNS = new Map([
  [
    'dashboard',
    [
      {
        colId: 'title',
        hide: false,
        width: 250,
      },
      {
        colId: 'url',
        hide: false,
        width: 165,
      },
      {
        colId: 'document',
        hide: false,
        width: 85,
      },
      {
        colId: 'workflowsStatus',
        hide: false,
        width: 150,
      },
      {
        colId: 'completionState',
        hide: false,
        width: 210,
      },
      {
        colId: 'email_recipients',
        hide: false,
        width: 280,
      },
      {
        colId: 'total_views',
        hide: true,
        width: 120,
      },
      {
        colId: 'folder',
        hide: false,
        width: 120,
      },
      {
        colId: 'authType',
        hide: true,
        width: 120,
      },
      {
        colId: 'templateTitle',
        hide: true,
        width: 120,
      },
      {
        colId: 'active',
        hide: true,
        width: 130,
      },
      {
        colId: 'last_update',
        hide: true,
        width: 200,
      },
      {
        colId: 'created',
        hide: true,
        width: 200,
      },
      {
        colId: 'group_ids',
        hide: false,
      },
      {
        colId: 'automations',
        hide: false,
      },
      {
        colId: 'creationDetails',
        hide: true,
      },
    ],
  ],
  [
    'drafts',
    [
      {
        colId: 'type',
        width: 120,
        hide: true,
      },
      {
        colId: 'createdBy',
        width: 200,
        hide: true,
      },
      {
        colId: 'status',
        hide: false,
        width: 240,
      },
      {
        colId: 'editors',
        hide: false,
        width: 200,
      },
      {
        colId: 'viewers',
        hide: false,
        width: 200,
      },
      {
        colId: 'createdAt',
        hide: false,
        width: 165,
      },
      {
        colId: 'updatedAt',
        hide: false,
        width: 165,
      },
    ],
  ],
  ['folders', []],
  [
    'templates',
    [
      {
        colId: 'document',
        hide: false,
      },
      {
        colId: 'createdepochms',
        hide: true,
      },
      {
        colId: 'Creator',
        hide: false,
      },
      {
        colId: 'workflow_ids',
        hide: false,
      },
      {
        colId: 'updatedepochms',
        hide: false,
      },
      {
        colId: 'Updater',
        hide: true,
      },
      {
        colId: 'actions',
        width: 300,
        hide: false,
      },
    ],
  ],
]);
