const DOCX_CONVERTER_URL = 'https://docx-converter.cke-cs.com/v1/convert';
const DOCX_CONVERTER_CSS_PATH = '/src/assets/css/ckeditor/docx-converter.css';
const DOCX_CONVERTER_OPTIONS = {
  suggestions: {},
  comments: {},
  format: 'A4',
  margin_top: '2cm',
  margin_bottom: '2cm',
  margin_left: '1.6cm',
  margin_right: '1.6cm',
  page_orientation: 'portrait',
};

export { DOCX_CONVERTER_URL, DOCX_CONVERTER_CSS_PATH, DOCX_CONVERTER_OPTIONS };
