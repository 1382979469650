import {
  ALLOWED_INTEGRATIONS,
  GOOGLE_FILE_PICKER_GOOGLEDRIVE_API_KEY,
  GOOGLE_FILE_PICKER_GOOGLESHEETS_API_KEY,
  INTEGRATION_CUSTOM_PARAMETERS,
  PARAGON_CDN_PUBLIC_URL,
  PARAGON_PROJECT_ID_MAIN,
  PARAGON_PROJECT_ID_PRODUCTION,
  TRIGGER_TYPE_KEYS,
} from '@/config/paragon-integration.js';
import automationsApiService from '@/services/automations-api-service';
import paragonApiService from '@/services/external_services/paragon-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { paragon } from '@useparagon/connect';
import uniqueBy from 'lodash.uniqby';
import { defineStore } from 'pinia';

export const useAutomationsStore = defineStore('automations', {
  state: () => ({
    harbourStore: useHarbourStore(),
    activePaneTitle: 'Microsoft Word',
    isLoading: false,
    integrationsList: [],
    paragonIntegrations: [],
    paragonMetadata: {},
    metadataLoaded: false,
    paragonIntegrationsStatus: {
      new: {
        label: 'NEW',
      },
      active: {
        label: '',
      },
      inactive: {
        label: 'Premium',
      },
      enabled: {
        label: '',
        icon: 'circle-check',
      },
    },
    paragonIntegrationsImplemented: [
      'googledrive',
      'googlesheets',
      'custom.catalis',
      'slack',
      'salesforce',
    ],
    availableIntegrations: ['googledrive', 'googlesheets', 'custom.catalis', 'salesforce'],
    paragonToken: null,
    isAuthenticatedInParagon: false,
    isLoadingIntegrations: false,
    linksDataChanged: false,
    filePickerOpened: false,
  }),
  getters: {
    getTitle: (state) => {
      return state.activePaneTitle;
    },
    getParagonProjectId: () => {
      const env = () => {
        const harbourStore = useHarbourStore();
        if (harbourStore.contextDict?.gae_service == 'default-production') return 'production';
        return harbourStore.contextDict?.gae_service || 'localdev';
      };
      return ['production', 'canary'].includes(env())
        ? PARAGON_PROJECT_ID_PRODUCTION
        : PARAGON_PROJECT_ID_MAIN; // localdev/livetest/staging
    },
  },
  actions: {
    setTitle(title) {
      this.activePaneTitle = title || '';
    },

    async authenticateInParagon() {
      if (this.isAuthenticatedInParagon) {
        return true;
      }

      // Get paragon token from backend
      const { token } = await paragonApiService.getParagonToken({
        user_id: this.harbourStore.contextDict?.systemuserid,
      });
      this.paragonToken = token;

      // Authenticate with paragon SDK
      try {
        await paragon.authenticate(this.getParagonProjectId, this.paragonToken);

        if (this.harbourStore.contextDict) {
          const userMetadata = {
            email: this.harbourStore.contextDict.systememail,
            name: this.harbourStore.contextDict.systememailname,
          };
          await paragon.setUserMetadata(userMetadata);
        }

        // Subscribe to Paragon SDK event
        paragon.subscribe('onIntegrationInstall', this.onIntegrationInstalled);
      } catch (error) {
        //Toast.open({
        //  duration: 3500,
        //  message: 'Paragon authentication failed. Please try again later.',
        //  position: 'is-top',
        //  type: 'is-danger',
        //});
        Sentry.captureException(error);
        this.isAuthenticatedInParagon = false;
        return false;
      }
      this.isAuthenticatedInParagon = true;
      return true;
    },

    async paragonRequest(integrationType, url, options) {
      if (!this.isAuthenticatedInParagon) {
        return null;
      }
      return paragon.request(integrationType, url, options);
    },

    async paragonActions(payload) {
      if (!this.isAuthenticatedInParagon) {
        return null;
      }

      const url = `https://api.useparagon.com/projects/${this.getParagonProjectId}/sdk/actions`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.paragonToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (![200, 201].includes(response.status)) {
        console.error('Failed to retrieve paragon actions');
        console.error(response);
        return null;
      }

      return response.json();
    },

    async listParagonIntegrations() {
      if (this.paragonIntegrations?.length) {
        return;
      }

      if (!this.isAuthenticatedInParagon) {
        await this.authenticateInParagon();
      }

      // Init
      let result = null;

      // Send request
      const response = await fetch(
        `https://api.useparagon.com/projects/${this.getParagonProjectId}/sdk/integrations`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.paragonToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.ok) {
        result = await response.json();
        this.integrationsList = result;
      } else {
        alert('Error listing integrations. Please try again later.');
      }
      this.paragonIntegrations = this.prepareParagonData(result);
    },

    async listHarbourIntegrations(agreementLinkId = null, integrationType = null, listAll = false) {
      let params = {
        organization_id: this.harbourStore.contextDict?.organizationid,
        ...(agreementLinkId && { agreement_link_id: agreementLinkId }),
        ...(integrationType && { integration_type: integrationType }),
      };

      const data = await automationsApiService.listHarbourIntegrations(params);

      return listAll ? data : uniqueBy(data, 'integration_type');
    },

    prepareParagonData(paragonIntegrations) {
      const processedIntegrations = [];
      // Retrieve currently authenticated user
      const paragonAuthenticatedUser = paragon.getUser();
      if (!paragonAuthenticatedUser.authenticated) return;

      if (!paragonIntegrations?.length) {
        console.log('pagagon data is not available. Try later again');
        return [];
      }

      // For each user integration set project enabled status
      const paragonUserInfoIntegrationTypes = Object.keys(paragonAuthenticatedUser.integrations);
      paragonUserInfoIntegrationTypes.forEach((integrationType) => {
        const integration = paragonIntegrations.find((i) => i.type === integrationType);
        if (integration) {
          paragonAuthenticatedUser.integrations[integrationType].isActive = integration.isActive;
        }
      });

      // Iterate on every added integration
      paragonIntegrations.forEach((integration) => {
        let type = integration.type;
        // Skip Harbour integration
        if (integration.customIntegration?.name === 'Harbour') {
          return;
        }

        const integrationData = { ...integration };

        if (integration.type === 'custom') {
          type = integration.customIntegration.slug;
          integrationData.type = type;
        }

        // Append Paragon integrations metadata
        this.paragonMetadata[type] = this.appendIntegrationMetadata(
          type,
          integration.name,
          integration.configs[0].values.accentColor,
          integration.customIntegration?.icon,
        );
        integrationData.name = this.paragonMetadata[type].name;
        this.metadataLoaded = true;

        // Set integration credentials (if exists)
        if (type in paragonAuthenticatedUser.integrations) {
          integrationData.credentialStatus =
            paragonAuthenticatedUser.integrations[type].credentialStatus;
          integrationData.credentialId = paragonAuthenticatedUser.integrations[type].credentialId;
        }

        // Set integration status
        let integrationStatus = 'inactive';
        if (
          integration.isActive === true &&
          (this.paragonIntegrationsImplemented.includes(type) ||
            this.paragonIntegrationsImplemented.includes(integration.customIntegration?.slug))
        ) {
          // Set new if at least one workflow has been created less than 30 days
          integrationStatus = 'active';
          integration.workflows.forEach((workflow) => {
            const daysDiff = Math.floor(
              (new Date() - new Date(workflow.dateCreated)) / (24 * 3600 * 1000),
            );
            if (daysDiff < 30) {
              integrationStatus = 'new';
            }
          });

          // special case for salesforce
          if (type === 'salesforce') {
            const paragonAuthenticatedUser = paragon.getUser();
            if (paragonAuthenticatedUser.integrations[type]?.credentialStatus === 'VALID') {
              integrationStatus = 'enabled';
            }
          }
        }

        // Set integration status to inactive if not allowed for org
        if (
          type in ALLOWED_INTEGRATIONS &&
          !ALLOWED_INTEGRATIONS[type].includes(this.harbourStore.contextDict?.organizationid)
        ) {
          integrationStatus = 'inactive';
        }
        integrationData.status = integrationStatus;

        // Append integration to array
        processedIntegrations.push(integrationData);
      });
      processedIntegrations.sort((a, b) => Number(b.isActive) - Number(a.isActive));
      const availableIntegrations = processedIntegrations.filter((a) =>
        this.availableIntegrations.includes(a.name.toLowerCase()),
      );
      const otherIntegrations = processedIntegrations.filter(
        (a) => !this.availableIntegrations.includes(a.name.toLowerCase()),
      );

      return [...availableIntegrations, ...otherIntegrations];
    },

    appendIntegrationMetadata(integrationType, name, brandColor, customIconSrc = '') {
      // Remove separators
      const integrationProvider = integrationType.split('.')[0];

      // If not available, icon will be replaced by
      // marketplace-content-app-icon-content-box-img-fallback
      const icon = customIconSrc || `${PARAGON_CDN_PUBLIC_URL}/${integrationProvider}.svg`;

      // Append metadata dictionary
      return {
        name,
        icon,
        brandColor,
      };
    },

    async updateIntegrationParams(harbourParameters, linkId) {
      if (harbourParameters.automation_id) {
        await automationsApiService.updateHarbourIntegration(harbourParameters.automation_id, {
          ...harbourParameters,
          updated_by: this.harbourStore.contextDict?.systemuserid,
          created_by: harbourParameters.created_by.user_id,
        });
      } else {
        // Create integration parameters
        await automationsApiService.createHarbourIntegration({
          organization_id: this.harbourStore.contextDict?.organizationid,
          created_by: this.harbourStore.contextDict?.systemuserid,
          agreement_link_id: linkId,
          ...harbourParameters,
        });
      }
    },

    async onCustomIntegrationSave(integrationType, harbourParameters, eventParams, action) {
      this.isLoadingIntegrations = true;
      const { selectedTrigger, customParameters, notificationSettings } = eventParams;
      const params = {
        ...harbourParameters,
      };

      params.integration_type = integrationType;
      params.custom_parameters = customParameters;
      params.notification_settings = notificationSettings;
      params.trigger_type = TRIGGER_TYPE_KEYS[selectedTrigger];

      await action(params);

      this.isLoadingIntegrations = false;
    },

    async onParagonIntegrationSave(integrationType, harbourParameters, eventParams, action) {
      this.isLoadingIntegrations = true;

      const { selectedTrigger, ...args } = eventParams;
      let params = {
        ...harbourParameters,
      };

      // re-emit to parent component
      const integration = this.paragonIntegrations.find(
        (integrationItem) => integrationItem.type === integrationType,
      );
      if (!integration.credentialId) {
        alert('Can not save integration: credential id was missed. Please, try again later');
        Sentry.captureException(new Error('Missing integration credential id'));
        return;
      }
      params.credential_id = integration.credentialId;
      params.integration_type = integrationType;

      params = {
        ...params,
        ...INTEGRATION_CUSTOM_PARAMETERS[integrationType].getSavedParameters(
          args,
          harbourParameters,
          selectedTrigger,
        ),
      };

      if (!Object.keys(params.custom_parameters).length) {
        alert('Can not save integration: integration parameters were not provided');
        Sentry.captureException(new Error('Missing integration custom_parameers'));
        return;
      }

      await action(params);
      this.isLoadingIntegrations = false;
    },

    async enableWorkflowForSalesforce(integration) {
      const subdomain = this.harbourStore.contextDict?.www_urlsubdomain;
      for (let workflow of integration.workflows) {
        if (workflow.description.toLowerCase().startsWith(`[${subdomain}]`)) {
          const payload = {};
          payload.config = {};
          payload.config.configuredWorkflows = {
            [workflow.id]: {
              enabled: true,
              settings: {},
            },
          };
          const response = await fetch(
            `https://api.useparagon.com/projects/${this.getParagonProjectId}/sdk/credentials/${integration.credentialId}`,
            {
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${this.paragonToken}`,
                'Content-Type': 'application/json',
              },
              withCredentials: true,
              body: JSON.stringify(payload),
            },
          );
          if (!response.ok) {
            alert('Paragon workflow was not enabled. Please try again later.');
            break;
          }
        }
      }
    },

    async installIntegration(integration) {
      let shouldOpenCustomModal = true;

      if (
        integration.isActive &&
        (!integration.credentialStatus || integration.credentialStatus == 'INVALID')
      ) {
        if (integration.type.startsWith('custom')) await paragon.connect(integration.type);
        else {
          await paragon.installIntegration(integration.type);
        }
      }
      if (integration.type === 'salesforce') {
        shouldOpenCustomModal = false;
      }

      return shouldOpenCustomModal;
    },

    async onIntegrationInstalled(data) {
      const integrationType = data.integrationType;
      this.paragonIntegrations = this.prepareParagonData(this.integrationsList);
      if (integrationType === 'salesforce') {
        const integration = this.paragonIntegrations.find((i) => i.type === integrationType);
        await this.enableWorkflowForSalesforce(integration);
      }
    },

    async runFilePicker(type, onFileSelect) {
      try {
        this.filePickerOpened = true;
        let allowedTypes = ['application/vnd.google-apps.folder'];
        let developerKey = GOOGLE_FILE_PICKER_GOOGLEDRIVE_API_KEY;
        let includeFolders = true;
        let allowFolderSelect = true;
        if (type === 'googlesheets') {
          // NOTE: this is a workaround using GoogleDrive picker for GoogleSheets
          // since Paragon does not support GoogleSheets picker yet
          let googleDriveIntegration = this.paragonIntegrations.find(
            (integration) => integration.type === 'googledrive',
          );
          await this.installIntegration(googleDriveIntegration);

          allowedTypes = ['application/vnd.google-apps.spreadsheet'];
          developerKey = GOOGLE_FILE_PICKER_GOOGLESHEETS_API_KEY;
          includeFolders = false;
          allowFolderSelect = false;
        }

        // NOTE: The following request fixes bug on paragon side related to refreshing token
        // TODO: remove this request when paragon fixes the bug
        await paragon.request('googledrive', '/about?fields=*', {
          method: 'GET',
        });

        // TODO: remove this workaround when Paragon supports GoogleSheets picker
        const picker = new paragon.ExternalFilePicker('googledrive', {
          allowedTypes,
          includeFolders: includeFolders,
          allowMultiselect: false,
          allowFolderSelect: allowFolderSelect,
          onFileSelect,
          onClose: () => {
            this.filePickerOpened = false;
          },
          onCancel: () => {
            this.filePickerOpened = false;
          },
        });

        await picker.init({
          developerKey,
        });

        picker.open();
      } catch (e) {
        this.filePickerOpened = false;
      }
    },
  },
});
