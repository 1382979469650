import Vue from 'vue';

export const endpointUrl = '/api/links';

export default {
  async listLinkGroups(linkId, params) {
    const { data } = await Vue.prototype.$harbourData
      .get(`${endpointUrl}/${linkId}/user-groups`, null, params)
      .catch((err) => {
        throw new Error(err);
      });
    return data;
  },

  async addGroupToLink(linkId, userGroupId, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .put(`${endpointUrl}/${linkId}/user-groups/${userGroupId}`, payload, params)
      .catch((err) => {
        throw new Error(err);
      });
    return data;
  },

  async removeGroupFromLink(linkId, userGroupId, payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .delete(`${endpointUrl}/${linkId}/user-groups/${userGroupId}`, payload, params)
      .catch((err) => {
        throw new Error(err);
      });
    return data;
  },

  async transferLinkOwnership(linkId, newOwnerEmail) {
    const { data } = await Vue.prototype.$harbourData.put(
      `/api/links/${linkId}/transfer-ownership`,
      {
        client_importbyurl_originalcreatoremail: newOwnerEmail,
      },
    );

    return data;
  },
};
