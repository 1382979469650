export default {
  "requestApproval": {
    "versions": [
      {
        "name": "1.0.0",
        "description": "Initial version",
      },
    ]
  },
  "startAgreement": {
    "versions": [
      {
        "name": "1.0.0",
        "description": "Initial version",
      }
    ]
  },
  "sendEmail": {
    "versions": [
      {
        "name": "1.0.0",
        "description": "Initial version",
      }
    ]
  },
  "sendEmailForSignature": {
    "versions": [
      {
        "name": "1.0.0",
        "description": "Initial version",
      }
    ]
  },
  "writeCode": {
    "versions": [
      {
        "name": "1.0.0",
        "description": "Initial version",
      }
    ]
  }
}
