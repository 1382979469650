import { computed, ref, unref } from 'vue';

export function useFolder(folder) {
  const id = ref(folder.id);
  const name = ref(folder.name);
  const parent = ref(folder.parent);
  const origin = ref(folder.origin);
  const creatorName = ref(folder.creatorName);
  const creatorEmail = ref(folder.creatorEmail);
  const collaborators = ref(folder.collaborators);
  const viewers = ref(folder.viewers);
  const folderPath = ref([]);
  const ancestors = ref([]);
  const columnState = ref(folder.columnState);
  const isCopying = ref(folder.isCopying);

  const isRoot = computed(() => {
    return !parent.value;
  });

  const buildFolderPath = (folders) => {
    const path = [id.value];
    if (!parent.value) return path;
    let parentId = parent.value;
    while (parentId) {
      const parentFolder = folders.find((f) => f.id === parentId);
      path.unshift(parentId);
      parentId = parentFolder.parent;
    }
    return path;
  };

  const setFolderPath = (folders) => {
    const foldersNormalized = unref(folders);
    const path = buildFolderPath(foldersNormalized);
    folderPath.value = path;
  };

  const setAncestors = (folders) => {
    let ancestorIds = [];
    folders.forEach((folder) => {
      const folderPath = unref(folder.folderPath);
      if (!folderPath.includes(id.value)) return;
      const index = folderPath.findIndex((path) => path === id.value);
      const fromNext = index + 1;
      const sliced = folderPath.slice(fromNext);
      ancestorIds.push(...sliced);
    });
    ancestorIds = Array.from(new Set(ancestorIds));
    const mapFolder = (id) => folders.find((folder) => unref(folder.id) === id);
    ancestors.value = ancestorIds.map(mapFolder);
  };

  return {
    id,
    name,
    parent,
    origin,
    creatorName,
    creatorEmail,
    collaborators,
    viewers,
    ancestors,
    folderPath,
    isRoot,
    setFolderPath,
    setAncestors,
    columnState,
    isCopying,
  };
}
