import { ToastProgrammatic as Toast } from 'buefy';

export const errorHandler = (requestName, err) => {
  const messages = [];

  try {
    if (!err.response || !err.response?.data || !err.response?.data?.error) {
      messages.push('An unexpected error occurred while processing the request.');
    }
    const errorMessages = err.response.data.messages;
    if (errorMessages.length) {
      errorMessages.forEach((message) => {
        messages.push(message);
      });
    }
    if (messages.length) {
      Toast.open({
        message: messages.join('; '),
        type: 'is-danger',
        position: 'is-top',
        duration: 7000,
        pauseOnHover: true,
      });
    }
  } catch (e) {
    console.error(`Error parsing ${requestName} errors`, e);
    Toast.open({
      message: 'An unexpected error occurred while processing the request.',
      type: 'is-danger',
      position: 'is-top',
      duration: 7000,
      pauseOnHover: true,
    });
  }
};
