<script setup>
import { computed } from 'vue'

const props = defineProps({
  lightColor: {
    type: String,
    default: '#fff',
  },
  darkColor: {
    type: String,
    default: '#000',
  },
  icon: {
    type: String,
    default: 'fas fa-check',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  circleStyle: {
    type: Object,
    default: () => ({}),
  }
})

const getStyle = computed(() => {
  return {
    '--wf-light-color': props.lightColor,
    '--wf-dark-color': props.darkColor,
    '--wf-dark-color-opacity': `${props.darkColor}99`,
  };
})
</script>

<template>
  <div class="wf-col wf-left-col" :style="getStyle">
    <i class="fal fa-spinner-third fa-spin wf-icon-container" v-if="props.loading" :style="props.circleStyle"></i>
    <i class="wf-icon-container" :class="props.icon" v-else :style="props.circleStyle"></i>
  </div>
</template>

<style scoped>
  .wf-icon-container {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--wf-light-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--wf-dark-color);
    font-size: 18px;
  }
</style>
