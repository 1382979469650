const ROUTES_WITHOUT_PANE = ['advancedsearch', 'content'];

const PANE_DEFAULT_WIDTH = 320;
const PANE_MIN_WIDTH = PANE_DEFAULT_WIDTH;
const PANE_MAX_WIDTH = 500;

export {
  ROUTES_WITHOUT_PANE,
  PANE_DEFAULT_WIDTH,
  PANE_MIN_WIDTH,
  PANE_MAX_WIDTH
};
