<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';

const settingsPageStore = useSettingsPageStore();
const { listUserGroups } = storeToRefs(settingsPageStore);

const props = defineProps({
  params: {
    type: Object,
    default: () => { }
  }
});

const emit = defineEmits(['change']);

const groupsList = computed(() => {
  const filteredGroups = listUserGroups.value.filter(group => group.name !== 'All users');
  return [
    {
      name: 'Only me',
      id: 'me',
      icon: 'user',
      bgColor: '#fff',
      iconColor: '#333'
    },
    ...filteredGroups,
  ];
});

const toggleUserGroup = (group, value) => {
  emit('change', {
    group,
    value
  });
};

const isSelected = (group) => {
  if (group.id === 'me' && !props.params.selectedGroups.length) return true;
  if (group.id === 'me' && props.params.selectedGroups.length > 0) return false;

  return props.params.selectedGroups.findIndex(item => item.id === group.id) > -1;
};
</script>

<template>
  <div class="groups-dropdown-container">
    <b-checkbox v-for="group in groupsList" :key="group.id" :value="isSelected(group)"
      @input="toggleUserGroup(group, $event)">
      <div class="check-label">
        <div class="icon-wrap" :style="{
          backgroundColor: group.bgColor,
          color: group.iconColor || '#fff',
          borderColor: group.iconColor || group.bgColor,
        }">
          <i :class="['fa-light', `fa-${group.icon}`]"></i>
        </div>
        {{ group.name }}
      </div>
    </b-checkbox>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/elements/checkbox.css";

.groups-dropdown-container {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 200px !important;

  .checkbox {
    padding: 8px 16px;
    margin-right: 0;
  }
}

.check-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid;

  i {
    font-size: 9px;
    color: inherit;
  }
}
</style>