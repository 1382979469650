import { errorHandler } from '@/utils/helpers/error-handler.js';
import Vue from 'vue';
export const endpointUrl = '/api/services/emails';

export default {
  async sendEmail(payload = {}, params = {}) {
    const { data } = await Vue.prototype.$harbourData
      .post(`${endpointUrl}/send`, payload, params)
      .catch((err) => {
        errorHandler('sendEmail', err);
      });
    return data;
  },
};
