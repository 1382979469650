// A fancy logger for the harbour store
export default {
  initLogger() {
    const types = Object.keys(this.loggerStyles);
    types.forEach((type) => {
      this.logger[type] = this.addLogger(type);
    });
  },

  addLogger(type) {
    return (...args) => {
      console.debug(`%c[${type}]`, this.loggerStyles[type] || '', ...args);
    }
  },

  log(...args) {
    if (import.meta.env.DEV) {
      try {
        this.logger.harbourStore(...args);
      } catch (e) {
        console.debug('[harbourStore]', ...args);
      }
    }
  },
}
