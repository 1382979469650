import { defineStore } from 'pinia';
import { ToastProgrammatic as Toast } from 'buefy';
import magicAnnotationsService from '@/services/magic-annotations-service.js';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import { COORDINATES_CONVERT_COEF, MAGIC_ANNOTATIONS_SIZES } from '@/config/magic-annotations.js';

const getRandomArbitrary = (min, max) => {
  return Math.round(Math.random() * (max - min) + min);
};

export const useMagicAnnotationsStore = defineStore('magic-annotations', {
  state: () => ({
    settingsPageStore: useSettingsPageStore(),
    // magic annotations and I'm the only signer data
    preloadedFileId: null,
    pageMode: 'edit',
    isLoading: false,
    suggestionsLoaded: false,
    mageUnavailable: false,
    isOnlySigner: false,
    isOnlySignerModalOpened: false,
    finalOnlySignerModalOpened: false,
    signaturePadModalOpened: false,
    consentModalOpened: true,
    isFinishConsentModal: false,
    topNotificationText: '',
    uploadedDocData: null,
    uploadedFile: null,
    documentPages: [],
    recordId: null,
    isFirstSigning: false,
    suggestionsList: [],
    addedInputsList: [],
    signatureNameRef: null,
    signaturePadAnnotation: null,
    finalAssetId: null,
    firstPageNum: 0,
    firstAnnotationRef: null,
    scrolledToFirstRef: false,
    documentRef: null,
    scrollPosition: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    pagesScale: [],
    // for coordinates convert
    pagesHeight: [],
    pagesWidth: [],
    documentType: null,

    termsAgreed: false,

    // have to save focused input index so User could navigate by fields both with tab, manual focus and next button
    focusedSuggestionIndex: 0,
  }),
  getters: {
    getPageSuggestions: (state) => (pageNum) => {
      return state.suggestionsList.filter((item) => item.page_number === pageNum);
    },
    getAllPageInputs: (state) => (pageNum) => {
      const suggestions = state.suggestionsList.filter((item) => item.page_number === pageNum);
      const customInputs = state.addedInputsList.filter((item) => item.page_number === pageNum);
      return suggestions.concat(customInputs);
    },
    getAllDocumentInputs: (state) => {
      return [...state.suggestionsList, ...state.addedInputsList];
    },
    getPageCustomInputs: (state) => (pageNum) => {
      return state.addedInputsList.filter((item) => item.page_number === pageNum);
    },
    getSignatures() {
      return this.settingsPageStore.storedSignatures;
    },
    getUserName() {
      return this.settingsPageStore.userFullName;
    },
    getFirstPageSuggestions() {
      return this.getPageSuggestions(this.firstPageNum);
    },
  },
  actions: {
    async getDocumentSuggestions() {
      try {
        const { annotated_data, id, processing_method, is_first_signing } =
          await magicAnnotationsService.getDocumentSuggestions(this.uploadedFile);
        // Skip date field type for now
        this.suggestionsList = annotated_data.map((suggestion) => ({
          ...suggestion,
          ...this.convertCoordsToHtml(suggestion),
          active: false,
          animation_visible: false,
          animated: suggestion.page_number === 0,
          value:
            suggestion.field_type === 'checkbox'
              ? suggestion.value === 'On'
                ? 'x'
                : ''
              : suggestion.value,
        }));

        this.suggestionsLoaded = true;
        this.isFirstSigning = is_first_signing;
        this.recordId = id;
        this.documentType = processing_method;
      } catch (e) {
        this.suggestionsLoaded = true;
        this.mageUnavailable = true;
        this.suggestionsList = [];
        console.error(e);
        Toast.open({
          message: 'Can not process document. Please, try again later.',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      }
    },

    // Convert coordinates from Cartesian coordinate system to PDF coordinates
    convertCoordsToPdf(suggestion) {
      const { x1, x2, y1, y2, page_number } = suggestion;
      return {
        x1: Math.round(x1 / COORDINATES_CONVERT_COEF),
        x2: Math.round(x2 / COORDINATES_CONVERT_COEF),
        y1: Math.round((this.pagesHeight[page_number] - y2) / COORDINATES_CONVERT_COEF),
        y2: Math.round((this.pagesHeight[page_number] - y1) / COORDINATES_CONVERT_COEF),
      };
    },

    // Convert coordinates to Cartesian coordinate system from PDF coordinates
    convertCoordsToHtml(suggestion) {
      const { x1, x2, y1, y2, page_number } = suggestion;
      return {
        x1: parseFloat((x1 * COORDINATES_CONVERT_COEF).toFixed(4)),
        x2: parseFloat((x2 * COORDINATES_CONVERT_COEF).toFixed(4)),
        y1: parseFloat((this.pagesHeight[page_number] - y2 * COORDINATES_CONVERT_COEF).toFixed(4)),
        y2: parseFloat((this.pagesHeight[page_number] - y1 * COORDINATES_CONVERT_COEF).toFixed(4)),
      };
    },

    addInput({ type, value = null, pageNum, x, y, addedByClick, testId }) {
      const { checkbox, image, defaultItem } = MAGIC_ANNOTATIONS_SIZES;

      // Calc initial x and y so added input always presented in visible area
      const pageScale = this.pagesScale[pageNum];
      const docWidth = this.pagesWidth[pageNum];
      let initY = (this.scrollPosition.y - pageNum * this.scrollPosition.h) / pageScale.y + 200;
      if (initY < 0) initY = 200;
      const initX = this.scrollPosition.x / pageScale.x + 200;

      const leftPadding = 20;
      const defaultWidth =
        docWidth - x < defaultItem.w ? docWidth - x - leftPadding : defaultItem.w;

      const pageSuggestions = this.getPageSuggestions(pageNum);
      const customInputs = this.getPageCustomInputs(pageNum);
      const x1 = x || initX,
        y1 = y || initY;
      let x2 = x1 + defaultWidth,
        y2 = y1 + defaultItem.h;
      if (type === 'checkbox') {
        x2 = x1 + checkbox.w;
        y2 = y1 + checkbox.h;
      }
      if (type === 'image') {
        x2 = x1 + image.w;
        y2 = y1 + image.h;
      }

      const unique_box_id =
        testId ||
        `B-${pageNum}-${
          pageSuggestions.length + customInputs.length + getRandomArbitrary(100, 500)
        }`;

      this.addedInputsList.push({
        active: true,
        field_type: type,
        value,
        name: null,
        page_number: pageNum,
        x1,
        y1,
        x2,
        y2,
        addedByClick,
        unique_box_id,
      });

      return unique_box_id;
    },

    clearAgreementData() {
      this.isOnlySigner = false;
      this.uploadedFile = null;
      this.uploadedDocData = null;
      this.suggestionsLoaded = false;
      this.suggestionsList = [];
      this.addedInputsList = [];
      this.documentPages = [];
      this.scrollPosition = {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
      };
      this.termsAgreed = false;
      this.consentModalOpened = true;
      this.isFinishConsentModal = false;
      this.documentRef = null;
      this.firstAnnotationRef = null;
      this.scrolledToFirstRef = false;
    },

    getStoredSignatures() {
      if (!this.settingsPageStore.storedSignatures.length) {
        this.settingsPageStore.getStoredValues();
      }
    },

    async saveDocument() {
      try {
        this.isLoading = true;
        const data = [...this.suggestionsList, ...this.addedInputsList].map((userItem) => ({
          ...userItem,
          ...this.convertCoordsToPdf(userItem),
        }));

        const response = await magicAnnotationsService.submitDocument(this.recordId, data);
        this.isLoading = false;
        this.isOnlySignerModalOpened = false;
        this.finalOnlySignerModalOpened = true;
        this.finalAssetId = response.asset_display_id;

        Toast.open({
          message: 'Document was successfully completed!',
          type: 'is-success',
          position: 'is-top',
          duration: 3500,
        });
      } catch (e) {
        console.error(e);
        Toast.open({
          message: 'Can not sign document. Please, try again later.',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
