import { ToastProgrammatic as Toast } from 'buefy';
import themes from './themes.js';
import pica from 'pica';

const MIN_FILE_SIZE = 500;
const MAX_FILE_SIZE = 20971520;

export const getAvailableOrganizationalThemes = themes;

export const getBlobFromUrl = async (url) => {
  const res = await fetch(url);
  const blob = await res.blob();
  return blob;
};

export const readFileAsDataUrl = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const readFileAsBinaryString = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = reject;
    reader.readAsBinaryString(blob);
  });
};

export const readFileAsText = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = reject;
    reader.readAsText(blob);
  });
};

export const createThumbnailFromBase64 = (
  base64data,
  maxWidth,
  maxHeight,
  compressionAmount = 0.8,
  skipContrastEnhancement = false,
) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = async () => {
      //If resizing is not needed, return orignal result
      if (img.width < maxWidth && img.height < maxHeight) {
        console.log(
          '#createThumbnailFromBase64 - no resizing needed (already right-sized), returning original',
        );
        return resolve(base64data);
      }

      //Create canvas to size of original image
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      //Enforce a white background (*if image has transparency [e.g., PNG transparent bkg])
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      //Add image on top of canvas background
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      //Calculate any as-needed, scaled-down new size (*dependent on max side)
      let newWidth = 0;
      let newHeight = 0;
      let maxSize = Math.max(maxWidth, maxHeight);
      let resizeFactor = null;
      if (img.width > maxSize || img.height > maxSize) {
        if (img.width > img.height) {
          newWidth = maxSize;
          resizeFactor = img.height / img.width;
          newHeight = parseInt(maxSize * resizeFactor);
        } else {
          newHeight = maxSize;
          resizeFactor = img.width / img.height;
          newWidth = parseInt(maxSize * resizeFactor);
        }
      } else {
        newWidth = img.width;
        newHeight = img.height;
      }

      //Resize to a new canvas (with pica)
      let resizingCanvas = document.createElement('canvas');
      let resizingCanvasCtx = resizingCanvas.getContext('2d');
      resizingCanvas.width = newWidth;
      resizingCanvas.height = newHeight;
      resizingCanvasCtx.fillStyle = 'white';
      resizingCanvasCtx.fillRect(0, 0, newWidth, newHeight);

      //Default resizing configuration
      let picaResizeOptions = {
        alpha: true,
      };

      //Optionally, if significant resizing of original image, apply unsharping/re-constrasting
      if (!skipContrastEnhancement) {
        if (img.width - newWidth > 1000 || img.height - newHeight > 1000) {
          picaResizeOptions = {
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
            alpha: true,
          };
        }
      }

      //Resize
      let resizer = pica();
      const result = await resizer.resize(canvas, resizingCanvas, picaResizeOptions);
      const blob = await resizer.toBlob(result, 'image/jpeg', compressionAmount);
      const base64 = await readFileAsDataUrl(blob);
      resolve(base64);
    };
    img.onerror = reject;

    img.src = base64data;
  });
};

export const createThumbnailFromFile = async (file, ...args) => {
  const base64 = await readFileAsDataUrl(file);
  return createThumbnailFromBase64(base64, ...args);
};

export const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
};

export const floor = (val, precision) => {
  const multiplier = 10 ** (precision || 0);
  return Math.floor(val * multiplier) / multiplier;
};

export const base64ToArrayBuffer = (base64) => {
  let binaryString = atob(base64);
  let bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const copyArrayBuffer = (src) => {
  let dst = new ArrayBuffer(src.byteLength);
  new Uint8Array(dst).set(new Uint8Array(src));
  return dst;
};

export const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    let shouldAppend = false;
    let el = document.querySelector('script[src="' + src + '"]');
    if (!el) {
      el = document.createElement('script');
      el.type = 'text/javascript';
      el.async = true;
      el.src = src;
      shouldAppend = true;
    } else if (el.hasAttribute('data-loaded')) {
      resolve(el);
      return;
    }

    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    el.addEventListener('load', () => {
      el.setAttribute('data-loaded', true);
      resolve(el);
    });
    if (shouldAppend) document.head.appendChild(el);
  });
};

export const cleanQuery = () => {
  const uri = window.location.toString();
  if (uri.includes('?')) {
    const clean = uri.slice(0, uri.indexOf('?'));
    window.history.replaceState({}, document.title, clean);
  }
};

export const textDeSanitize = (inputText) => {
  const htmlEscapes = {
    '"': '&quot;',
    "'": '&#39;',
    '<': '&lt;',
    '>': '&gt;',
    'javascript:': 'javascript ',
  };
  // if null, return null (no action needed)
  if (!inputText) return '';
  // prevent escaping other escapes here
  let outputText = inputText.replace(/&amp;/g, '&');
  Object.keys(htmlEscapes).forEach((key) => {
    const regexp = new RegExp(`${htmlEscapes[key]}`, 'g');
    outputText = outputText.replace(regexp, key);
  });
  return outputText;
};

export const textSanitize = (inputText) => {
  let outputText = '';
  let htmlEscapes = {
    '"': '&quot;',
    "'": '&#39;',
    '<': '&lt;',
    '>': '&gt;',
    'javascript:': 'javascript ',
  };
  //if null, return null (no action needed)
  if (!inputText) return '';
  Object.keys(htmlEscapes).forEach(function (key) {
    const regexp = new RegExp(`${key}`, 'g');
    outputText = inputText.replace(regexp, htmlEscapes[key]);
  });
  outputText = outputText.replace(/[+\=&|><!\(\)\{\}\[\]^\"~*?:\\\/]/g, '');
  return outputText;
};

export const getUrlParamsObject = () => {
  return Object.fromEntries(new URLSearchParams(location.search));
};

export const isUploadedFileSizeValid = (file) => {
  //Validate: min file size?
  let tgtFileBytes = file.size;
  if (tgtFileBytes < MIN_FILE_SIZE) {
    Toast.open({
      duration: 2500,
      message: 'The provided image is too small. Please try again with a larger version.',
      position: 'is-top',
      type: 'is-warning',
    });
    return false;
  }

  //Validate max file size?
  if (tgtFileBytes > MAX_FILE_SIZE) {
    Toast.open({
      duration: 2500,
      message: 'The provided image is too large (>20MB). Please try again with a smaller version.',
      position: 'is-top',
      type: 'is-warning',
    });
    return false;
  }

  return true;
};

export const getLocalStoredValue = (field) => {
  const localJsonString = localStorage.getItem('harbourLocalJSONstr');
  if (!localJsonString) return null;
  try {
    const parsedJson = JSON.parse(localJsonString);
    const value = parsedJson[field];
    if (value === null || value === undefined) return null;
    return value;
  } catch (err) {
    return null;
  }
};

export const setLocalStoredValue = (field, value) => {
  let localJsonString = localStorage.getItem('harbourLocalJSONstr');
  if (!localJsonString) {
    let object = {};
    object[field] = value;
    localStorage.setItem('harbourLocalJSONstr', JSON.stringify(object));
    return;
  }
  const parsedJson = JSON.parse(localJsonString);
  parsedJson[field] = value;
  localStorage.setItem('harbourLocalJSONstr', JSON.stringify(parsedJson));
};

export const getValidatedEmailArrayFromText = (inputText) => {
  // Replace various delimiters with a single delimiter and split the text into an array
  const validEmailsArray = inputText
  .replace(/[\n\r<>()[\]\s,;\t]/g, '|')
  .split('|')
  .map(email => email.trim().toLowerCase())
  .filter(email => email.length > 0);

  const validEmailsArrayClean = validEmailsArray.filter(email => {
    // Validate the email using a regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate: one and only one "@"
    const atSymbolCount = (email.match(/@/g) || []).length;
    if (atSymbolCount !== 1) return false;

    // Validate: one or more "."
    const dotCount = (email.match(/\./g) || []).length;
    if (dotCount === 0) return false;

    // Validate: too short or too long
    if (email.length < 5 || email.length > 255) return false;

    // Validate: full regex check
    return emailRegex.test(email);
  });

  return validEmailsArrayClean;
};

export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toTitleCase = (str) => {
  const titleCase = str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return titleCase;
};

export const composeAsync =
  (...fns) =>
  (x) =>
    fns.reduce((acc, f) => acc.then(f), Promise.resolve(x));

export const getCookie = (name) => {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

//Delete cookies from the browser
export const deleteCookie = (name, path, domain) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};

export const filterAgreementInputs = (inputsList) => {
  return inputsList.filter((i) => {
    if (i.provider && i.parents) {
      const inputParents = inputsList.filter((input) => i.parents.includes(input.id));
      const selectedProviders = inputParents.map((input) => input.itemtypeconfig.provider);
      return selectedProviders.includes(i.provider);
    }
    return !i.id.includes('inputtile-') && !i.inputtiletype && i.itemfieldtype;
  });
};

export const delay = (t) => {
  return new Promise((resolve) => setTimeout(resolve, t));
};

export const delayWithAbort  = (duration, signal) => {
  return new Promise((resolve, reject) => {
    if (signal.aborted) {
      return reject(new DOMException('Operation aborted', 'AbortError'));
    }

    const timeoutId = setTimeout(() => {
      resolve();
    }, duration);

    signal.addEventListener('abort', () => {
      clearTimeout(timeoutId);
      reject(new DOMException('Operation aborted', 'AbortError'));
    }, { once: true });
  });
};

// Must be PDF
export const generatePDFPreviewImage = async (data) => {

  const typedarray = new Uint8Array(data);
  const pdf = await Vue.prototype.$pdfjs.getDocument(typedarray).promise;

  const page = await pdf.getPage(1);
  const scale = 0.35;
  const viewport = page.getViewport({ scale });

  const canvas = document.createElement('canvas');
  canvas.width = viewport.width;
  canvas.height = viewport.height;

  const context = canvas.getContext('2d');
  context.drawImage(canvas, 0, 0);

  const renderContext = {
      canvasContext: context,
      viewport: viewport
  };

  await page.render(renderContext).promise;
  const imageData = canvas.toDataURL('image/jpeg', 0.7);
  canvas.remove();
  return imageData;
}
